import ValidateAll from "@/view/qualified_inspections/qualified_inspections/window/validate_all.vue";

const route = [
    {
        path: '/validate_all/:id',
        name: 'validate_all',
        props: {id: null, showMenu: false, background: true},
        component: ValidateAll
    }
];

const routes = [
    ...route,
]

export default routes;
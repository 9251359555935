import Vue from "vue";
import vuex from "vuex";
import {API_URL} from "@/common/config";
import axios from "axios";
Vue.use(vuex);

export default {
    state: {
        searchData: '',
        loader: false,
        resultTitle: 'no_results_found'
    },
    mutations: {
        loadSearchDataHistoric(state,payload){
            state.searchData = payload
        },
        loadTitleResultHistoric(state, payload){
            state.resultTitle = payload
        },
        loadLoader(state, payload){
            state.loader = payload
        },
    },
    actions:{
        destroyedResultDataHistoric(state){
            state.commit("loadSearchDataHistoric", '');
            state.commit('loadTitleResultHistoric', 'no_results_found');
        },
        async downloadSanction(state,item){
            let requestLogin = API_URL +'download/sanction/' + item.type + '/' + item.id;

            await axios({
                method: "get",
                url: requestLogin,
                responseType: 'blob'
            }).then(
                response => {
                    let filename = 'downloaded-file.pdf';

                    const contentDisposition = response.headers['content-disposition'];
                    if (contentDisposition) {
                        const match = contentDisposition.match(/filename="(.+)"/);
                        if (match && match[1]) {
                            filename = match[1];
                        }
                    }

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();

                    // Clean up resources.
                    window.URL.revokeObjectURL(url);
                },
                error => {
                    console.log(error);
                }
            )
        },
        exportResultHistoric(state,items){
            let requestLogin =API_URL +'download/sanction';
            let params = {};

            var countWorkers = 0;
            var countCompanies = 0;
            var countInstalations = 0;
            var countInspectors = 0;
            var countTypesFoults = 0;
            var countTypesPenalties = 0;
            items.formData.forEach((item) => {
                let _key = item[0];
                if(_key === 'search[worker][]'){
                    params['search[worker][' + countWorkers + ']'] = item[1];
                    countWorkers++;
                }else if(_key === 'search[company][]'){
                    params['search[company][' + countCompanies + ']'] = item[1];
                    countCompanies++;
                }else if(_key === 'search[instalation][]'){
                    params['search[instalation][' + countInstalations + ']'] = item[1];
                    countInstalations++;
                }else if(_key === 'search[inspector][]'){
                    params['search[inspector][' + countInspectors + ']'] = item[1];
                    countInspectors++;
                }else if(_key === 'search[type_foult][]'){
                    params['search[type_foult][' + countTypesFoults + ']'] = item[1];
                    countTypesFoults++;
                }else if(_key === 'search[type_penalty][]'){
                    params['search[type_penalty][' + countTypesPenalties + ']'] = item[1];
                    countTypesPenalties++;
                }else {
                    params[_key] = item[1];
                }
            });

            axios({
                method: "get",
                url: requestLogin,
                params: params,
                responseType: 'blob'
            }).then(
                response => {
                    let filename = 'downloaded-file.xlsx'; // Default filename

                    const contentDisposition = response.headers['content-disposition'];
                    if (contentDisposition) {
                        const match = contentDisposition.match(/filename="(.+)"/);
                        if (match && match[1]) {
                            filename = match[1];
                        }
                    }

                    const url = window.URL.createObjectURL(new Blob([response.data],{
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                },
                error => {
                    console.log(error);
                }
            )
        },
        async searchDataHistoric(state,items){
            let requestLogin = API_URL +'search/sanctions';

            state.commit('loadLoader', true);

            let params = {};
            var countTypeWork = 0;
            var countTypeInspection = 0;
            items.formData.forEach((item) => {
                let _key = item[0];
                if(_key === 'search[type_work][]'){
                    params['search[type_work][' + countTypeWork + ']'] = item[1];
                    countTypeWork++;
                }else if(_key === 'search[type_inspection][]'){
                    params['search[type_inspection][' + countTypeInspection + ']'] = item[1];
                    countTypeInspection++;
                }else {
                    params[_key] = item[1];
                }
            });

            await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadSearchDataHistoric", response.data.data);
                        state.commit("loadTitleResultHistoric", response.data.data.length);
                        state.commit('loadLoader', false);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async exportDataHistoric(state,items){
            let requestLogin = API_URL + 'search/sanctions/export-results';

            let params = {};
            var countTypeWork = 0;
            var countTypeInspection = 0;
            items.formData.forEach((item) => {
                let _key = item[0];
                if(_key === 'search[type_work][]'){
                    params['search[type_work][' + countTypeWork + ']'] = item[1];
                    countTypeWork++;
                }else if(_key === 'search[type_inspection][]'){
                    params['search[type_inspection][' + countTypeInspection + ']'] = item[1];
                    countTypeInspection++;
                }else {
                    params[_key] = item[1];
                }
            });

            await axios({
                method: "get",
                url: requestLogin,
                params: params,
                responseType: 'blob'
            }).then(
                response => {
                    let filename = 'downloaded-file.xlsx'; // Default filename

                    const contentDisposition = response.headers['content-disposition'];
                    if (contentDisposition) {
                        const match = contentDisposition.match(/filename="(.+)"/);
                        if (match && match[1]) {
                            filename = match[1];
                        }
                    }

                    // Create a blob from the response data.
                    const blob = new Blob([response.data]);

                    // Create a link element to trigger the download.
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;

                    // Set the download attribute and trigger a click event to download the file.
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();

                    // Clean up resources.
                    window.URL.revokeObjectURL(url);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}

import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        level_criticity: [],
        criticity_level: [],
        ambits: [],
        actions: [],
        feed_perms: [],
        feed_type_foult: [],
        feed_type_penalty: [],
        feed_type_inspection: [],
        feed_type_work: [],
        result_search_workers: [],
        result_search_company: [],
        result_search_instalation: [],
        result_search_inspectors: [],
        bill_type: []
    },
    mutations: {
        loadLevelCriticity(state, payload){
            state.level_criticity = payload
        },
        loadCriticityLevel(state, payload){
            state.criticity_level = payload
        },
        loadAmbits(state, payload){
            state.ambits = payload
        },
        loadActions(state, payload){
            state.actions = payload
        },
        loadBillType(state, payload){
            state.bill_type = payload
        },
        loadFeedPerms(state, payload){
            state.feed_perms = payload;
        },
        loadFeedTypeFoult(state, payload){
            state.feed_type_foult = payload;
        },
        loadFeedTypePenalty(state, payload){
            state.feed_type_penalty = payload;
        },
        loadFeedTypeInspection(state, payload){
            state.feed_type_inspection = payload;
        },
        loadFeedTypeWork(state, payload){
            state.feed_type_work = payload;
        },
        loadResultSearchWorkers(state, payload){
            state.result_search_workers = payload;
        },
        loadResultSearchCompany(state, payload){
            state.result_search_company = payload;
        },
        loadResultSearchInstalation(state, payload){
            state.result_search_instalation = payload;
        },
        loadResultSearchInspector(state, payload){
            state.result_search_inspectors = payload;
        },
    },
    actions: {
        async getFeedLevelCriticality(state){
            await axios.get(
                API_URL + "level-criticalities"
            ).then(
                response => {
                    state.commit('loadLevelCriticity', response.data.data);
                }
            )
        },
        async getFeedCriticalityLevel(state){
            await axios.get(
                API_URL + "criticalities-levels"
            ).then(
                response => {
                    state.commit('loadCriticityLevel', response.data.data);
                }
            )
        },
        async getFeedAmbits(state){
            await axios.get(
                API_URL + "ambits"
            ).then(
                response => {
                    state.commit('loadAmbits', response.data.data);
                }
            )
        },
        async getFeedActions(state){
            await axios.get(
                API_URL + "actions/feeds"
            ).then(
                response => {
                    state.commit('loadActions', response.data.data);
                }
            )
        },
        async getFeedBillType(state){
            await axios.get(
                API_URL + "bill-type"
            ).then(
                response => {
                    state.commit('loadBillType', response.data.data);
                }
            )
        },
        async getFeedPerms(state){
            let  requestLogin = API_URL + "permissions/feed";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedPerms", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedTypeFoult(state){
            await axios.get(
                API_URL + "feed/type-foult"
            ).then(
                response => {
                    state.commit('loadFeedTypeFoult', response.data.data);
                }
            )
        },
        async getFeedTypePenalty(state){
            await axios.get(
                API_URL + "feed/type-penalty"
            ).then(
                response => {
                    state.commit('loadFeedTypePenalty', response.data.data);
                }
            )
        },
        async getFeedTypeInspection(state){
            await axios.get(
                API_URL + "type-inspection/feed"
            ).then(
                response => {
                    state.commit('loadFeedTypeInspection', response.data.data);
                }
            )
        },
        async getFeedTypeWork(state){
            await axios.get(
                API_URL + "type-work/feed"
            ).then(
                response => {
                    state.commit('loadFeedTypeWork', response.data.data);
                }
            )
        },
        async getSearchWorkers(state, item){
            let params = {};

            item.formData.forEach((item) => {
                let _key = item[0];
                params[_key] = item[1];
            });

            await axios({
                method: "get",
                url: API_URL + "search/workers",
                params: params,
            }).then(
                response => {
                    state.commit("loadResultSearchWorkers", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected)==true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchCompany(state, item){
            let params = {};

            item.formData.forEach((item) => {
                let _key = item[0];
                params[_key] = item[1];
            });

            await axios({
                method: "get",
                url: API_URL + "search/company",
                params: params,
            }).then(
                response => {
                    state.commit("loadResultSearchCompany", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected)==true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchInstalation(state, item){
            let params = {};

            item.formData.forEach((item) => {
                let _key = item[0];
                params[_key] = item[1];
            });

            await axios({
                method: "get",
                url: API_URL + "search/instalation",
                params: params,
            }).then(
                response => {
                    state.commit("loadResultSearchInstalation", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected)==true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchInspector(state, item){
            let params = {};

            item.formData.forEach((item) => {
                let _key = item[0];
                params[_key] = item[1];
            });

            await axios({
                method: "get",
                url: API_URL + "search/inspector",
                params: params,
            }).then(
                response => {
                    state.commit("loadResultSearchInspector", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected)==true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getInstalationCodeSearch(state, item){
            let request = API_URL + 'search/inline/instalation-code';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: request,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getInstalationNameSearch(state, item){
            let request = API_URL + 'search/inline/instalation-name';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: request,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getCompanyNameSearch(state, item){
            let request = API_URL + 'search/inline/company-name';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: request,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getCompanyCifSearch(state, item){
            let request = API_URL + 'search/inline/company-cif';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: request,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getInspectorSearch(state, item){
            let request = API_URL + 'search/inline/inspector';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: request,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getGSMASearch(state, item){
            let request = API_URL + 'search/inline/gsma';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: request,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getGATSearch(state, item){
            let request = API_URL + 'search/inline/gat';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: request,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};
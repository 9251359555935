import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        sections: [],
    },
    mutations: {
        loadShutdownsSections(state, payload) {
            state.sections = payload;
        }
    },
    actions: {
        async getShutdownsSections(state){
            await axios.get(
                API_URL + "app-modules/shutdowns"
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadShutdownsSections', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
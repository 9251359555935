import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import Global from "@/store/Global";

import {API_URL} from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        list: [],
        refreshNotAssociatedList: false,
    },
    mutations: {
        loadNotAssociatedList(state, payload) {
            state.refreshNotAssociatedList = !state.refreshNotAssociatedList;
            state.list = payload;
        }
    },
    getters: {
        getterNotAssociatedList(state){
            const dummy = state.refreshNotAssociatedList; // eslint-disable-line no-unused-vars
            return state.list;
        }
    },
    actions: {
        async getNotAssociatedList(state){
            await axios.get(
                API_URL + "exempt/exempt"
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadNotAssociatedList', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setAssociated(state, item){
            let request = API_URL + (item.data !== undefined ? "exempt/exempt/associate" : "exempt/exempt/" + item.id + "/associate");
            let formData = new FormData();

            if(item.data !== undefined){
                item.data.forEach((i) => {
                    formData.append('associated[]', i.id);
                });
            }

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        if(item.data !== undefined){
                            Global.closePopUp('associated_popup');
                        }

                        await this.dispatch('getNotAssociatedSections');
                        await state.dispatch('getNotAssociatedList');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhLocation',{staticStyle:{"margin-top":"-5px"},attrs:{"dataLocation":{
            showButton: false,
            secondWindow: true,
            showLoader: 'OK',
            text: _vm.$t('criticality'),
            img: require('@/assets/gh_new_dessign/floating.svg')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')}}}),(_vm.haveData)?_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'criticality',
                title: _vm.$t('criticality')
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_criticality"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"margin-left":"6px"}},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'criticality_level',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_criticality_level') + ':',
                                        text: _vm.$t('criticality_level') + '*',
                                        label: _vm.$t('criticality_level'),
                                        type: 'radio',
                                        columns: 3,
                                        required: true,
                                        style: '',
                                        popupStyle: 'min-width: 900px;width: 55%;'
                                    },"datainput":{
                                        id: 'value_criticality_level',
                                        name: 'criticality[level]'
                                    },"feed":_vm.getFeeds.criticity_level,"selected_input":_vm.getterConfigurationCriticalitiesData.level !== undefined ? _vm.getterConfigurationCriticalitiesData.level : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"300px","min-width":"300px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('criticality_level') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(Object.values(_vm.getterConfigurationCriticalitiesData.level).join(', ')))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                                        text: _vm.$t('criticality_detail'),
                                        styles: 'width: 298px; min-width: 298px !important; text-align: right; margin-right: 12px;',
                                        class: ''
                                    },"datainput":{
                                        id: 'criticality_detail_criticality',
                                        name: 'criticality[detail_criticality]',
                                        value: _vm.getterConfigurationCriticalitiesData.detail_criticality !== undefined ? _vm.getterConfigurationCriticalitiesData.detail_criticality : ''
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"300px","min-width":"300px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('criticality_detail') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterConfigurationCriticalitiesData.detail_criticality !== undefined ? _vm.getterConfigurationCriticalitiesData.detail_criticality : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                                        text: _vm.$t('assent_detail'),
                                        styles: 'width: 298px; min-width: 298px !important; text-align: right; margin-right: 12px;',
                                        class: ''
                                    },"datainput":{
                                        id: 'criticality_detail_sanction',
                                        name: 'criticality[detail_sanction]',
                                        value: _vm.getterConfigurationCriticalitiesData.detail_sanction !== undefined ? _vm.getterConfigurationCriticalitiesData.detail_sanction : ''
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"300px","min-width":"300px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('assent_detail') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterConfigurationCriticalitiesData.detail_sanction !== undefined ? _vm.getterConfigurationCriticalitiesData.detail_sanction : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('economic_sanction_of'),
                                        style: 'width: 300px; text-align: right;',
                                        class: 'T15'
                                    },"datainput":{
                                        required: false,
                                        id: 'criticality_pay',
                                        name: 'criticality[pay]',
                                        style: 'width: 80px',
                                        value: _vm.getterConfigurationCriticalitiesData.pay !== undefined ? _vm.getterConfigurationCriticalitiesData.pay : '',
                                        type: 'text'
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"300px","min-width":"300px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('economic_sanction_of')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterConfigurationCriticalitiesData.pay !== undefined ? _vm.getterConfigurationCriticalitiesData.pay : ''))])],_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px","margin-right":"10px"}},[_vm._v(_vm._s('€'))]),_c('GhChecks',{attrs:{"datalabel":{
                                    text: _vm.$t('na'),
                                    style: 'width: 130px',
                                    id: 'label_criticality_na_pay'
                                },"datainput":{
                                    required: false,
                                    selected: _vm.getterConfigurationCriticalitiesData.na !== undefined && _vm.getterConfigurationCriticalitiesData.na === 1,
                                    disabled: _vm.$root._route.params.view,
                                    type: 'checkbox',
                                    id: 'value_criticality_na_pay',
                                    value: 1,
                                    name: 'criticality[na]'
                                }}})],2),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"300px","min-width":"300px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('implies_a_warning')))]),_c('GhChecks',{attrs:{"datalabel":{
                                    text: '',
                                    style: 'width: 130px',
                                    id: 'label_criticality_implies_a_warning'
                                },"datainput":{
                                    required: false,
                                    selected: _vm.getterConfigurationCriticalitiesData.warning !== undefined && _vm.getterConfigurationCriticalitiesData.warning === 1,
                                    disabled: _vm.$root._route.params.view,
                                    type: 'checkbox',
                                    id: 'value_criticality_implies_a_warning',
                                    value: 1,
                                    name: 'criticality[warning]'
                                }}})],1),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('implies_red_card_to_the_worker_of'),
                                        style: 'width: 300px; text-align: right;',
                                        class: 'T15'
                                    },"datainput":{
                                        required: false,
                                        id: 'criticality_red_card_worker_from',
                                        name: 'criticality[red_card][worker][from]',
                                        style: 'width: 80px',
                                        value: _vm.getterConfigurationCriticalitiesData.red_card !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.worker !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.worker.from !== undefined ? _vm.getterConfigurationCriticalitiesData.red_card.worker.from : '',
                                        type: 'int'
                                    }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('a'),
                                        style: 'width: 18px; text-align: right;',
                                        class: 'T15'
                                    },"datainput":{
                                        required: false,
                                        id: 'criticality_red_card_worker_to',
                                        name: 'criticality[red_card][worker][to]',
                                        style: 'width: 80px',
                                        value: _vm.getterConfigurationCriticalitiesData.red_card !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.worker !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.worker.to !== undefined ? _vm.getterConfigurationCriticalitiesData.red_card.worker.to : '',
                                        type: 'int'
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"300px","min-width":"300px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('implies_red_card_to_the_worker_of')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterConfigurationCriticalitiesData.red_card !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.worker !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.worker.from !== undefined ? _vm.getterConfigurationCriticalitiesData.red_card.worker.from : ''))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('a')))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterConfigurationCriticalitiesData.red_card !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.worker !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.worker.to !== undefined ? _vm.getterConfigurationCriticalitiesData.red_card.worker.to : ''))])],_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('days').toLowerCase()))])],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('implies_red_card_to_the_company_of'),
                                        style: 'width: 300px; text-align: right;',
                                        class: 'T15'
                                    },"datainput":{
                                        required: false,
                                        id: 'criticality_red_card_company_from',
                                        name: 'criticality[red_card][company][from]',
                                        style: 'width: 80px',
                                        value: _vm.getterConfigurationCriticalitiesData.red_card !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.company !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.company.from !== undefined ? _vm.getterConfigurationCriticalitiesData.red_card.company.from : '',
                                        type: 'int'
                                    }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('a'),
                                        style: 'width: 18px; text-align: right;',
                                        class: 'T15'
                                    },"datainput":{
                                        required: false,
                                        id: 'criticality_red_card_company_to',
                                        name: 'criticality[red_card][company][to]',
                                        style: 'width: 80px',
                                        value: _vm.getterConfigurationCriticalitiesData.red_card !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.company !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.company.to !== undefined ? _vm.getterConfigurationCriticalitiesData.red_card.company.to : '',
                                        type: 'int'
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"300px","min-width":"300px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('this_sanction_implies_a_red_card')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterConfigurationCriticalitiesData.red_card !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.company !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.company.from !== undefined ? _vm.getterConfigurationCriticalitiesData.red_card.company.from : ''))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('a')))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterConfigurationCriticalitiesData.red_card !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.company !== undefined && _vm.getterConfigurationCriticalitiesData.red_card.company.to !== undefined ? _vm.getterConfigurationCriticalitiesData.red_card.company.to : ''))])],_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('days').toLowerCase()))])],2)])]):(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})]:_vm._e()]},proxy:true}],null,false,111830625)}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view && _vm.haveData)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_save',
                    text: _vm.$t('save')
                }},on:{"click":function($event){return _vm.setCriticality()}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_return',
                    text: _vm.$t('close')
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'message_upload',
            title: $t('information'),
            type: 'info',
            style: 'width: 450px; min-width:450px',
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px;'
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <div v-if="getterConfigurationDocument.loaded && getterConfigurationForms.list.length !== 0">
                <div class="gh-content-global">
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15" >
                            {{$t('form') + ' '}}
                            <span class="T15_b" v-if="getterConfigurationForms.list.find((i) => i.id === ConfigurationForms.tempId) !== undefined">
                                {{$t(getterConfigurationForms.list.find((i) => i.id === ConfigurationForms.tempId).name)}}
                            </span>
                            <template v-if="getterConfigurationDocument.content.status === 200">
                                {{' ' + $t('has_been_uploaded_successfully').toLowerCase()}}
                            </template>
                            <template v-else-if="getterConfigurationDocument.content.status === 333">
                                {{' ' + $t('has_not_been_uploaded_successfully').toLowerCase()}}
                            </template>
                        </label>
                    </div>
                </div>
            </div>
            <div v-else-if="!getterConfigurationDocument.loaded">
                <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                    <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton v-if="getterConfigurationDocument.loaded"
                :datainput="{
                    id: 'btn_metrics_accept_popup',
                    text: $t('close')
                }"
                @click="Global.closePopUp('message_upload')"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters, mapState} from "vuex";

    export default {
        name: 'message_upload',
        components: {
            GhPopUp,
            GhButton
        },
        computed: {
            ...mapGetters(['getterConfigurationDocument', 'getterConfigurationForms']),
            ...mapState(['ConfigurationForms'])
        }
    }
</script>
<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{
                id: 'actions'
            }"
            :header="header"
            :data="getterConfigurationActionsList.list"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <a class="table-cell_link T13" @click="see(itemProp.id,'view_configuration_actions')">{{itemProp[labelProp]}}</a>
            </template>
            <template v-slot:actions="{itemProp}">
                <template v-if="getterConfigurationActionsList.actions.modify">
                    <GhAction
                        :dataction="{
                            id: 'modify_configuration_actions',
                            text: $t('modify'),
                            icon: require('../../../assets/gh_new_dessign/edit.svg')
                        }"
                        @click="edit(itemProp.id,'modify_configuration_actions')"
                    />
                </template>
                <template v-if="getterConfigurationActionsList.actions.delete">
                    <GhAction
                        :dataction="{
                            id: 'delete_configuration_actions',
                            text: $t('delete'),
                            icon: require('../../../assets/gh_new_dessign/trash.svg')
                        }"
                        @click="Global.deleteConfirm('delete_confirm','removeActions', itemProp.id,{'name': itemProp.name, 'description': itemProp.description}, $t('delete_actions'), $t('preparing_delete_actions'), 'delete')"
                    />
                </template>
            </template>
            <template v-slot:footer>
                <div class="centered" v-if="getterConfigurationActionsList.actions.add">
                    <GhButton
                        :datainput="{
                            id: 'btn_new_configuration_actions',
                            text: $t('add')
                        }"
                        @click="add"
                    />
                </div>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhButton from "fe-gh-button-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapGetters} from "vuex";

    export default {
        name: 'list_actions',
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapGetters(['getterConfigurationActionsList'])
        },
        data(){
            return{
                header: [
                    {text: 'name', field: 'name', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 80%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                timer: [],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationActions', '', {root: true});

            if(!this.getterConfigurationActionsList.actions.modify && !this.getterConfigurationActionsList.actions.delete){
                delete this.header[2];

                this.header = this.header.filter(function (item) {
                    return item !== undefined;
                });
            }

            this.haveData = true;
        },
        methods:{
            async add(){
                this.Global.windowOpen(this.Global.openSecondWindow('new_configuration_actions',''),'new_configuration_actions',true);
                this.timer = setInterval(await this.checkChildWindow, 500);
            },
            async edit(id,type){
                this.Global.windowOpen(this.Global.openSecondWindow(type,{id: id}),type,true);
                this.timer = setInterval(await this.checkChildWindow, 500);
            },
            async see(id,type){
                this.Global.windowOpen(this.Global.openSecondWindow(type,{id: id,view: true}),type,true);
                this.timer = setInterval(await this.checkChildWindow, 500);
            },
            async checkChildWindow() {
                if (this.Global.state.window.closed) {
                    await this.$store.dispatch('getConfigurationSections', '', {root: true});
                    await this.$store.dispatch('getConfigurationActions', '', {root: true});
                    this.Global.state.window_open = false;
                    clearInterval(this.timer);
                }
            }
        }
    }
</script>
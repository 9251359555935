import Sanction from "@/view/sanction/sanction.vue";
import SanctionAction from "@/view/shared/sanction.vue";

const routes = [
    {
        path: '/sanction',
        name: 'sanction',
        props: {showMenu: true},
        component: Sanction
    },
    {
        path: '/sanction/:type/:id_company/:id/:origin',
        name: 'sanction_action',
        props: {showMenu: false, background: true, type: null, id_company: null, id: null},
        component: SanctionAction
    }
];

export default routes;
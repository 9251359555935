var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-bottom":"12px"}},[_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'filters_block',
            title: _vm.$t('seeker'),
            second_title: ''
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.searchgeneral.filters,{tag:"component"})]},proxy:true}])}),_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'results_block',
            title: (_vm.resultsSearch != '' ? _vm.resultsSearch+' ' : '') + _vm.$t('results'),
            second_title:''
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.searchgeneral.result,{tag:"component"})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
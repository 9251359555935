<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'associated_popup',
            title: $t('exempt_sanctioning_regime'),
            type: 'add',
            style: 'width: 35%; min-width: 430px'
        }"
        :bodyConfiguration="{id: 'associated_popup_body', class: '', style: 'overflow-y: auto;max-height: 800px;padding-left: 20px;', text: ''}"
        :footerConfiguration="{id: 'associated_popup_footer', class: '', style: ''}"
    >
        <template v-slot:body>
            <div style="margin-top: 15px; margin-left: 20px; margin-right: 20px;" :key="refreshContent">
                <label class="T15 gh_text-readonly_label">{{$t('company') + ': '}}</label>
                <label class="T15_b gh_text-readonly_label">{{companies}}</label>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'accept_associated_popup',
                    text: $t('accept'),
                    class: 'T19 container-md_button_content',
                    style: 'display: flex;'
                }"
                @click="acceptAssociated()"
            />
            <GhButton
                :datainput="{
                    id: 'close_associated_popup',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: 'display: flex;'
                }"
                @click="Global.closePopUp('associated_popup')"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'popup_associated',
        props: ['dataPopup'],
        components: {
            GhPopUp,
            GhButton
        },
        data(){
            return {
                companies: '',
                refreshContent: 0
            }
        },
        watch: {
            'dataPopup'(data){
                this.companies = '';

                data.forEach((i) => {
                    this.companies += i.name + ', ';
                });

                this.companies = this.companies.substring(0, this.companies.length - 2);

                this.refreshContent++;
            }
        },
        methods: {
            async acceptAssociated(){
                await this.$store.dispatch('setAssociated', {
                    data: this.dataPopup
                }, {root: true});
            }
        }
    }
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTableCommon',{ref:'refIncidencesQualified' + _vm.prop.id,attrs:{"extratable":{
            id: 'table_qualified_inspections_' + _vm.prop.id
        },"header":_vm.header,"data":_vm.getterQualifiedInspectionsCompaniesIncidences[_vm.prop.id] === undefined || _vm.getterQualifiedInspectionsCompaniesIncidences[_vm.prop.id].list.length === 0 ? [] : _vm.getterQualifiedInspectionsCompaniesIncidences[_vm.prop.id].list},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('label',{staticClass:"T15_b",style:('color:' + itemProp['style_criticality'] + ';')},[_vm._v(_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [(_vm.getterQualifiedInspectionsCompaniesIncidences[_vm.prop.id].actions.view)?[_c('GhAction',{attrs:{"dataction":{
                        id: 'view_sanction_' + itemProp['id'],
                        text: _vm.$t('view'),
                        item_prop: itemProp,
                        icon: require('@/assets/gh_new_dessign/doc.svg')
                    }},on:{"click":function($event){return _vm.setView(itemProp, 'view')}}})]:_vm._e(),((itemProp.resolve || itemProp.validate) && (_vm.getterQualifiedInspectionsCompaniesIncidences[_vm.prop.id].actions.validate || _vm.getterQualifiedInspectionsCompaniesIncidences[_vm.prop.id].actions.resolve))?[_c('GhAction',{attrs:{"dataction":{
                        id: 'action_qualified_inspections_' + itemProp['id'],
                        text: _vm.$t(itemProp.resolve ? 'resolve' : 'validate'),
                        item_prop: itemProp,
                        icon: require('@/assets/gh_new_dessign/tick.svg')
                    }},on:{"click":function($event){return _vm.setAction(itemProp, itemProp.resolve ? 'resolve' : 'validate')}}})]:_vm._e()]}}],null,false,1060965757)})],1):_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import Global from "@/store/Global";
Vue.use(vuex);

export default{
    state: {
        data: [],
        refreshConfigurationForms: false,
        document: {
            loaded: false,
            content: []
        },
        refreshDocument: false,
        tempId: null
    },
    mutations: {
        loadConfigurationForms(state, payload) {
            state.refreshConfigurationForms = !state.refreshConfigurationForms;
            state.data = payload;
        },
        loadTempId(state, payload){
            state.tempId = payload;
        },
        loadDocument(state, payload) {
            state.refreshDocument = !state.refreshDocument;
            state.document = payload;
        }
    },
    getters: {
        getterConfigurationForms(state){
            const dummy = state.refreshConfigurationForms; // eslint-disable-line no-unused-vars
            return state.data;
        },
        getterConfigurationDocument(state){
            const dummy = state.refreshDocument; // eslint-disable-line no-unused-vars
            return state.document;
        },
    },
    actions: {
        async getConfigurationForms(state){
            await axios.get(
                API_URL + "forms"
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadConfigurationForms', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setFormData(state, item){
            let request = API_URL + "forms";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        state.dispatch('getConfigurationForms');
                        this.dispatch('getConfigurationSections');
                        Global.closePopUp('popup_add_form');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        setUploadIdDocument(state, item){
            state.commit('loadTempId', item.id);
        },
        async uploadForm(state, item){
            let request = API_URL + 'forms/' + state.state.tempId + '/attach';

            state.commit('loadDocument', {
                loaded: false,
                content: []
            });

            Global.openPopUp('message_upload');

            let params = new FormData();

            var data = {};
            item.formData.forEach((item) => {
                data = item;
            });

            params.append('form[attach]', data);

            return await axios({
                method: "post",
                url: request,
                data: params,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit('loadDocument', {
                        loaded: true,
                        content: response
                    });

                    state.dispatch('getConfigurationForms');

                    return response;
                },
                error => {
                    state.commit('loadDocument', {
                        loaded: true,
                        content: error.response.data
                    });

                    state.dispatch('getConfigurationForms');
                    return error.response.data;
                }
            )
        }
    }
};
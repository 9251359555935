<template>
    <div class="float_window">
        <GhLocation
            style="margin-top: -5px;"
            :dataLocation="{
                showButton: false,
                secondWindow: true,
                showLoader: 'OK',
                text: $root._route.params.type === 'view' ? $t('assent') : $t($root._route.params.type) + ' ' + $t('assent').toLowerCase(),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new', {
                id: 1,
                add: true,
                floatWindow: true
            }),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'sanction',
                    title: $root._route.params.type === 'view' ? $t('assent') : $t($root._route.params.type) + ' ' + $t('assent').toLowerCase()
                }"
            >
                <template v-slot:content>
                    <div v-if="haveData">
                        <form id="sanction_form">
                            <div class="section_line" style="margin-top: 8px;">
                                <div class="T15_b" style="padding-left:13px;">{{$t('incident_detected_by') + ' ' + getterSanction.inspector + ' ' + getterSanction.sanction_date + ' ' + $t('in_company').toLowerCase() + ' ' + getterSanction.company.name}}</div>
                            </div>

                            <div class="gh-content-global">
                                <div class="gh-row-content" v-if="getterSanction.show.section">
                                    <label class="gh_text-readonly_label T15" style="min-width: 150px; max-width: 150px; text-align: right;">{{$t('section') + ':'}}</label>
                                    <label class="gh_text-readonly_label T15_b" style="margin-left: 10px;">{{getterSanction.section}}</label>
                                </div>
                                <div class="gh-row-content" v-if="getterSanction.show.incidence_detail">
                                    <label class="gh_text-readonly_label T15" style="min-width: 150px; max-width: 150px; text-align: right;">{{$t('incidence_detail') + ':'}}</label>
                                    <label class="gh_text-readonly_label T15_b" style="margin-left: 10px;">{{getterSanction.incidence_detail}}</label>
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text-readonly_label T15" style="min-width: 150px; max-width: 150px; text-align: right;">{{$t('criticality') + ':'}}</label>
                                    <label class="gh_text-readonly_label T15_b" style="margin-left: 10px;" :style="'color:' + getterSanction.style_criticality">{{getterSanction.criticality}}</label>
                                </div>
                                <div class="gh-row-content" v-if="getterSanction.show.employee_detail">
                                    <label class="gh_text-readonly_label T15" style="min-width: 150px; max-width: 150px; text-align: right;">{{$t('employee_detail') + ':'}}</label>
                                    <label class="gh_text-readonly_label T15_b" style="margin-left: 10px;">{{getterSanction.employee_detail}}</label>
                                </div>
                                <div class="gh-row-content" v-if="getterSanction.show.resolved_immediately">
                                    <label class="gh_text-readonly_label T15" style="min-width: 150px; max-width: 150px; text-align: right;">{{$t('resolved_immediately') + ':'}}</label>
                                    <label class="gh_text-readonly_label T15_b" style="margin-left: 10px;">{{getterSanction.resolved_immediately}}</label>
                                </div>
                                <div class="gh-row-content" v-if="getterSanction.show.sanction_detail">
                                    <template v-if="getterSanction.state === 0 && $root._route.params.type !== 'view'">
                                        <GhTextarea
                                            :datalabel="{
                                                text: $t('sanction_detail'),
                                                styles: 'width: 150px; min-width: 150px !important; text-align: right; margin-right: 12px;',
                                                class: ''
                                            }"
                                            :datainput="{
                                                id: 'sanction_sanction_detail',
                                                name: 'sanction[sanction_detail]',
                                                value: getterSanction.sanction_detail !== undefined ? getterSanction.sanction_detail : ''
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text-readonly_label T15" style="min-width: 150px; max-width: 150px; text-align: right;">{{$t('sanction_detail') + ':'}}</label>
                                        <label class="gh_text-readonly_label T15_b" style="margin-left: 10px;" >{{getterSanction.sanction_detail}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content" v-if="getterSanction.show.sanction_note">
                                    <label class="gh_text-readonly_label T15" style="min-width: 150px; max-width: 150px; text-align: right;">{{$t('sanction_note') + ':'}}</label>
                                    <label class="gh_text-readonly_label T15_b" style="margin-left: 10px;" >{{getterSanction.sanction_note}}</label>
                                </div>
                                <div class="gh-row-content" v-if="getterSanction.show.applicable_sanction">
                                    <label class="gh_text-readonly_label T15" style="min-width: 150px; max-width: 150px; text-align: right;">{{$t('applicable_sanction_of') + ':'}}</label>
                                    <label class="gh_text-readonly_label T15_b" style="margin-left: 10px;">{{getterSanction.applicable_sanction + ' €'}}</label>
                                    <template v-if="!getterSanction.sanction_regim">
                                        <label class="gh_text-readonly_label T15_b" style="margin-left: 10px;">{{'(' + $t('no_apply') + ': ' + $t('this_company_is_not_in_the_sanctioning_regime') + ')'}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content" v-if="getterSanction.show.warning">
                                    <label class="gh_text-readonly_label T15" style="min-width: 150px; max-width: 150px; text-align: right;">{{$t('warning') + ':'}}</label>
                                    <label class="gh_text-readonly_label T15_b" style="margin-left: 10px;">{{getterSanction.warning}}</label>
                                </div>
                                <template v-if="getterSanction.show.show_employees">
                                    <div class="gh-row-content" v-for="(employee, keyEmployee) in getterSanction.employees" :key="keyEmployee" :style="keyEmployee % 2 ? '' : 'background-color: #F3DAB5;'" style="padding-left: 5px; padding-top: 5px; padding-bottom: 5px;">
                                        <span v-if="haveRedCard" style="width: 22px;">
                                            <div v-if="employee.red_card" style="background-color: red; width: 13px; height: 16px; border-radius: 2px;"></div>
                                        </span>
                                        <label class="gh_text-readonly_label T15_b" style="width: 30%">{{employee.name}}</label>
                                        <template v-if="employee.red_card">
                                            <label class="gh_text-readonly_label T15_b" style="margin-right: 10px;">{{$t('red_card_applied_for') + ' ' + getterSanction.range_red_card.employee.from + ' ' + $t('to').toLowerCase() + ' ' + getterSanction.range_red_card.employee.to + ' ' + $t('days').toLowerCase() + ', ' + $t('apply_days').toLowerCase() + (getterSanction.state !== 0 ? ' ' + employee.red_card_days : '')}}</label>
                                            <template v-if="getterSanction.state === 0 && $root._route.params.type !== 'view'">
                                                <GhInputTextField :key="'input-limit-' + keyEmployee + '-' + refreshInput"
                                                    :datalabel="{
                                                        text: '',
                                                        style: '',
                                                        class: 'T15'
                                                    }"
                                                    :datainput="{
                                                        required: stateRedInput[keyEmployee],
                                                        label_required: $t('apply_days') + ' ' + $t('of').toLowerCase() + ' ' +employee.name,
                                                        id: 'sanction_worker_' + employee.id + '_red_card_days',
                                                        name: 'sanction[worker][' + employee.id + '][red_card_days]',
                                                        style: 'width: 50px; padding-bottom: unset !important; height: 15px !important',
                                                        value: valueEmployee['uuid' + employee.id],
                                                        type: 'int'
                                                    }"
                                                    :class="!stateRedInput[keyEmployee] ? 'disabled' : ''"
                                                    :ref="'refCuantityEmployee' + employee.id"
                                                    @blur="limitCuantityEmployee(employee.id)"
                                                />

                                                <div>
                                                    <GhChecks
                                                        style="margin-left: 20px;margin-top: -2px;"
                                                        :datalabel="{
                                                            text: $t('na'),
                                                            style: 'margin-right: 10px;',
                                                            class: 'T15_b',
                                                            id: 'label_red_card_na_' + employee.id
                                                        }"
                                                        :datainput="{
                                                            required: false,
                                                            selected: false,
                                                            disabled: $root._route.params.view || getterSanction.state !== 0 || $root._route.params.type === 'view',
                                                            type: 'checkbox',
                                                            id: 'value_red_card_na_' + employee.id,
                                                            value: true,
                                                            name: 'sanction[worker][' + employee.id + '][red_card_na]'
                                                        }"
                                                        :ref="'refNaEmployee' + employee.id"
                                                        @click="checkNA(employee.id,keyEmployee)"
                                                    />
                                                </div>
                                            </template>
                                        </template>
                                    </div>
                                </template>
                                <template v-if="getterSanction.show.show_company_red_card">
                                    <div class="gh-row-content" style="padding-left: 5px; padding-top: 5px;">
                                        <span v-if="haveRedCard" style="width: 22px;">
                                            <div v-if="showRedCard" style="background-color: red; width: 13px; height: 16px; border-radius: 2px;"></div>
                                        </span>
                                        <div>
                                            <GhChecks
                                                style="margin-top: -1px;"
                                                :datalabel="{
                                                    text: $t('red_card_applied_company_for') + ' ' + getterSanction.range_red_card.company.from + ' ' + getterSanction.range_red_card.company.to + ' ' + $t('days').toLowerCase() + (showRedCard ? ', ' + $t('apply_days').toLowerCase() : '') + (getterSanction.state !== 0 || $root._route.params.type === 'view' ? ' ' + getterSanction.company.red_card_days : ''),
                                                    style: 'margin-right: 10px;',
                                                    class: 'T15_b',
                                                    id: 'label_criticality_implies_a_warning'
                                                }"
                                                :datainput="{
                                                    required: false,
                                                    selected: getterSanction.company.red_card,
                                                    disabled: $root._route.params.view || getterSanction.state !== 0 || $root._route.params.type === 'view',
                                                    type: 'checkbox',
                                                    id: 'value_sanction_company_red_card',
                                                    value: true,
                                                    name: 'sanction[company][red_card]'
                                                }"
                                                ref="RefCompanyRedCard"
                                                @click="checkCompanyRedCard()"
                                            />
                                            <template v-if="getterSanction.state === 0 && $root._route.params.type !== 'view'">
                                                <GhInputTextField v-if="showRedCard"
                                                    :datalabel="{
                                                        text: '',
                                                        style: '',
                                                        class: 'T15'
                                                    }"
                                                    :datainput="{
                                                        required: true,
                                                        label_required: $t('apply_days') + ' ' + $t('company').toLowerCase(),
                                                        id: 'sanction_company_red_card_days',
                                                        name: 'sanction[company][red_card_days]',
                                                        style: 'width: 50px; padding-bottom: unset !important;',
                                                        value: valueCompany,
                                                        type: 'int'
                                                    }"
                                                    ref="refCuantityCompany"
                                                    @blur="limitCuantity"
                                                />
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <template v-if="getterSanction.show.rejected">
                                <div class="section_line">
                                    <div class="T15_b" style="padding-left:13px;">{{$t('rejected')}}</div>
                                </div>
                                <div class="gh-content-global">
                                    <div class="gh-row-content">
                                        <label class="gh_text-readonly_label T15" >{{$t('rejected_motive') + ': '}}</label>
                                        <label class="gh_text-readonly_label T15_b" style="margin-left: 10px;">{{getterSanction.rejected.text}}</label>
                                    </div>
                                </div>
                            </template>
                            <template v-if="getterSanction.show.resolutions">
                                <div class="section_line">
                                    <div class="T15_b" style="padding-left:13px;">{{$t('resolutions')}}</div>
                                </div>

                                <div class="gh-content-global">
                                    <div v-for="(resolution, keyResolution) in getterSanction.resolutions" :key="'RESOLUTION' + keyResolution">
                                        <div class="gh-row-content">
                                            <label class="gh_text-readonly_label T21" style="margin-left: 160px;">{{$t('notified') + ' ' + resolution.date}}</label>
                                        </div>
                                        <div v-if="resolution.allegation" class="gh-row-content" style="margin-left: 160px;">
                                            <GhChecks
                                                :datalabel="{
                                                    text: $t('is_allegation'),
                                                    style: 'margin-right: 10px;',
                                                    class: 'T15_b',
                                                    id: 'label_criticality_implies_a_warning'
                                                }"
                                                :datainput="{
                                                    required: false,
                                                    selected: resolution.allegation,
                                                    disabled: true,
                                                    type: 'checkbox',
                                                    id: 'value_sanction_company_red_card',
                                                    value: true,
                                                    name: 'sanction[company][red_card]'
                                                }"
                                                ref="RefCompanyRedCard"
                                                @click="checkCompanyRedCard()"
                                            />
                                        </div>
                                        <div class="gh-row-content">
                                            <label class="gh_text-readonly_label T15" style="max-width: 150px; min-width: 150px; text-align: right;">{{$t('answer') + ':'}}</label>
                                            <label class="gh_text-readonly_label T15_b" style="margin-left: 10px;">{{resolution.answer}}</label>
                                        </div>
                                        <div class="gh-row-content">
                                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('documents') + ':'}}</label>
                                            <div style="display: block;width: 100%">
                                                <div style="display: block;width: 100%; margin-right: 5px;">
                                                    <template v-for="(file,indexFile) in resolution.documents">
                                                        <div style="width: 100%; display: flex; padding-left: 5px; padding-top: 2px; padding-bottom: 2px;" :key="'DOCUMENTS' + keyResolution + indexFile" :style="indexFile % 2 === 0 ? 'background-color: #FFFFFF;' : 'background-color: #F3DAB5;'">
                                                            <div style="width: 80%">
                                                                <GhAction
                                                                    :dataction="{
                                                                        id: 'file-download_' + file.id, href: file.file_download, text: file.name, icon: ''
                                                                    }"
                                                                />
                                                            </div>
                                                            <div style="width: 20%; display: flex; flex-direction: row-reverse;">
                                                                <GhAction
                                                                    :dataction="{
                                                                        id: 'download_' + file.id,
                                                                        href: file.file_download,
                                                                        text: $t('download'),
                                                                        icon: require('@/assets/gh_new_dessign/descargar.svg')
                                                                    }"
                                                                />
                                                            </div>
                                                            <br>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <template v-if="getterSanction.show.exemption_employee">
                                            <div class="gh-row-content">
                                                <label class="gh_text-readonly_label T15_b">{{$t('label_work_no_responsable')}}</label>
                                            </div>
                                        </template>
                                        <div class="gh-row-content">
                                            <label class="gh_text-readonly_label T15" style="margin-right: 10px;margin-top: 2px;">{{$t('question_documents_resolve_sanction')}}</label>
                                            <template v-if="$root._route.params.type !== 'view'">
                                                <GhChecks
                                                    :datalabel="{
                                                        text: '',
                                                        style: 'width: 100px',
                                                        id: 'label_validate_yes'
                                                    }"
                                                    :datainput="{
                                                        required: true,
                                                        selected: false,
                                                        label_required: $t('question_documents_resolve_sanction'),
                                                        type: 'radio',
                                                        id: 'validate_yes',
                                                        value: 1,
                                                        name: 'validate[resolve]'
                                                    }"
                                                    @click="showComment = false"
                                                />
                                                <label class="gh_text-readonly_label T15" style="margin-right: 50px;margin-top: 2px;margin-left: 5px;">{{$t('yes')}}</label>
                                                <GhChecks
                                                    :datalabel="{
                                                        text: '',
                                                        style: 'width: 100px',
                                                        id: 'label_validate_no'
                                                    }"
                                                    :datainput="{
                                                        required: true,
                                                        selected: false,
                                                        label_required: $t('question_documents_resolve_sanction'),
                                                        type: 'radio',
                                                        id: 'validate_no',
                                                        value: 0,
                                                        name: 'validate[resolve]'
                                                    }"
                                                    @click="showComment = true"
                                                />
                                                <label class="gh_text-readonly_label T15" style="margin-right: 50px;margin-top: 2px;margin-left: 5px;">{{$t('no')}}</label>
                                            </template>
                                            <template v-else>
                                                <label class="gh_text-readonly_label T15_b" style="margin-left: 10px;margin-top: 2px;">{{resolution.resolve ? $t('yes') : $t('no')}}</label>
                                            </template>
                                        </div>

                                        <div class="gh-row-content" v-if="showComment || resolution.resolve">
                                            <template v-if="$root._route.params.type !== 'view'">
                                                <GhTextarea
                                                    :datalabel="{
                                                        text: $t('comment') + '*',
                                                        class: 'T15_b',
                                                        styles: 'margin-right: 10px;',
                                                    }"
                                                    :datainput="{
                                                        id: 'validate_comment',
                                                        name: 'validate[comment]',
                                                        required: true,
                                                        value: '',
                                                    }"
                                                />
                                            </template>
                                            <template v-else>
                                                <label class="gh_text-readonly_label T15" style="max-width: 150px; min-width: 150px; text-align: right;">{{$t('comment') + ':'}}</label>
                                                <label class="gh_text-readonly_label T15_b" style="margin-left: 10px;">{{resolution.comment}}</label>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </form>
                    </div>
                    <div v-else-if="!haveData">
                        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                            <img :src="require('../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                        </div>
                    </div>
                </template>
            </GhNoDropSelector>

            <div class="centered" style="margin-top: 10px;">
                <template v-if="haveData && $root._route.params.type !== 'view'">
                    <template v-if="getterSanction.button.decline">
                        <GhButton
                            :datainput="{
                                id: 'button_decline',
                                text: $t('decline'),
                                class: 'T19 container-md_button_content',
                                style: ' display: flex;'
                            }"
                            @click="Global.openPopUp('decline_popup')"
                        />
                    </template>
                    <template v-if="getterSanction.button.exonerate">
                        <GhButton
                            :datainput="{
                                id: 'button_exonerate',
                                text: $t('exonerate'),
                                class: 'T19 container-md_button_content',
                                style: ' display: flex;'
                            }"
                            @click="setSanction('exonerate')"
                        />
                    </template>
                    <GhButton
                        :datainput="{
                            id: 'button_accept',
                            text: $t('accept'),
                            class: 'T19 container-md_button_content',
                            style: ' display: flex;'
                        }"
                        @click="setSanction()"
                    />
                </template>
                <GhButton
                    :datainput="{
                        id: 'button_close',
                        text: $t('close'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>

        <component v-bind:is="popups.decline" :props="{id:$root._route.params.id,id_company:$root._route.params.id_company}"/>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import popup_decline from "@/view/shared/popup_decline.vue";

    import {mapGetters} from "vuex";

    export default {
        name: 'sanction_action',
        components: {
            GhLocation,
            GhNoDropSelector,
            GhTextarea,
            GhInputTextField,
            GhChecks,
            GhAction,
            GhButton
        },
        computed: {
            ...mapGetters(['getterSanction'])
        },
        data(){
            return {
                showRedCard: false,
                haveRedCard: false,
                haveData: false,
                showComment: false,
                valueCompany: '',
                refreshInput: 0,
                valueEmployee: [],
                stateRedInput: [],
                popups: {
                    decline: popup_decline
                },
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getSanction', {
                from: this.$root._route.params.type,
                type: this.$root._route.params.type,
                origin: this.$root._route.params.origin,
                id_company: this.$root._route.params.id_company,
                id: this.$root._route.params.id
            }, {root: true});

            if(this.getterSanction.employees.find((i) => i.red_card === true)){
                this.getterSanction.employees.forEach((i) => {
                    this.valueEmployee['uuid' + i.id] = i.red_card_days;
                });
                this.haveRedCard = true;
            }

            if(this.getterSanction.show.show_company_red_card){
                this.haveRedCard = true;
                this.showRedCard = this.getterSanction.company.red_card;
                this.valueCompany = this.getterSanction.company.red_card_days;
            }

            if(this.getterSanction.employees !== undefined && this.getterSanction.employees.length !== 0){
                this.getterSanction.employees.forEach((item,index) => {
                    this.stateRedInput[index] = item.red_card_na ? false : true;
                });
            }

            this.haveData = true;
        },
        methods: {
            limitCuantity(){
                if(this.getterSanction !== undefined && this.getterSanction.range_red_card !== undefined && this.getterSanction.range_red_card.company !== undefined && this.getterSanction.range_red_card.company.from > this.$refs.RefCompanyRedCard[0]['_props']['datainput']['value']){
                    this.valueCompany = this.getterSanction.range_red_card.company.from;
                }
                if(this.getterSanction !== undefined && this.getterSanction.range_red_card !== undefined && this.getterSanction.range_red_card.company !== undefined && this.getterSanction.range_red_card.company.to < this.$refs.RefCompanyRedCard[0]['_props']['datainput']['value']){
                    this.valueCompany = this.getterSanction.range_red_card.company.to;
                }
            },
            limitCuantityEmployee(reference){
                this.valueEmployee['uuid' + reference] = this.$refs['refCuantityEmployee' + reference][0]['_props']['datainput']['value'];
                if(this.getterSanction !== undefined && this.getterSanction.range_red_card !== undefined && this.getterSanction.range_red_card.employee !== undefined && this.getterSanction.range_red_card.employee.from > this.$refs['refCuantityEmployee' + reference][0]['_props']['datainput']['value']){
                    this.valueEmployee['uuid' + reference] = this.getterSanction.range_red_card.employee.from;
                    this.refreshInput++;
                }
                if(this.getterSanction !== undefined && this.getterSanction.range_red_card !== undefined && this.getterSanction.range_red_card.employee !== undefined && this.getterSanction.range_red_card.employee.to < this.$refs['refCuantityEmployee' + reference][0]['_props']['datainput']['value']){
                    this.valueEmployee['uuid' + reference] = this.getterSanction.range_red_card.employee.to;
                    this.refreshInput++;
                }
            },
            checkCompanyRedCard(){
                this.showRedCard = this.$refs.RefCompanyRedCard.selected;
            },
            async setDecline(){
                alert(0);
            },
            async setSanction(type){
                if(this.Global.checkRequired('sanction_form') === 'OK') {
                    const formData = new FormData(document.getElementById('sanction_form'));
                    this.Global.deleteConfirm('delete_confirm','setSanction', {
                        formData: [...formData],
                        id: this.$root._route.params.id,
                        id_company: this.$root._route.params.id_company,
                        from: this.$root._route.params.type,
                        type: type
                    },{
                        'date': this.getterSanction.sanction_date,
                        'company': this.getterSanction.company.name,
                        'incidence_detail': this.getterSanction.incidence_detail,
                        'sanction_detail': this.getterSanction.sanction_detail
                    }, this.$t(type !== undefined ? type : this.$root._route.params.type) + ' ' + this.$t('assent').toLowerCase(), this.$t('preparing_to') + ' ' + this.$t(type !== undefined ? type : this.$root._route.params.type).toLowerCase() + ' ' + this.$t('next_assent'), 'save');
                }
            },
            checkNA(id,index){
                this.stateRedInput[index] = !this.$refs['refNaEmployee' + id][0]['selected'];
                this.refreshInput++;
            }
        }
    }
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'message_upload',
        title: _vm.$t('information'),
        type: 'info',
        style: 'width: 450px; min-width:450px',
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px;'
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.getterConfigurationDocument.loaded && _vm.getterConfigurationForms.list.length !== 0)?_c('div',[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15"},[_vm._v(" "+_vm._s(_vm.$t('form') + ' ')+" "),(_vm.getterConfigurationForms.list.find((i) => i.id === _vm.ConfigurationForms.tempId) !== undefined)?_c('span',{staticClass:"T15_b"},[_vm._v(" "+_vm._s(_vm.$t(_vm.getterConfigurationForms.list.find((i) => i.id === _vm.ConfigurationForms.tempId).name))+" ")]):_vm._e(),(_vm.getterConfigurationDocument.content.status === 200)?[_vm._v(" "+_vm._s(' ' + _vm.$t('has_been_uploaded_successfully').toLowerCase())+" ")]:(_vm.getterConfigurationDocument.content.status === 333)?[_vm._v(" "+_vm._s(' ' + _vm.$t('has_not_been_uploaded_successfully').toLowerCase())+" ")]:_vm._e()],2)])])]):(!_vm.getterConfigurationDocument.loaded)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [(_vm.getterConfigurationDocument.loaded)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_metrics_accept_popup',
                text: _vm.$t('close')
            }},on:{"click":function($event){return _vm.Global.closePopUp('message_upload')}}}):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
// config.js ----------------------------------------------------------

export const API_URL = window.API_URL;
export const LoginUrl = window.API_URL;

export default {LoginUrl,API_URL};

export const IS_CONSOLE_DEBUG = true;

export const APP_SHORT_NAME = "App_short_name";
export const APP_LONG_NAME = "El nombre largo de la APP";


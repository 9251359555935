<template>
    <div class="float_window">
        <GhLocation
            style="margin-top: -5px;"
            :dataLocation="{
                showButton: false,
                secondWindow: true,
                showLoader: 'OK',
                text: $t('action'),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
        />

        <div class="content_page" v-if="haveData">
            <GhNoDropSelector
                :datablock="{
                    id: 'action',
                    title: $t('action')
                }"
            >
                <template v-slot:content>
                    <form id="form_action" v-if="haveData">
                        <div class="gh-content-global" style="margin-left: 6px;">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('name') + '*',
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'action_name',
                                            name: 'action[name]',
                                            style: 'width: 300px',
                                            value: getterConfigurationActionsData.name !== undefined ? getterConfigurationActionsData.name : '',
                                            type: 'text'
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterConfigurationActionsData.name !== undefined ? getterConfigurationActionsData.name : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('description'),
                                            styles: 'width: 150px; min-width: 150px !important; text-align: right; margin-right: 12px;',
                                            class: ''
                                        }"
                                        :datainput="{
                                            id: 'action_description',
                                            name: 'action[description]',
                                            value: getterConfigurationActionsData.description !== undefined ? getterConfigurationActionsData.description : ''
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterConfigurationActionsData.description !== undefined ? getterConfigurationActionsData.description : ''}}</label>
                                </template>
                            </div>
                        </div>
                    </form>
                    <template v-else-if="!haveData">
                        <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                    </template>
                </template>
            </GhNoDropSelector>
            <div class="centered_footer" style="margin-top: 8px;">
                <GhButton v-if="!$root._route.params.view && haveData"
                    :datainput="{
                        id: 'btn_save',
                        text: $t('save')
                    }"
                    @click="setAction()"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_return',
                        text: $t('close')
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";
    import GhLocation from "fe-gh-location-lib";

    export default {
        name: 'view_action',
        components: {
            GhLocation,
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhButton
        },
        computed: {
            ...mapGetters(['getterConfigurationActionsData'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationActionsData', {
                id: this.$root._route.params.id
            },{root:true});

            this.haveData = true;
        },
        methods: {
            setAction(){
                if(this.Global.checkRequired('form_action') === 'OK'){
                    const formData = new FormData(document.getElementById('form_action'));
                    this.$store.dispatch('setActionData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>
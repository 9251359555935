var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.getterGlobalSpinner)?[_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]:[_c('div',{style:(_vm.ListResults.records_results.length !== 0 ? 'padding-top: 12px;' : '')},[(_vm.ListResults.records_results.length !== 0)?_c('GhTableCommon',{ref:"updated_result",attrs:{"extratable":{
                    id: 'results'
                },"header":_vm.header,"data":_vm.ListResults.records_results.length === 0 ? [] : _vm.ListResults.records_results},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                            id: 'view_row_' + itemProp['id'],
                            text: _vm.$t('view'),
                            icon: require('../../../assets/gh_new_dessign/view.svg')
                        }},on:{"click":function($event){return _vm.modifyRow(itemProp, 1);}}}),(_vm.Login.perms.validate)?[_c('GhAction',{attrs:{"dataction":{
                                id: 'modify_row_' + itemProp['id'],
                                text: _vm.$t('modify'),
                                icon: require('../../../assets/gh_new_dessign/edit.svg')
                            }},on:{"click":function($event){return _vm.modifyRow(itemProp, 2);}}}),_c('GhAction',{attrs:{"dataction":{
                                id: 'delete_row_' + itemProp['id'],
                                text: _vm.$t('delete'),
                                icon: require('../../../assets/gh_new_dessign/trash.svg')
                            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','LanguageDetails', {keyword: itemProp.keyword_id, system: itemProp.application_id},{'id': itemProp.keyword_id, 'name': itemProp.keyword_name, 'description': itemProp.keyword_description, 'default_languages': itemProp.default_language, 'name_application': itemProp.application_name, 'status': itemProp.status}, _vm.$t('delete_translate'), _vm.$t('preparing_delete_translate'), 'delete')}}})]:_vm._e()]}}],null,false,4221338495)}):_vm._e()],1)],_c('GhPopup',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
            id: 'row_key',
            title: _vm.$t(_vm.onlyView ? 'view' : 'modify'),
            type: 'info',
            style: 'width: 45%; min-width:1080px;'
        },"bodyConfiguration":{
            id: '',
            class: '',
            style: 'max-height: 400px; min-height: 50px; overflow-y: auto!important;'
        },"footerConfiguration":{
            id: '',
            class: '',
            style: '',
            content: ''
        }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{attrs:{"id":"form_row"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"margin-top":"-10px","margin-bottom":"-20px"}},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('id') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterLanguageDetails.keyword_id))])]),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterLanguageDetails.keyword_name))])]),_c('div',{staticClass:"gh-row-content"},[(!_vm.onlyView)?[_c('GhTextarea',{attrs:{"datalabel":{
                                    text: _vm.$t('description'),
                                    class: 'T15_b',
                                    styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                },"datainput":{
                                    id: 'row_description',
                                    name: 'row[description]',
                                    required: false,
                                    value: _vm.getterLanguageDetails.keyword_description,
                                }}}),_c('input',{attrs:{"type":"hidden","name":"row[system]"},domProps:{"value":_vm.getterLanguageDetails.application_id}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterLanguageDetails.keyword_description))])]],2),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name_application') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterLanguageDetails.application_name))])]),_c('div',{staticClass:"section_line",staticStyle:{"margin-left":"-25px","margin-right":"-25px","margin-top":"10px","margin-bottom":"20px"}},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px"}},[_vm._v(_vm._s(_vm.$t('language_status').toUpperCase()))])]),(_vm.getterLanguageDetails.languages !== undefined && _vm.getterLanguageDetails.languages.length !== 0)?_c('GhTableCommon',{attrs:{"extratable":{
                            id: 'details_table'
                        },"header":_vm.onlyView ? _vm.headerViewDetail : _vm.headerEditDetail,"data":_vm.getterLanguageDetails.languages.length === 0 ? [] : _vm.getterLanguageDetails.languages},scopedSlots:_vm._u([{key:"field_component",fn:function({itemProp, labelProp}){return [(labelProp.field === 'language_id')?[(itemProp.status_id !== 2)?[_c('GhChecks',{attrs:{"datalabel":{
                                            'text': itemProp.status_id === 0 ? _vm.$t('finish') : itemProp.status_id === 1 ? _vm.$t('validate') : _vm.$t('draft')
                                        },"datainput":{
                                            required: false,
                                            type: 'checkbox',
                                            id: 'row_language_' + itemProp.language_id + '_status',
                                            value: itemProp.status_id,
                                            name: 'row[language][' + itemProp.language_id + '][status]',
                                            selected: false,
                                            disabled: false
                                        }}})]:[_vm._v(" "+_vm._s(_vm.$t('validated'))+" ")]]:_vm._e(),(labelProp.field === 'translation')?[(itemProp.status_id !== 2)?[_c('GhInputTextField',{staticStyle:{"margin-left":"-4px","margin-right":"4px"},attrs:{"datalabel":{},"datainput":{
                                            required: labelProp.required,
                                            id: 'row_language_' + itemProp.language_id + '_translation',
                                            name: 'row[language][' + itemProp.language_id + '][translation]',
                                            value: itemProp.translation,
                                        }}})]:[_vm._v(" "+_vm._s(itemProp.translation)+" ")]]:_vm._e()]}}],null,false,473102587)}):_vm._e()],1)])]},proxy:true},{key:"footer",fn:function(){return [(!_vm.onlyView)?[_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_accept_row_key',
                        text: _vm.$t('accept')
                    }},on:{"click":function($event){return _vm.setRow()}}})]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close_row_key',
                    text: _vm.$t('close')
                }},on:{"click":function($event){return _vm.Global.closePopUp('row_key');}}})]},proxy:true}])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_bill',
        title: _vm.$t('bill'),
        type: 'warning',
        style: 'width: 700px; min-width: 700px'
    },"bodyConfiguration":{id: 'popup_bill_body', class: '', style: 'overflow-y: auto;max-height: 800px;padding-left: 20px;', text: _vm.$t('select_type_bill')},"footerConfiguration":{id: 'popup_bill_footer', class: '', style: ''}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{key:_vm.refreshContent,attrs:{"id":"form_bill"}},[_c('div',{key:_vm.refreshInput,staticClass:"gh-row-content",staticStyle:{"margin-top":"20px"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"20px","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('manual')))]),_c('GhInputTextField',{ref:"RefInput",attrs:{"datalabel":{
                        text: '',
                        style: '',
                        class: 'T15_b'
                    },"datainput":{
                        required: true,
                        id: 'sanction_new_cuantity',
                        name: 'sanction[new_cuantity]',
                        style: 'min-width: 50px; width: 50px;',
                        value: '',
                        type: 'int',
                        label_required: _vm.$t('type_bill') + '*'
                    }},on:{"keyup":function($event){_vm.refreshCheck++}}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('days')))])],1),_c('div',{staticClass:"grid-container-2",staticStyle:{"margin-left":"10px","margin-right":"50px"}},_vm._l((_vm.getFeeds.bill_type),function(item,index){return _c('div',{key:index,staticClass:"grid-item",staticStyle:{"display":"flex"}},[_c('GhChecks',{key:index + _vm.refreshCheck,ref:"RefChecks",refInFor:true,staticStyle:{"margin-left":"10px","padding-top":"5px","padding-bottom":"5px"},attrs:{"datalabel":{
                            text: '',
                            style: '',
                            class: '',
                            id: ''
                        },"datainput":{
                            disabled: false,
                            required: true,
                            selected: false,
                            type: 'radio',
                            id: 'worker_' + index,
                            value: index,
                            name: 'bill[automatic]',
                            label_required: _vm.$t('type_bill') + '*'
                        }},on:{"click":function($event){_vm.refreshInput++}}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px","margin-top":"7px"}},[_vm._v(_vm._s(item))])],1)}),0)])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'bill_accept_popup',
                text: _vm.$t('accept'),
                class: 'T19 container-md_button_content',
                style: 'display: flex;'
            }},on:{"click":function($event){return _vm.setBill()}}}),_c('GhButton',{attrs:{"datainput":{
                id: 'bill_close_popup',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: 'display: flex;'
            }},on:{"click":function($event){_vm.Global.closePopUp('popup_bill'); _vm.refreshContent++}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
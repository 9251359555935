var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.Historic.loader)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_c('div',[_c('div',{style:(this.$store.state.Historic.searchData && this.$store.state.Historic.searchData != [] ? 'margin-top: 12px;' : '')},[(this.$store.state.Historic.searchData && this.$store.state.Historic.searchData != [])?_c('GhTableCommon',{attrs:{"extratable":{
                id:'result_search_data'
            },"data":this.$store.state.Historic.searchData && this.$store.state.Historic.searchData != [] && this.$store.state.Historic.searchData.length !== undefined ? this.$store.state.Historic.searchData : [],"header":_vm.header},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [(labelProp === 'infractions')?[_c('div',{staticClass:"ellipsis",attrs:{"title":(itemProp[labelProp].mild !== '' && itemProp[labelProp].mild !== 0 ? itemProp[labelProp].mild + ' ' + _vm.$t('mild_plural') + '; ' : '') + (itemProp[labelProp].serious !== '' && itemProp[labelProp].serious !== 0 ? itemProp[labelProp].serious + ' ' + _vm.$t('serious_plural') + '; ' : '') + (itemProp[labelProp].very_serious !== '' && itemProp[labelProp].very_serious !== 0 ? itemProp[labelProp].very_serious + ' ' + _vm.$t('very_serious_plural') + '; ' : '')}},[(itemProp[labelProp].mild !== '' && itemProp[labelProp].mild !== 0)?[_c('span',{staticClass:"T15_b",staticStyle:{"color":"green"}},[_vm._v(_vm._s(itemProp[labelProp].mild + ' ' + _vm.$t('mild_plural') + '; '))])]:_vm._e(),(itemProp[labelProp].serious !== '' && itemProp[labelProp].serious !== 0)?[_c('span',{staticClass:"T15_b",staticStyle:{"color":"orange"}},[_vm._v(_vm._s(itemProp[labelProp].serious + ' ' + _vm.$t('serious_plural') + '; '))])]:_vm._e(),(itemProp[labelProp].very_serious !== '' && itemProp[labelProp].very_serious !== 0)?[_c('span',{staticClass:"T15_b",staticStyle:{"color":"red"}},[_vm._v(_vm._s(itemProp[labelProp].very_serious + ' ' + _vm.$t('very_serious_plural') + '; '))])]:_vm._e()],2)]:_vm._e(),(labelProp === 'inspections_action' || labelProp === 'infractions_action')?[_c('GhAction',{attrs:{"dataction":{
                            id: 'download_' + labelProp + '_' + itemProp['id'],
                            text: _vm.$t('download'),
                            icon: require('@/assets/gh_new_dessign/descargar.svg')
                        }},on:{"click":function($event){return _vm.downloadSanction(itemProp, labelProp === 'inspections_action' ? 'inspections' : 'infractions')}}})]:_vm._e()]}}],null,false,2241159213)}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
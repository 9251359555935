<template>
    <div v-if="haveData">
        <div class="gh-content-global" style="padding-left: 15px; padding-top: 8px;">
            <div class="gh-row-content" v-for="(item, key) in getterConfigurationForms.list" :key="'FORM' + key" style="padding-bottom: 2px; padding-top: 2px; padding-left: 5px; padding-right: 5px;" :style="key % 2 ? '' : 'background-color: #F3DAB5;'">
                <div>
                    <div style="width: 20%;">
                        <label class="T15_b gh_text-readonly_label">
                            {{item.name}}
                        </label>
                    </div>
                    <div style="width: 40%">
                        <GhEasyUpload
                            :extrablock="{
                                id: 'attach_' + item.id,
                                class: '',
                                style: 'flex-direction: row !important;',
                                name_hidden: 'form[attach][' + item.id + ']',
                                id_hidden: 'attach',
                                icon_attach: require('../../../assets/gh_new_dessign/attach.svg')
                            }"
                            :datalabel="{
                                id: 'label_attach_' + item.id,
                                style: ''
                            }"
                            :datainput="{
                                text: $t('attach') + ' ' + item.name,
                                show_image: false,
                                delete: false,
                                href: 'uploadForm',
                                file_name: '',
                                file_path: '',
                                file_download: '',
                                required: false
                            }"
                            @click="setUpload(item.id)"
                        />
                    </div>
                    <div style="width: 40%;">
                        <GhAction
                            :dataction="{
                                id: 'download_' + item.id,
                                text: $t('download') + ' ' + item.name,
                                icon: require('../../../assets/gh_new_dessign/descargar.svg'),
                                href: item.download.file_download
                            }"
                        />
                    </div>
                </div>
            </div>

            <div class="centered" v-if="getterConfigurationForms.actions !== undefined && getterConfigurationForms.actions.add" style="margin-top: 12px;">
                <GhButton
                    :datainput="{
                        id: 'btn_new_configuration_procedures',
                        text: $t('add')
                    }"
                    @click="Global.openPopUp('popup_add_form')"
                />
            </div>
        </div>

        <component :is="component.message_upload"/>
        <component :is="component.new_form"/>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhAction from "fe-gh-action-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    import message_upload from "@/view/configuration/forms/popups/message_upload.vue";
    import add_form from "@/view/configuration/forms/popups/add_form.vue";

    export default {
        name: 'list_forms',
        components: {
            GhAction,
            GhEasyUpload,
            GhButton
        },
        computed: {
            ...mapGetters(['getterConfigurationForms'])
        },
        data(){
            return {
                haveData: false,
                component: {
                    message_upload: message_upload,
                    new_form: add_form
                }
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationForms', '', {root: true});
            this.haveData = true;
        },
        methods: {
            setUpload(id){
                this.$store.dispatch('setUploadIdDocument', {id: id}, {root: true});
            }
        }
    }
</script>

<style>
    [id^="btn_download_attach_"]{
        display: none !important;
    }
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"35px"}},[_c('div',[_c('GhInputTextField',{attrs:{"datalabel":{
                text: _vm.$t('cif'),
                id: 'label_cif',
                class: '',
                style:'',
            },"datainput":{
                id: 'input_cif',
                name: 'company[cif]',
                type: 'text',
                style: 'width: 150px'
            }}}),_c('GhInputTextField',{staticStyle:{"margin-left":"50px"},attrs:{"datalabel":{
                text: _vm.$t('name'),
                id: 'label_name',
                class: '',
                style:'',
            },"datainput":{
                class: '',
                id: 'input_name',
                name: 'company[name]',
                type: 'text',
                style: 'width: 300px'
            }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
import NotAssociated from "@/view/not_associated/not_associated.vue";

const route = [
    {
        path: '/exempt',
        name: 'exempt',
        props: {showMenu: true},
        component: NotAssociated
    }
];

const routes = [
    ...route,
]

export default routes;
<template>
    <div>
        <template v-if="getterGlobalSpinner">
            <div class="centered" style="margin-top: 10px; margin-bottom: 10px;">
                <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
            </div>
        </template>
        <template v-else>
            <div :style="ListResults.records_results.length !== 0 ? 'padding-top: 12px;' : ''">
                <GhTableCommon v-if="ListResults.records_results.length !== 0" ref="updated_result"
                    :extratable="{
                        id: 'results'
                    }"
                   :header="header"
                   :data="ListResults.records_results.length === 0 ? [] : ListResults.records_results"
                >
                    <template v-slot:actions="{itemProp}">
                        <GhAction
                            :dataction="{
                                id: 'view_row_' + itemProp['id'],
                                text: $t('view'),
                                icon: require('../../../assets/gh_new_dessign/view.svg')
                            }"
                            @click="modifyRow(itemProp, 1);"
                        />
                        <template v-if="Login.perms.validate">
                            <GhAction
                                :dataction="{
                                    id: 'modify_row_' + itemProp['id'],
                                    text: $t('modify'),
                                    icon: require('../../../assets/gh_new_dessign/edit.svg')
                                }"
                                @click="modifyRow(itemProp, 2);"
                            />
                            <GhAction
                                :dataction="{
                                    id: 'delete_row_' + itemProp['id'],
                                    text: $t('delete'),
                                    icon: require('../../../assets/gh_new_dessign/trash.svg')
                                }"
                                @click="Global.deleteConfirm('delete_confirm','LanguageDetails', {keyword: itemProp.keyword_id, system: itemProp.application_id},{'id': itemProp.keyword_id, 'name': itemProp.keyword_name, 'description': itemProp.keyword_description, 'default_languages': itemProp.default_language, 'name_application': itemProp.application_name, 'status': itemProp.status}, $t('delete_translate'), $t('preparing_delete_translate'), 'delete')"
                            />
                        </template>
                    </template>
                </GhTableCommon>
            </div>
        </template>
        <GhPopup v-show="false"
            :generalConfiguration="{
                id: 'row_key',
                title: $t(onlyView ? 'view' : 'modify'),
                type: 'info',
                style: 'width: 45%; min-width:1080px;'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'max-height: 400px; min-height: 50px; overflow-y: auto!important;'
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <form id="form_row">
                    <div class="gh-content-global" style="margin-top: -10px; margin-bottom: -20px;">
                        <div class="gh-row-content">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('id') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterLanguageDetails.keyword_id}}</label>
                        </div>
                        <div class="gh-row-content">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterLanguageDetails.keyword_name}}</label>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!onlyView">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description'),
                                        class: 'T15_b',
                                        styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                    }"
                                    :datainput="{
                                        id: 'row_description',
                                        name: 'row[description]',
                                        required: false,
                                        value: getterLanguageDetails.keyword_description,
                                    }"
                                />
                                <input type="hidden" name="row[system]" :value="getterLanguageDetails.application_id">
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterLanguageDetails.keyword_description}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name_application') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterLanguageDetails.application_name}}</label>
                        </div>

                        <div class="section_line" style="margin-left: -25px; margin-right: -25px; margin-top: 10px; margin-bottom: 20px;">
                            <div class="T15_b" style="padding-left:13px;">{{$t('language_status').toUpperCase()}}</div>
                        </div>

                        <GhTableCommon v-if="getterLanguageDetails.languages !== undefined && getterLanguageDetails.languages.length !== 0"
                            :extratable="{
                                id: 'details_table'
                            }"
                            :header="onlyView ? headerViewDetail : headerEditDetail"
                            :data="getterLanguageDetails.languages.length === 0 ? [] : getterLanguageDetails.languages"
                        >
                            <template v-slot:field_component="{itemProp, labelProp}">
                                <template v-if="labelProp.field === 'language_id'">
                                    <template v-if="itemProp.status_id !== 2">
                                        <GhChecks
                                            :datalabel="{
                                                'text': itemProp.status_id === 0 ? $t('finish') : itemProp.status_id === 1 ? $t('validate') : $t('draft')
                                            }"
                                            :datainput="{
                                                required: false,
                                                type: 'checkbox',
                                                id: 'row_language_' + itemProp.language_id + '_status',
                                                value: itemProp.status_id,
                                                name: 'row[language][' + itemProp.language_id + '][status]',
                                                selected: false,
                                                disabled: false
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        {{$t('validated')}}
                                    </template>
                                </template>
                                <template v-if="labelProp.field === 'translation'">
                                    <template v-if="itemProp.status_id !== 2">
                                        <GhInputTextField
                                            style="margin-left: -4px; margin-right: 4px;"
                                            :datalabel="{}"
                                            :datainput="{
                                                required: labelProp.required,
                                                id: 'row_language_' + itemProp.language_id + '_translation',
                                                name: 'row[language][' + itemProp.language_id + '][translation]',
                                                value: itemProp.translation,
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        {{itemProp.translation}}
                                    </template>
                                </template>
                            </template>
                        </GhTableCommon>
                    </div>
                </form>
            </template>
            <template v-slot:footer>
                <template v-if="!onlyView">
                    <GhButton
                        :datainput="{
                            id: 'btn_accept_row_key',
                            text: $t('accept')
                        }"
                        @click="setRow()"
                    />
                </template>
                <GhButton
                    :datainput="{
                        id: 'btn_close_row_key',
                        text: $t('close')
                    }"
                    @click="Global.closePopUp('row_key');"
                />
            </template>
        </GhPopup>
    </div>
</template>

<script>
import GhTableCommon from "fe-gh-table-common-lib";
import GhPopup from "fe-gh-popup-lib";
import GhButton from "fe-gh-button-lib";
import GhAction from "fe-gh-action-lib";
import GhChecks from "fe-gh-checks-lib";
import GhInputTextField from "fe-gh-inputtextfield-lib";
import GhTextarea from "fe-gh-textarea-lib";

import {mapGetters, mapState} from "vuex";

export default {
    name: "result_search",
    components: {
        GhTableCommon,
        GhPopup,
        GhAction,
        GhButton,
        GhChecks,
        GhInputTextField,
        GhTextarea
    },
    computed: {
        ...mapState(['getFeeds','ListResults', 'Login']),
        ...mapGetters(['getterLanguageDetails', 'getterGlobalSpinner'])
    },
    data(){
        return{
            header: [
                {text: 'id', field: 'keyword_name', sorting: true, typeSort: 'string', style: 'min-width: 130px; max-width: 130px; width: 20%;'},
                {text: 'description', field: 'keyword_description', sorting: true, typeSort: 'string', style: 'min-width: 130px; max-width: 130px; width: 50%;'},
                {text: 'default_languages', field: 'default_language', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                {text: 'name_application', field: 'application_name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                {text: 'status', field: 'status', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                {text: '', field: 'actions', sorting: false},
            ],
            headerViewDetail: [
                {text: 'language', field: 'language_name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                {text: 'translation', field: 'translation', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 80%;'},
                {text: 'default_languages', field: 'default_language', sorting: true, typeSort: 'string', style: 'min-width: 130px; max-width: 130px; width: 130px;'},
                {text: 'status', field: 'status', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
            ],
            headerEditDetail: [
                {text: '', field: 'language_id', field_component: true, style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                {text: 'language', field: 'language_name', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                {text: 'translation', field: 'translation', field_component: true, style: 'min-width: 100px; max-width: 100px; width: 80%;'},
                {text: 'default_languages', field: 'default_language', style: 'min-width: 130px; max-width: 130px; width: 130px;'},
                {text: 'status', field: 'status', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
            ],
            onlyView: true
        }
    },
    updated() {
        this.$parent.$parent.resultsSearch = this.ListResults.records_results.length;
    },
    methods: {
        async modifyRow(item, type){
            this.onlyView = type === 1;
            await this.$store.dispatch('getLanguageDetails', {keyword_id: item.keyword_id, system_id: item.application_id}, {root: true});
            this.Global.openPopUp('row_key');
        },
        async setRow(){
            if(this.Global.checkRequired('form_row') == 'OK') {
                const formData = new FormData(document.getElementById('form_row'));
                this.$store.dispatch('setLanguageDetails', {
                    formData: [...formData],
                    keyword_id: this.getterLanguageDetails.keyword_id
                }, {root: true});
            }
        }
    }
}
</script>
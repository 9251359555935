import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        companies: [],
        refreshShutdownsShutdownsCompanies: false,
        tables: [],
        refreshShutdownsShutdownsCompanyIncidences: false,
    },
    mutations: {
        loadShutdownsShutdownsCompanies(state, payload) {
            state.refreshShutdownsShutdownsCompanies = !state.refreshShutdownsShutdownsCompanies;
            state.companies = payload;
        },
        loadShutdownsShutdownsCompanyIncidences(state, payload) {
            state.refreshShutdownsShutdownsCompanyIncidences = !state.refreshShutdownsShutdownsCompanyIncidences;
            state.tables[payload.id] = payload.data;
        },
    },
    getters: {
        getterShutdownsShutdownsCompanies(state){
            const dummy = state.refreshShutdownsShutdownsCompanies; // eslint-disable-line no-unused-vars
            return state.companies;
        },
        getterShutdownsShutdownsCompanyIncidences(state){
            const dummy = state.refreshShutdownsShutdownsCompanyIncidences; // eslint-disable-line no-unused-vars
            return state.tables;
        }
    },
    actions: {
        async getShutdownsShutdownsCompanies(state){
            await axios.get(
                API_URL + "shutdowns/shutdowns/companies"
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadShutdownsShutdownsCompanies', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getShutdownsShutdownsCompanyIncidences(state,item){
            await axios.get(
                API_URL + "shutdowns/shutdowns/companies/" + item.id
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadShutdownsShutdownsCompanyIncidences', {data:response.data.data, id:item.id});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};
import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        sections: [],
        tables: [],
        refreshTables: false,
        refreshBlockSections: false,
        sanction: [],
        refreshSanctionSection: false,
        refreshSanction: false,
        agrupate: [],
        blockSections: [],
    },
    mutations: {
        loadCountPendingIncidences(state, payload) {
            state.refreshBlockSections = !state.refreshBlockSections;
            state.blockSections = payload;
        },
        loadCompaniesPendingIncidences(state, payload) {
            state.refreshSanctionSection = !state.refreshSanctionSection;
            state.sections = payload;
        },
        loadPendingIncidencesCompany(state, payload) {
            state.refreshTables = !state.refreshTables;
            state.tables[payload.id] = payload.data;
        },
        loadSanction(state, payload) {
            state.refreshSanction = !state.refreshSanction;
            state.sanction = payload;
        },
        loadAgrupate(state, payload) {
            state.agrupate = payload;
        },
    },
    getters:{
        getterTablesSanction(state){
            const dummy = state.refreshTables; // eslint-disable-line no-unused-vars
            return state.tables;
        },
        getterTablesBlockSections(state){
            const dummy = state.refreshBlockSections; // eslint-disable-line no-unused-vars
            return state.blockSections;
        },
        getterSanction(state){
            const dummy = state.refreshSanction; // eslint-disable-line no-unused-vars
            return state.sanction;
        },
        getterSanctionSection(state){
            const dummy = state.refreshSanctionSection; // eslint-disable-line no-unused-vars
            return state.sections;
        }
    },
    actions: {
        async getCountPendingIncidences(state){
            await axios.get(
                API_URL + "app-modules/sanction"
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadCountPendingIncidences', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getCompaniesPendingIncidences(state){
            await axios.get(
                API_URL + "sanction/companies"
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadCompaniesPendingIncidences', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPendingIncidencesCompany(state,item){
            await axios.get(
                API_URL + "sanction/companies/" + item.id
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadPendingIncidencesCompany', {data:response.data.data, id:item.id});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSanction(state,item){
            await axios.get(
                API_URL + (item.from === 'qualify' ? 'sanction' : item.origin + '-inspections') + "/company/" + item.id_company + "/" + item.id
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadSanction', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setSanction(state,item){
            var requestLogin = API_URL + (item.id.from === 'qualify' ? 'sanction' : 'qualified-inspections') + "/company/" + item.id.id_company + "/" + item.id.id + (item.id.type !== undefined ? '/' + item.id.type : '');
            let formData = new FormData();

            if(item.id.type !== 'exonerate') {
                item.id.formData.forEach((i) => {
                    formData.append(i[0], i[1]);
                });
            }

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        window.close();
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDecline(state,item){
            var requestLogin = API_URL + "qualify/company/" + item.id_company + "/" + item.id + "/decline";
            let formData = new FormData();

            item.formData.forEach((i) => {
                formData.append(i[0], i[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        window.close();
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};
import GhContentSearchView from "fe-gh-content-window-search-view-lib";

const routes = [
    {
        path: '/search-window/:idblock',
        name: 'GhContentWindowSearchView',
        props: {showMenu: false, background:true},
        component: GhContentSearchView
    },
]

export default routes;
import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        list: [],
        refreshConfigurationProcedures: false,
        data: [],
        refreshConfigurationProceduresData: false,
    },
    mutations: {
        loadConfigurationProcedures(state, payload) {
            state.refreshConfigurationProcedures = !state.refreshConfigurationProcedures;
            state.list = payload;
        },
        loadConfigurationProceduresData(state, payload) {
            state.refreshConfigurationProceduresData = !state.refreshConfigurationProceduresData;
            state.data = payload;
        },
    },
    getters: {
        getterConfigurationProceduresList(state){
            const dummy = state.refreshConfigurationProcedures; // eslint-disable-line no-unused-vars
            return state.list;
        },
        getterConfigurationProceduresData(state){
            const dummy = state.refreshConfigurationProceduresData; // eslint-disable-line no-unused-vars
            return state.data;
        },
    },
    actions: {
        async getConfigurationProcedures(state){
            await axios.get(
                API_URL + "procedures"
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadConfigurationProcedures', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getConfigurationProceduresData(state, item){
            if(item.id === undefined){
                state.commit("loadConfigurationProceduresData", []);
            }else {
                await axios.get(
                    API_URL + "procedures/" + item.id
                ).then(
                    response => {
                        if (response.status === 200) {
                            state.commit('loadConfigurationProceduresData', response.data.data);
                        }
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async setProcedureData(state, item){
            let request = item.id ? API_URL + "procedures/" + item.id : API_URL + "procedures";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        window.close();
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeProcedures(state, item){
            let request = API_URL + "procedures/" + item.id;
            let formData = new FormData();

            await axios({
                method: "delete",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('getConfigurationSections');
                        await state.dispatch('getConfigurationProcedures');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
import SearchList from "@/view/search/search.vue";

import GhContentSearchView from './window/route';
import SearchResult from './result/route';
import SearchView from './view/route';

const route = [
    {
        path: '/search-window',
        name: 'search-window',
        component: SearchList,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...GhContentSearchView,
    ...SearchResult,
    ...SearchView
]

export default routes;
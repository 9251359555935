<template>
    <div style="padding-bottom: 12px; margin-right: 20px; margin-left: 20px; margin-top: 15px; margin-bottom: 5px;">
        <div :style="'display: flex;padding-left: 10px;background-color: var(--pallette-four);padding-top: 4px;padding-bottom: 5px; height: 17px;'">
            <label class="gh_text_field-label T14 ellipsis" :title="$t('date')" style="width: 75px; cursor: pointer" :class="typeSort" @click="doOrder('date')">
                {{$t('date')}}
            </label>
        </div>
        <template v-for="(item,index) in dataSanctionSection">
            <template>
                <div :key="'actions_' + index" style="display: flex;flex-direction: row-reverse;">
                    <GhAction v-if="getterSanctionSection !== undefined && getterSanctionSection.actions !== undefined && getterSanctionSection.actions.download_inspection !== undefined && getterSanctionSection.actions.download_inspection"
                        :dataction="{
                            id: 'download_inspection_' + item.id,
                            text: $t('download_inspection'),
                            icon: require('@/assets/gh_new_dessign/descargar.svg')
                        }"
                        @click="pdf(item.id,'download')"
                    />
                    <GhAction v-if="getterSanctionSection !== undefined && getterSanctionSection.actions !== undefined && getterSanctionSection.actions.see_inspection !== undefined && getterSanctionSection.actions.see_inspection"
                        :dataction="{
                            id: 'see_inspection_' + item.id,
                            text: $t('see_inspection'),
                            icon: require('@/assets/gh_new_dessign/view.svg')
                        }"
                        @click="pdf(item.id,'see')"
                    />
                    <GhAction v-if="getterSanctionSection !== undefined && getterSanctionSection.actions !== undefined && getterSanctionSection.actions.see_historic !== undefined && getterSanctionSection.actions.see_historic"
                        :dataction="{
                            id: 'see_historic_' + item.id,
                            text: $t('see_historic'),
                            icon: require('@/assets/gh_new_dessign/doc.svg')
                        }"
                        @click="historic(item.id)"
                    />
                </div>
            </template>
            <div :key="index" :style="(index % 2 ? 'background-color: #F3DAB5;' : '') + (getterSanctionSection.actions.download_inspection || getterSanctionSection.actions.see_inspection || getterSanctionSection.actions.see_historic ? 'margin-top: -20px;' : '')">
                <div :class="item.warning ? 'warning_exist': ''">
                    <GhDropDownField
                        :key="refreshDrop"
                        :dataBlock="{
                            id: 'drop_sanction_' + index + '_' + item.id,
                            style: 'padding-top: 2px; padding-bottom: 2px;padding-left: 5px; ',
                            title: item.date + ' ' + item.name,
                            subTitle: item.from !== undefined && item.from !== '' ? $t('from') + ' ' + item.from : '',
                            subStyle: item.from !== undefined ? 'margin-left: 20px; max-width: 200px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;' : '',
                            extraSubTitle: item.extra_text !== undefined ? item.extra_text : '',
                            extraSubStyle: item.from !== undefined ? 'margin-left: 20px; max-width: 200px;white-space: nowrap; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;' : '',
                            isDrop: drop['key' + index]
                        }"
                        ref="refDropDownField"
                    >
                        <template v-slot:content>
                            <div style="padding-right: 18px; padding-left: 13px; padding-bottom: 10px;">
                                <component v-bind:is="TableIncidence" :prop="item"/>
                            </div>
                        </template>
                    </GhDropDownField>
                </div>
            </div>
        </template>
    </div>
</template>

<script>

    import GhDropDownField from "fe-gh-drop-down-field-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapGetters} from "vuex";

    import TableIncidence from "@/view/sanction/sanction/include/table_incidences.vue";

    export default {
        name: 'section_sanction',
        props: ['drop'],
        components: {
            GhDropDownField,
            GhAction
        },
        data(){
            return {
                refreshDrop: 0,
                order: [],
                dataSanctionSection: [],
                typeSort: 'sorting-asc',
                TableIncidence: TableIncidence,
            }
        },
        computed: {
            ...mapGetters(['getterSanctionSection'])
        },
        beforeMount() {
            this.dataSanctionSection = this.getterSanctionSection.list;
        },
        methods: {
            async historic(id){
                await this.$store.dispatch('getHistoricCompany', {id: id,origin: 'sanctions',}, {root: true});
                this.Global.openPopUp('historic_popup');
            },
            async pdf(id,type){
                await this.$store.dispatch('getHistoricCompanyPDF', {id: id, type: type}, {root: true});
            },
            sortsGeneric(x, order) {
                this.dataSanctionSection.sort((a, b) => {
                    if(a[x] == undefined){
                        a[x] = '';
                    }

                    if(b[x] == undefined) {
                        b[x] = '';
                    }

                    if(order == 'asc'){
                        return a[x] > b[x] ? 1 : -1;
                    }else{
                        return a[x] < b[x] ? 1 : -1;
                    }
                });
            },
            doOrder(key){
                if(this.$refs['refDropDownField'] !== undefined) {
                    this.typeSort = this.typeSort === 'sorting-asc' ? 'sorting-desc' : 'sorting-asc';
                    this.sortsGeneric(key, this.typeSort.split('-')[1]);
                    this.order = [];
                    this.$refs['refDropDownField'].forEach((i) => {
                        i.open = false;
                    });
                }
            }
        }
    }
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTableCommon',{ref:'refIncidencesPending' + _vm.prop.id,attrs:{"extratable":{
            id: 'table_incidences_pending_' + _vm.prop.id
        },"header":_vm.header,"data":_vm.getterTablesSanction[_vm.prop.id] === undefined || _vm.getterTablesSanction[_vm.prop.id].list.length === 0 ? [] : _vm.getterTablesSanction[_vm.prop.id].list},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('label',{staticClass:"T15_b",style:('color:' + itemProp['style_criticality'] + ';')},[_vm._v(_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [(_vm.getterTablesSanction[_vm.prop.id].actions.view)?[_c('GhAction',{attrs:{"dataction":{
                        id: 'view_sanction_' + itemProp['id'],
                        text: _vm.$t('view'),
                        item_prop: itemProp,
                        icon: require('@/assets/gh_new_dessign/doc.svg')
                    }},on:{"click":function($event){return _vm.setView(itemProp, 'view')}}})]:_vm._e(),(itemProp.qualify && _vm.getterTablesSanction[_vm.prop.id].actions.qualify)?[_c('GhAction',{attrs:{"dataction":{
                        id: 'edit_company_'+itemProp['id'],
                        text: _vm.$t('qualify'),
                        item_prop: itemProp,
                        icon: require('@/assets/gh_new_dessign/tick.svg')
                    }},on:{"click":function($event){return _vm.setQualify(itemProp, 'qualify')}}})]:_vm._e()]}}],null,false,1283366166)})],1):_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div style="padding-bottom: 12px; margin-right: 20px; margin-left: 20px; margin-top: 15px; margin-bottom: 5px;" v-if="haveData">
        <div :style="'display: flex;padding-left: 10px;background-color: var(--pallette-four);padding-top: 4px;padding-bottom: 5px; height: 17px;'">
            <label class="gh_text_field-label T14 ellipsis" :title="$t('date')" style="width: 75px; cursor: pointer" :class="typeSort" @click="doOrder('date')">
                {{$t('date')}}
            </label>
        </div>
        <template v-for="(item, index) in dataQualifiedInspectionsCompanies">
            <div :key="'actions_' + index" style="display: flex; flex-direction: row-reverse;">
                <template v-if="getterQualifiedInspectionsCompanies.actions.download_inspection">
                    <GhAction
                        :dataction="{
                            id: 'download_inspection_' + item.id,
                            text: $t('download_inspection'),
                            icon: require('../../../assets/gh_new_dessign/descargar.svg')
                        }"
                        @click="setPdf(item.id, 'download')"
                    />
                </template>
                <template v-if="getterQualifiedInspectionsCompanies.actions.see_inspection">
                    <GhAction
                        :dataction="{
                            id: 'see_inspection_' + item.id,
                            text: $t('see_inspection'),
                            icon: require('../../../assets/gh_new_dessign/view.svg')
                        }"
                        @click="setPdf(item.id, 'see')"
                    />
                </template>
                <template v-if="getterQualifiedInspectionsCompanies.actions.see_historic">
                    <GhAction
                        :dataction="{
                            id: 'see_historic_' + item.id,
                            text: $t('see_historic'),
                            icon: require('../../../assets/gh_new_dessign/doc.svg')
                        }"
                        @click="setHistoric(item.id)"
                    />
                </template>
                <template v-if="item.validate">
                    <GhAction
                        :dataction="{
                            id: 'see_validate_' + item.id,
                            text: $t('validate'),
                            icon: require('../../../assets/gh_new_dessign/tick.svg')
                        }"
                        @click="validateInspection(item.id)"
                    />
                </template>
            </div>
            <div
                :key="'content_' + index"
                :style="(!getterQualifiedInspectionsCompanies.actions.see_historic && !getterQualifiedInspectionsCompanies.actions.see_inspection && !getterQualifiedInspectionsCompanies.actions.download_inspection ? '' : 'margin-top: -20px;') + (index % 2 ? 'background-color: #F3DAB5;' : '')"
            >
                <div :class="item.warning ? 'warning_exist': ''">
                    <GhDropDownField
                        :key="refreshDrop"
                        :dataBlock="{
                            id: 'drop_qualified_inspections_' + index + '_' + item.id,
                            style: 'padding-top: 2px; padding-bottom: 2px;padding-left: 5px; ',
                            title: item.date + ' ' + item.name,
                            subTitle: item.from !== undefined && item.from !== '' ? $t('from') + ' ' + item.from : '',
                            subStyle: item.from !== undefined ? 'margin-left: 20px; max-width: 200px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;' : '',
                            extraSubTitle: item.extra_text !== undefined ? item.extra_text : '',
                            extraSubStyle: item.from !== undefined ? 'margin-left: 20px; max-width: 200px;white-space: nowrap; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;' : '',
                            isDrop: drop['key' + index]
                        }"
                        ref="refDropDownField"
                    >
                        <template v-slot:content>
                            <div style="padding-right: 18px; padding-left: 13px; padding-bottom: 10px;">
                                <component v-bind:is="components.incidences" :prop="item"/>
                            </div>
                        </template>
                    </GhDropDownField>
                </div>
            </div>
        </template>

        <component v-bind:is="components.historic"/>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhDropDownField from "fe-gh-drop-down-field-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapGetters} from "vuex";

    import table_qualified_inspections from "@/view/qualified_inspections/qualified_inspections/include/table_qualified_inspections.vue";
    import historic_popup from "@/view/shared/popup_historic.vue";

    export default {
        name: 'section_qualified_inspections',
        props: ['drop'],
        components: {
            GhDropDownField,
            GhAction
        },
        computed: {
            ...mapGetters(['getterQualifiedInspectionsCompanies'])
        },
        data(){
            return {
                haveData: false,
                refreshDrop: 0,
                order: [],
                dataQualifiedInspectionsCompanies: [],
                typeSort: 'sorting-asc',
                timer: [],
                components: {
                    incidences: table_qualified_inspections,
                    historic: historic_popup
                }
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getQualifiedInspectionsCompanies', '', {root: true});
            this.dataQualifiedInspectionsCompanies = this.getterQualifiedInspectionsCompanies.list;
            this.haveData = true;
        },
        methods: {
            async setHistoric(id){
                await this.$store.dispatch('getHistoricCompany', {
                    id: id,
                    origin: 'qualified',
                }, {root: true});

                this.Global.openPopUp('historic_popup');
            },
            async setPdf(id,type){
                await this.$store.dispatch('getHistoricCompanyPDF', {
                    id: id,
                    type: type
                }, {root: true});
            },
            async validateInspection(id){
                this.Global.windowOpen(this.Global.openSecondWindow('validate_all',{
                    id: id
                }),'validate_all');

                var that = this;
                window.UpdatedSelect = async function(e){
                    if(e !== undefined) {
                        await that.$store.dispatch('getRefreshValidateAll', '', {root: true});
                    }
                }

                this.timer = setInterval(await this.checkChildWindow, 500);
            },
            sortsGeneric(x, order) {
                this.dataQualifiedInspectionsCompanies.sort((a, b) => {
                    if(a[x] == undefined){
                        a[x] = '';
                    }

                    if(b[x] == undefined) {
                        b[x] = '';
                    }

                    if(order == 'asc'){
                        return a[x] > b[x] ? 1 : -1;
                    }else{
                        return a[x] < b[x] ? 1 : -1;
                    }
                });
            },
            doOrder(key){
                if(this.$refs['refDropDownField'] !== undefined) {
                    this.typeSort = this.typeSort === 'sorting-asc' ? 'sorting-desc' : 'sorting-asc';
                    this.sortsGeneric(key, this.typeSort.split('-')[1]);
                    this.$parent.$parent.drop = [];
                    this.$refs['refDropDownField'].forEach((i) => {
                        i.open = false;
                    });
                }
            }
        }
    }
</script>

<style>
    [id^="list_table_qualified_inspections_"]{
        min-width: 800px !important;
    }
</style>
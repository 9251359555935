var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTableCommon',{attrs:{"extratable":{
            id: 'certificates'
        },"header":_vm.header,"data":_vm.getterConfigurationCriticalitiesList.list},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('a',{staticClass:"table-cell_link T13",on:{"click":function($event){return _vm.see(itemProp.id,'view_configuration_criticalities')}}},[_vm._v(_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [(_vm.getterConfigurationCriticalitiesList.actions.modify)?[_c('GhAction',{attrs:{"dataction":{
                        id: 'modify_configuration_criticalities',
                        text: _vm.$t('modify'),
                        icon: require('../../../assets/gh_new_dessign/edit.svg')
                    }},on:{"click":function($event){return _vm.edit(itemProp.id,'modify_configuration_criticalities')}}})]:_vm._e(),(_vm.getterConfigurationCriticalitiesList.actions.delete)?[_c('GhAction',{attrs:{"dataction":{
                        id: 'delete_configuration_criticalities',
                        text: _vm.$t('delete'),
                        icon: require('../../../assets/gh_new_dessign/trash.svg')
                    }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeCriticalities', itemProp.id,{'incidence_level': itemProp.level, 'time_red_card': itemProp.time, 'assent': itemProp.sanction, 'assent_detail': itemProp.detail}, _vm.$t('delete_criticalities'), _vm.$t('preparing_delete_criticalities'), 'delete')}}})]:_vm._e()]}},{key:"footer",fn:function(){return [(_vm.getterConfigurationCriticalitiesList.actions.add)?_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_new_configuration_criticalities',
                        text: _vm.$t('add')
                    }},on:{"click":_vm.addCriticality}})],1):_vm._e()]},proxy:true}],null,false,2346151518)})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
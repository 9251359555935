import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        list: [],
        refreshSanctionedCompaniesList: false,
    },
    mutations: {
        loadSanctionedCompaniesList(state, payload) {
            state.refreshSanctionedCompaniesList = !state.refreshSanctionedCompaniesList;
            state.list = payload;
        }
    },
    getters: {
        getterSanctionedCompaniesList(state){
            const dummy = state.refreshSanctionedCompaniesList; // eslint-disable-line no-unused-vars
            return state.list;
        }
    },
    actions: {
        async getSanctionedCompaniesList(state){
            await axios.get(
                API_URL + "sanctioned-companies/sanctioned-companies"
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadSanctionedCompaniesList', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async paySanctionedCompanies(state, item){
            let request = API_URL + "sanctioned-companies/sanctioned-companies/" + item.id + "/payed";

            await axios({
                method: "post",
                url: request,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('getSanctionedCompaniesSections');
                        await state.dispatch('getSanctionedCompaniesList');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setBill(state, item){
            let request = API_URL + "sanctioned-companies/sanctioned-companies/" + item.id + "/bill";

            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('getSanctionedCompaniesSections');
                        await state.dispatch('getSanctionedCompaniesList');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
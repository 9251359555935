<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{
                id: 'certificates'
            }"
            :header="header"
            :data="getterConfigurationCriticalitiesList.list"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <a class="table-cell_link T13" @click="see(itemProp.id,'view_configuration_criticalities')">{{itemProp[labelProp]}}</a>
            </template>
            <template v-slot:actions="{itemProp}">
                <template v-if="getterConfigurationCriticalitiesList.actions.modify">
                    <GhAction
                        :dataction="{
                            id: 'modify_configuration_criticalities',
                            text: $t('modify'),
                            icon: require('../../../assets/gh_new_dessign/edit.svg')
                        }"
                        @click="edit(itemProp.id,'modify_configuration_criticalities')"
                    />
                </template>
                <template v-if="getterConfigurationCriticalitiesList.actions.delete">
                    <GhAction
                        :dataction="{
                            id: 'delete_configuration_criticalities',
                            text: $t('delete'),
                            icon: require('../../../assets/gh_new_dessign/trash.svg')
                        }"
                        @click="Global.deleteConfirm('delete_confirm','removeCriticalities', itemProp.id,{'incidence_level': itemProp.level, 'time_red_card': itemProp.time, 'assent': itemProp.sanction, 'assent_detail': itemProp.detail}, $t('delete_criticalities'), $t('preparing_delete_criticalities'), 'delete')"
                    />
                </template>
            </template>
            <template v-slot:footer>
                <div class="centered" v-if="getterConfigurationCriticalitiesList.actions.add">
                    <GhButton
                        :datainput="{
                            id: 'btn_new_configuration_criticalities',
                            text: $t('add')
                        }"
                        @click="addCriticality"
                    />
                </div>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'list_criticalities',
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapGetters(['getterConfigurationCriticalitiesList'])
        },
        data(){
            return{
                header: [
                    {text: 'incidence_level', field: 'level', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'time_red_card', field: 'time', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'assent', field: 'sanction', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'assent_detail', field: 'detail', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 70%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                timer:[],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationCriticalities', '', {root: true});

            if(!this.getterConfigurationCriticalitiesList.actions.modify && !this.getterConfigurationCriticalitiesList.actions.delete){
                delete this.header[4];

                this.header = this.header.filter(function (item) {
                    return item !== undefined;
                });
            }

            this.haveData = true;
        },
        methods:{
            async addCriticality(){
                this.Global.windowOpen(this.Global.openSecondWindow('new_configuration_criticalities',''),'new_configuration_criticalities',true);
                this.timer = setInterval(await this.checkChildWindow, 500);
            },
            async edit(id,type){
                this.Global.windowOpen(this.Global.openSecondWindow(type,{id: id}),type,true);
                this.timer = setInterval(await this.checkChildWindow, 500);
            },
            async see(id,type){
                this.Global.windowOpen(this.Global.openSecondWindow(type,{id: id,view: true}),type,true);
                this.timer = setInterval(await this.checkChildWindow, 500);
            },
            async checkChildWindow() {
                if (this.Global.state.window.closed) {
                    await this.$store.dispatch('getConfigurationSections', '', {root: true});
                    await this.$store.dispatch('getConfigurationCriticalities', '', {root: true});
                    this.Global.state.window_open = false;
                    clearInterval(this.timer);
                }
            }
        }
    }
</script>
<template>
    <div v-if="haveData" style="margin-top: 10px;">
        <GhTableCommon
            :extratable="{
                id: 'not_associated'
            }"
            :header="header"
            :data="getterNotAssociatedList.list"
        >
            <template v-slot:actions="{itemProp}">
                <template v-if="getterNotAssociatedList.actions.associate">
                    <GhAction
                        :dataction="{
                            id: 'associate_not_associated',
                            text: $t('associate'),
                            icon: require('../../../assets/gh_new_dessign/tick.svg')
                        }"
                        @click="Global.deleteConfirm('delete_confirm','setAssociated', itemProp.id,{'name': itemProp.name, 'cif': itemProp.cif, 'from': itemProp.from}, $t('associate_company'), $t('the_company') + ' ' + itemProp.name + ' ' + $t('will_be_associated_with_the_sanctioning_regime'), 'delete')"
                    />
                </template>
            </template>
            <template v-slot:footer>
                <div class="centered" v-if="getterNotAssociatedList.actions.add">
                    <GhButton
                        :datainput="{
                            id: 'add_not_associated',
                            text: $t('add')
                        }"
                        @click="addAssociate()"
                    />
                </div>
            </template>
        </GhTableCommon>

        <component :is="components.associate" :dataPopup="dataPopup"/>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    import associate from './popups/associated.vue';
    import {API_URL} from "@/common/config";

    export default {
        name: 'list_not_associated',
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapGetters(['getterNotAssociatedList'])
        },
        data(){
            return {
                header: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100%;'},
                    {text: 'cif', field: 'cif', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'from', field: 'from', sorting: true, typeSort: 'date', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                headerSearch:[
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', required: true, label_required: 'company', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'cif', field: 'cif', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 200px; width: 200px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100%;'}
                ],
                components: {
                    associate: associate
                },
                dataPopup: [],
                timer: [],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getNotAssociatedList', '', {root: true});

            if(!this.getterNotAssociatedList.actions.associate){
                delete this.header[3];

                this.header = this.header.filter(function (item) {
                    return item !== undefined;
                });
            }

            this.haveData = true;
        },
        methods: {
            addAssociate(){
                let data = [];

                this.getterNotAssociatedList.list.forEach((i) => {
                   data.push({
                       'id': i.id,
                       'cif': i.cif,
                       'name': i.name,
                       'check': true
                   });
                });

                let then = this;

                let params = {
                    idblock: 'companies',
                    actionsSearch: '',
                    actionsSelected: '',
                    actionsNoDropSelected: '',
                    actionsNoDropSearch: '',
                    dataDisabled: [],
                    allmarkoption: false,
                    supportPermision: false,
                    textuserselected: this.$t('selected_companies'),
                    img: require('@/assets/gh_new_dessign/floating.svg'),
                    textNofilter: this.$t('no_filter_has_been_applied'),
                    textNoResult: this.$t('no_results_found'),
                    nameSlot: 'company-search',
                    nameStore: 'getFeeds',
                    ResultSearch: 'result_search_company',
                    dataSelected: data,
                    actionresult: 'loadResultSearchCompany',
                    textlocation: this.$t('add_no_associated_companies'),
                    headersearch: JSON.stringify(this.headerSearch),
                    headerselected: JSON.stringify(this.headerSearch),
                    formId: 'companies',
                    actionsearch: 'getSearchCompany',
                    checkfilterselected: 1,
                    hiddencheck: false,
                    type: 'checkbox',
                    loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif'),
                    dataUsers: data
                }

                localStorage.setItem('Params_' + 'companies', JSON.stringify(params));
                localStorage.setItem('API_URL', API_URL);
                this.Global.windowOpen(this.Global.openSecondWindow('GhContentWindowSearchView',{ idblock: params.idblock }),'companies_target', true);
                window.UpdatedSelect = function (e) {
                    then.setAssociate(e);
                    localStorage.removeItem('Params_companies');
                    localStorage.removeItem('API_URL');
                }

                window.reloadwindow = function () {}
                window.closewindow = function () {
                    then.Global.state.window_open = false;
                }
                window.events = function () {}
            },
            setAssociate(item){
                this.dataPopup = item;
                this.Global.openPopUp('associated_popup');
            }
        }
    }
</script>
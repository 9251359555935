import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: [],
    },
    mutations: {
        loadHistoricCompany(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        async getHistoricCompany(state,item){
            await axios.get(
                API_URL +  item.origin + "/company/historic/" + item.id
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadHistoricCompany', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getHistoricCompanyPDF(state, item) {
            try {
                const response = await axios.get(API_URL + "sanction/company/pdf/" + item.id, { responseType: 'blob' });

                if (response.status === 200) {
                    const file = new Blob([response.data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);

                    if (item.type === 'see') {
                        window.open(fileURL);
                    } else {
                        let filename = 'downloaded-file.pdf'; // Default filename

                        const contentDisposition = response.headers['content-disposition'];
                        if (contentDisposition) {
                            const match = contentDisposition.match(/filename="(.+)"/);
                            if (match && match[1]) {
                                filename = match[1];
                            }
                        }

                        const link = document.createElement('a');
                        link.href = fileURL;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();

                        document.body.removeChild(link);
                        URL.revokeObjectURL(fileURL);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
};
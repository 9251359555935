<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <GhNoDropSelector :datablock="{ id: 'historic', title: $t('historic'), second_title: ''}">
            <template v-slot:content>
                <component :key="'search_' + refreshView" v-bind:is="SearchHistoric" />
            </template>
        </GhNoDropSelector>
        <GhNoDropSelector :datablock="{
            id: 'result',
            title: typeof Historic.resultTitle == 'number' ? Historic.resultTitle + ' ' + $t('results') : $t((Historic.resultTitle !== undefined ? Historic.resultTitle : 'no_results_found')),
            second_title:''
        }">
            <template v-slot:actions>
                <template v-if="typeof Historic.resultTitle == 'number'">
                    <GhAction
                        :dataction="{
                            id: 'export_excel_result',
                            text: $t('export_excel'),
                            icon: require('@/assets/gh_new_dessign/descargar.svg')
                        }"
                        @click="exportExcel"
                    />
                </template>
            </template>
            <template v-slot:content>
                <component v-bind:is="ResultHistoric" />
            </template>
        </GhNoDropSelector>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px;">
            <img :src="require('../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhAction from 'fe-gh-action-lib';
    import SearchHistoric from './search_content/view_search_historic.vue'
    import ResultHistoric from "@/view/historic/result_content/result_historic.vue";
    import {mapState} from "vuex";

    export default {
        name: 'view_historic',
        components:{
            GhNoDropSelector,
            GhAction
        },
        computed:{
            ...mapState(['Historic'])
        },
        data(){
            return{
                haveData: false,
                refreshView: 0,
                SearchHistoric: SearchHistoric,
                ResultHistoric: ResultHistoric,
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getFeedLevelCriticality', '', {root: true});
            await this.$store.dispatch('getFeedTypeInspection', '', {root: true});
            await this.$store.dispatch('getFeedTypeWork', '', {root: true});

            await this.$store.dispatch('destroyedResultDataHistoric','',{root:true});
            this.haveData = true;
        },
        methods: {
            async exportExcel(){
                const formData = new FormData(document.getElementById('form_search'));
                await this.$store.dispatch('exportDataHistoric',{formData: [...formData]},{root:true});
            }
        }
    }
</script>
import Login from "@/view/login";

const routes = [
    {
        path: '',
        name: 'login',
        props: {showMenu: false},
        component: Login
    }
];

export default routes;
<template>
    <div class="float_window">
        <GhLocation
            style="margin-top: -5px;"
            :dataLocation="{
                showButton: false,
                secondWindow: true,
                showLoader: 'OK',
                text: $t('procedure'),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
        />

        <div class="content_page" v-if="haveData">
            <GhNoDropSelector
                :datablock="{
                    id: 'procedure',
                    title: $t('procedure')
                }"
            >
                <template v-slot:content>
                    <form id="form_procedure" v-if="haveData">
                        <div class="gh-content-global" style="margin-left: 6px;">
                            <template v-if="!$root._route.params.view && $root._route.params.id !== undefined">
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15_b">{{$t('text_warning_config_procedures')}}</label>
                                </div>
                            </template>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('code') + '*',
                                            style: 'width: 160px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'procedure_code',
                                            name: 'procedure[code]',
                                            style: 'width: 100px',
                                            value: getterConfigurationProceduresData.code !== undefined ? getterConfigurationProceduresData.code : '',
                                            type: 'text'
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 160px; min-width: 160px; text-align: right;">{{$t('code') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterConfigurationProceduresData.code !== undefined ? getterConfigurationProceduresData.code : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('name_procedure') + '*',
                                            style: 'width: 160px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'procedure_name',
                                            name: 'procedure[name]',
                                            style: 'width: 300px',
                                            value: getterConfigurationProceduresData.name !== undefined ? getterConfigurationProceduresData.name : '',
                                            type: 'text'
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 160px; min-width: 160px; text-align: right;">{{$t('name_procedure') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterConfigurationProceduresData.name !== undefined ? getterConfigurationProceduresData.name : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('if') + '*',
                                            style: 'width: 160px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'procedure_if',
                                            name: 'procedure[if]',
                                            style: 'width: 50px',
                                            value: getterConfigurationProceduresData.if !== undefined ? getterConfigurationProceduresData.if : '',
                                            type: 'int',
                                            limitNumMin: 1,
                                            limitNumMax: 10
                                        }"
                                    />
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'procedure_incident',
                                            class: 'T15_b',
                                            subtitle: $t('select_incidents') + ':',
                                            text: $t('incidents') + '*',
                                            label: $t('incidents'),
                                            type: 'radio',
                                            columns: 3,
                                            required: true,
                                            style: 'margin-left: 10px;',
                                            popupStyle: 'min-width: 900px;width: 55%;'
                                        }"
                                        :datainput="{
                                            id: 'value_procedure_incident',
                                            name: 'procedure[incident]'
                                        }"
                                        :feed="getFeeds.level_criticity"
                                        :selected_input="getterConfigurationProceduresData.incident !== undefined ? getterConfigurationProceduresData.incident : []"
                                    />
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'procedure_ambit',
                                            class: 'T15_b',
                                            subtitle: $t('select_ambit') + ':',
                                            text: $t('ambit'),
                                            label: $t('ambit'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: false,
                                            style: 'margin-left: 10px;',
                                            popupStyle: 'min-width: 900px;width: 55%;'
                                        }"
                                        :datainput="{
                                            id: 'value_procedure_ambit',
                                            name: 'procedure[ambit][]'
                                        }"
                                        :feed="getFeeds.ambits"
                                        :selected_input="getterConfigurationProceduresData.ambit !== undefined ? getterConfigurationProceduresData.ambit : []"
                                    />
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('in_period') + '*',
                                            style: 'margin-left: 10px; white-space: nowrap;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'procedure_in_period',
                                            name: 'procedure[in_period]',
                                            style: 'width: 50px',
                                            value: getterConfigurationProceduresData.in_period !== undefined ? getterConfigurationProceduresData.in_period : '',
                                            type: 'int',
                                            limitNumMin: 1,
                                            limitNumMax: 24
                                        }"
                                    />
                                    <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{$t('months')}}</label>
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; margin-left: 160px">
                                        {{$t('if') + ' ' + (getterConfigurationProceduresData.if !== undefined ? getterConfigurationProceduresData.if : '') + ' ' + $t('incidents').toLowerCase() + ' ' + Object.values(getterConfigurationProceduresData.incident).join(', ') + ' ' + $t('ambit').toLowerCase() + ' ' + Object.values(getterConfigurationProceduresData.ambit).join(', ') + ' ' + $t('in_period').toLowerCase() + ' ' + (getterConfigurationProceduresData.in_period !== undefined ? getterConfigurationProceduresData.in_period : '') + ' ' + $t('months').toLowerCase()}}
                                    </label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'procedure_action',
                                            class: 'T15_b',
                                            subtitle: $t('select_actions') + ':',
                                            text: $t('actions') + '*',
                                            label: $t('actions'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: true,
                                            style: '',
                                            popupStyle: 'min-width: 900px;width: 55%;'
                                        }"
                                        :datainput="{
                                            id: 'value_procedure_actions',
                                            name: 'procedure[actions][]'
                                        }"
                                        :feed="getFeeds.actions"
                                        :selected_input="getterConfigurationProceduresData.actions !== undefined ? getterConfigurationProceduresData.actions : []"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 160px; min-width: 160px; text-align: right;">{{$t('actions') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(getterConfigurationProceduresData.actions).join(', ')}}</label>
                                </template>
                            </div>
                        </div>
                    </form>
                    <template v-else-if="!haveData">
                        <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                    </template>
                </template>
            </GhNoDropSelector>
            <div class="centered_footer" style="margin-top: 8px;">
                <GhButton v-if="!$root._route.params.view && haveData"
                    :datainput="{
                        id: 'btn_save',
                        text: $t('save')
                    }"
                    @click="setProcedure()"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_return',
                        text: $t('close')
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters, mapState} from "vuex";


    export default {
        name: 'view_procedure',
        components: {
            GhLocation,
            GhNoDropSelector,
            GhInputTextField,
            GhDataSelector,
            GhButton
        },
        computed: {
            ...mapState(['getFeeds']),
            ...mapGetters(['getterConfigurationProceduresData'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getFeedLevelCriticality', '', {root: true});
            await this.$store.dispatch('getFeedAmbits', '', {root: true});
            await this.$store.dispatch('getFeedActions', '', {root: true});
            await this.$store.dispatch('getConfigurationProceduresData', {
                id: this.$root._route.params.id
            }, {root: true});

            this.haveData = true;
        },
        methods: {
            setProcedure(){
                if(this.Global.checkRequired('form_procedure') === 'OK'){
                    const formData = new FormData(document.getElementById('form_procedure'));
                    this.$store.dispatch('setProcedureData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>

<style>
    #procedure_action_selector_label{
        width: 160px;
        text-align: end;
    }

    #procedure_incident_data_select_SUMMARY{
        width: 80px;
    }

    #procedure_ambit_data_select_SUMMARY{
        min-width: 160px;
    }
</style>
import Actions from "@/view/configuration/actions/view/action.vue";

const routes = [
    {
        path: '/configuration/actions',
        name: 'new_configuration_actions',
        component: Actions,
        props: {showMenu: false, background: true, otherTitle: 'actions'},
        children: [
            {
                path: '/configuration/actions/:id',
                name: 'modify_configuration_actions',
                component: Actions,
                props: {id: null, showMenu: false, background: true, otherTitle: 'actions'},
            },
            {
                path: '/configuration/actions/:id/:view',
                name: 'view_configuration_actions',
                component: Actions,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'actions'},
            },
        ]
    }
];

export default routes;
import ViewGroup from '@/view/configuration_permissions/groups/view/group.vue'

const routes = [
{
    path: '/configuration-permissions/group',
    name: 'new_group',
    component: ViewGroup,
    props: {id: null, showMenu: true, otherTitle: 'group'},
    children: [
        {
            path: '/configuration-permissions/group/:id',
            name: 'edit_group',
            component: ViewGroup,
            props: {id: null, showMenu: true, otherTitle: 'group'},
        },
        {
            path: '/configuration-permissions/group/:id/:view',
            name: 'view_group',
            component: ViewGroup,
            props: {id: null, view: true, showMenu: true, otherTitle: 'group'},
        }
    ]
},
    ];

export default routes;
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'list_persons_group',title: _vm.$t('group')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{key:_vm.haveData,attrs:{"id":"form_group"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"refName",attrs:{"datalabel":{
                                    text: _vm.$t('name') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'group_name',
                                    name: 'group[name]',
                                    style: 'width: 25%',
                                    value: _vm.name,
                                    type: 'text'
                                }},on:{"focusout":function($event){return _vm.persitance('refName')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{ref:"refDescription",attrs:{"datalabel":{
                                    text: _vm.$t('description'),
                                    styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;'
                                },"datainput":{
                                    id: 'group_description',
                                    name: 'group[description]',
                                    value: _vm.description
                                }},on:{"focusout":function($event){return _vm.persitance('refDescription')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(''))])]],2),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('permissions') + '*'))])]),_c('div',{staticStyle:{"margin-top":"-29px"}},_vm._l((_vm.getFeeds.feed_perms),function(item,index){return _c('div',{key:index,staticClass:"gh-row-content",class:index % 2 === 0 ? 'striped_white' : 'striped',staticStyle:{"padding":"5px","padding-top":"3px","margin-left":"160px"}},[_c('div',[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},_vm._l((item),function(itemChildren,indexChildren){return _c('div',{key:itemChildren.id + indexChildren,staticStyle:{"display":"flex","min-width":"300px","box-sizing":"border-box"},attrs:{"title":itemChildren.description}},[_c('GhChecks',{attrs:{"datalabel":{
                                                text: '',
                                                style: '',
                                                class: '',
                                                id: ''
                                            },"datainput":{
                                                disabled: _vm.$root._route.params.view,
                                                required: true,
                                                selected: _vm.ListGroups.group_data === undefined || _vm.ListGroups.group_data.permissions === undefined || _vm.ListGroups.group_data.permissions.length === 0 ? false : Object.keys(_vm.ListGroups.group_data.permissions).includes(itemChildren.id),
                                                type: 'checkbox',
                                                id: 'group_permissions_' + itemChildren.id,
                                                value: itemChildren.id,
                                                label_required: _vm.$t('permissions') + '*',
                                                name: 'group[permissions][]'
                                            }}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(itemChildren.name))])],1)}),0)])])}),0),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-top":"10px"}},[_c('div',{key:_vm.refreshUsers},[_c('GhDropDownField',{attrs:{"dataBlock":{
                                    id: 'drop_group_users',
                                    style:'width: 100%; margin-right: 0px;',
                                    title: _vm.$t('users'),
                                    subTitle: _vm.dataUser.length + ' ' + _vm.$t('results'),
                                    isDrop: true
                                }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('GhTableCommon',{attrs:{"extratable":{
                                            id: 'conf_supp_assignment_users'
                                        },"header":_vm.header,"data":_vm.dataUser},scopedSlots:_vm._u([(!_vm.$root._route.params.view)?{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                                                        id: 'btn_add_assignment_users',
                                                        text: _vm.$t('add_users')
                                                    }},on:{"click":function($event){return _vm.searchUsers(_vm.dataUser)}}})],1)]},proxy:true}:null],null,true)}):_vm._e()]},proxy:true}])})],1)]),_vm._l((_vm.dataUser),function(user,keyUser){return [_c('input',{key:keyUser,attrs:{"type":"hidden","name":"group[users][]"},domProps:{"value":user.id}})]})],2)])]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save')
            },"type":"submit"},on:{"click":_vm.submitGroup}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back')
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
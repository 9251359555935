<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{
                id: 'procedures'
            }"
            :header="header"
            :data="getterConfigurationProceduresList.list"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <a class="table-cell_link T13" @click="see(itemProp.id,'view_configuration_procedures')">{{itemProp[labelProp]}}</a>
            </template>
            <template v-slot:actions="{itemProp}">
                <template v-if="getterConfigurationProceduresList.actions.modify">
                    <GhAction
                        :dataction="{
                            id: 'modify_configuration_procedures',
                            text: $t('modify'),
                            icon: require('../../../assets/gh_new_dessign/edit.svg')
                        }"
                        @click="edit(itemProp.id,'modify_configuration_procedures')"
                    />
                </template>
                <template v-if="getterConfigurationProceduresList.actions.delete">
                    <GhAction
                        :dataction="{
                            id: 'delete_configuration_procedures',
                            text: $t('delete'),
                            icon: require('../../../assets/gh_new_dessign/trash.svg')
                        }"
                        @click="Global.deleteConfirm('delete_confirm','removeProcedures', itemProp.id,{'code': itemProp.code, 'name_procedure': itemProp.name, 'start_date': itemProp.start_date, 'end_date': itemProp.end_date, 'incidence_number': itemProp.incidence_number, 'incidence_severity': itemProp.incidence_severity, 'ambit': itemProp.ambit, 'period': itemProp.period, 'actions': itemProp.action}, $t('delete_procedures'), $t('preparing_delete_procedures'), 'delete')"
                    />
                </template>
            </template>
            <template v-slot:footer>
                <div class="centered" v-if="getterConfigurationProceduresList.actions.add">
                    <GhButton
                        :datainput="{
                            id: 'btn_new_configuration_procedures',
                            text: $t('add')
                        }"
                        @click="add"
                    />
                </div>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'list_procedures',
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapGetters(['getterConfigurationProceduresList'])
        },
        data(){
            return{
                header: [
                    {text: 'code', field: 'code', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 33.33%;'},
                    {text: 'start_date', field: 'start_date', sorting: true, typeSort: 'string', style: 'min-width: 90px; max-width: 90px; width: 90px;'},
                    {text: 'end_date', field: 'end_date', sorting: true, typeSort: 'string', style: 'min-width: 90px; max-width: 90px; width: 90px;'},
                    {text: 'incidences', field: 'incidence_number', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'severity', field: 'incidence_severity', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'ambit', field: 'ambit', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 33.33%;'},
                    {text: 'period', field: 'period', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'actions', field: 'action', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 33.33%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                timer: [],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationProcedures', '', {root: true});

            if(!this.getterConfigurationProceduresList.actions.modify && !this.getterConfigurationProceduresList.actions.delete){
                delete this.header[5];

                this.header = this.header.filter(function (item) {
                    return item !== undefined;
                });
            }

            this.haveData = true;
        },
        methods:{
            async add(){
                this.Global.windowOpen(this.Global.openSecondWindow('new_configuration_procedures',''),'new_configuration_procedures',true);
                this.timer = setInterval(await this.checkChildWindow, 500);
            },
            async edit(id,type){
                this.Global.windowOpen(this.Global.openSecondWindow(type,{id: id}),type,true);
                this.timer = setInterval(await this.checkChildWindow, 500);
            },
            async see(id,type){
                this.Global.windowOpen(this.Global.openSecondWindow(type,{id: id,view: true}),type,true);
                this.timer = setInterval(await this.checkChildWindow, 500);
            },
            async checkChildWindow() {
                if (this.Global.state.window.closed) {
                    await this.$store.dispatch('getConfigurationSections', '', {root: true});
                    await this.$store.dispatch('getConfigurationProcedures', '', {root: true});
                    this.Global.state.window_open = false;
                    clearInterval(this.timer);
                }
            }
        }
    }
</script>
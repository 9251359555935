<template>
    <div class="float_window" style="margin-top: -4px;">
        <GhLocation
            :dataLocation="{
                showButton: false,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('detail_sanction'),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'detail_sanction',
                    title: $t('detail_sanction')
                }"
            >
                <template v-slot:content>
                    <div v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ' ' + $t('ee_ss') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{data.data.code}}</label>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ' ' + $t('ee_ss') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{data.data.name}}</label>
                            </div>
                        </div>
                        <GhTableCommon
                            :extratable="{
                                id: 'detail_sanctions'
                            }"
                            :header="header"
                            :data="data.list"
                        />
                    </div>
                    <div v-else-if="!haveData">
                        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                            <img :src="require('../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                        </div>
                    </div>
                </template>
            </GhNoDropSelector>
            <div class="centered_footer" style="margin-top: 8px;">
                <GhButton
                    :datainput="{
                        id: 'btn_return',
                        text: $t('close')
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'view_sanction',
        components: {
            GhButton,
            GhLocation,
            GhNoDropSelector,
            GhTableCommon
        },
        data(){
            return {
                header: [
                    {text: 'incidences', field: 'incidences', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 60%;'},
                    {text: 'gravity', field: 'gravity', keyFormat: 'gravity_style', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'assent', field: 'sanction', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'state', field: 'status', keyFormat: 'status_style', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 80px; width: 20%;'},
                ],
                data: [],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getDetailSections', {
                id: this.$root._route.params.id
            }, {root: true}).then((response) => {
                this.data = response;
            });

            this.haveData = true;
        }
    }
</script>
import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
        group_data: '',
        sections: [],
        data: []
    },
    mutations: {
        loadGroups(state, payload) {
            state.records_data = payload;
        },
        loadCountGroups(state, payload) {
            state.sections = payload;
        },
        loadGroupData(state, payload){
            state.group_data = payload;
        }
    },
    actions: {
        async getGroupsList(state){
            await axios.get(
                API_URL + "groups"
            ).then(
                response => {
                    state.commit('loadGroups', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getCountGroups(state){
            await axios.get(
                API_URL + "app-modules/configuration-permissions"
            ).then(
                response => {
                    state.commit('loadCountGroups', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getGroupData(state, items){
            if(items.id === undefined){
                state.commit("loadGroupData", []);
            }else {
                let requestLogin = API_URL + "groups/" + items.id;
                let formData = new FormData();
                formData.append("id", items.id);
                await axios({
                    method: "get",
                    url: requestLogin,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(
                    response => {
                        state.commit("loadGroupData", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async setGroupData(state, item){
            let requestLogin = item.id ? API_URL + "groups/"+item.id : API_URL + "groups";
            let formData = new FormData();

            formData.append('group[id]', item.id);

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        router.push({name: 'configuration_permissions'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeGroup(state, item){
            let request = API_URL + "groups/" + item.id;
            let formData = new FormData();
            await axios({
                method: "delete",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('getGroupsList');
                        await this.dispatch('getCountGroups');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
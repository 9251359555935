import ConfigPerms from '@/view/configuration_permissions/configuration_permissions.vue';

import ConfigPermsGroup from './groups/route'


const route = [
    {
        path: '/configuration-permissions',
        name: 'configuration_permissions',
        component: ConfigPerms,
        props: {showMenu: true},
    },
];

const routes = [
    ...route,
    ...ConfigPermsGroup
]

export default routes;
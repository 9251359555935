<template>
    <div style="padding-top:10px;">
        <GhTableCommon
            :extratable="{
              id: 'groups',
            }"
            :header="header"
            :data="ListGroups.records_data !== undefined && ListGroups.records_data.list !== undefined && ListGroups.records_data.list.length === 0 ? [] : ListGroups.records_data.list"
        >
            <template v-slot:actions="{itemProp}">
                <template v-if="ListGroups.records_data !== undefined && ListGroups.records_data.actions !== undefined && ListGroups.records_data.actions.modify">
                    <router-link :to="{name: 'edit_group', params: {id: itemProp.id}}">
                        <GhAction
                            :dataction="{
                                id: 'edit_group_' + itemProp['id'],
                                text: $t('modify'),
                                key_show_condition: 'state_id',
                                value_show_condition: 1,
                                icon: require('../../../assets/gh_new_dessign/edit.svg'),
                                item_prop: itemProp
                            }"
                        />
                    </router-link>
                </template>
                <template v-if="ListGroups.records_data !== undefined && ListGroups.records_data.actions !== undefined && ListGroups.records_data.actions.delete">
                    <GhAction
                        :dataction="{
                            id: 'delete_group',
                            text: $t('delete'),
                            icon: require('../../../assets/gh_new_dessign/trash.svg'),
                            item_prop: itemProp
                        }"
                        @click="Global.deleteConfirm('delete_confirm','removeGroup', itemProp.id,{
                            'name': itemProp.name,
                            'description': itemProp.description,
                            'users': itemProp.users,
                        }, $t('delete_group'), $t('preparing_delete_group'), 'delete')"
                    />
                </template>
            </template>
            <template v-slot:footer>
                <template v-if="ListGroups.records_data !== undefined && ListGroups.records_data.actions !== undefined && ListGroups.records_data.actions.add">
                    <div class="centered">
                        <router-link :to="{name: 'new_group'}">
                            <GhButton
                                :datainput="{
                                    id: 'btn_add_group',
                                    text: $t('add')
                                }"
                            />
                        </router-link>
                    </div>
                </template>
            </template>
        </GhTableCommon>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from 'fe-gh-button-lib';
    import GhAction from "fe-gh-action-lib";

    import {mapState} from "vuex";


    export default {
        name: "list_groups",
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        data(){
            return {
                header: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 42.5%;'},
                    {text: 'users', field: 'users', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 42.5%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
                groupModify: [],
                refresh: 0,
                haveData: false,
                titlePopupGroup: 'add_group',
            }
        },
        computed: {
            ...mapState(['ListGroups','getFeeds']),
        },
        async beforeMount() {
            await this.$store.dispatch('getGroupsList','',{root:true});
            if (this.ListGroups.records_data !== undefined && this.ListGroups.records_data.actions !== undefined && !this.ListGroups.records_data.actions.delete && !this.ListGroups.records_data.actions.modify) {
                delete this.header[3];

                this.header = this.header.filter(function (item) {
                    return item !== undefined;
                });
            }

            this.haveData = true;
        },
    }
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-bottom":"12px","margin-right":"20px","margin-left":"20px","margin-top":"15px","margin-bottom":"5px"}},[_c('div',{style:('display: flex;padding-left: 10px;background-color: var(--pallette-four);padding-top: 4px;padding-bottom: 5px; height: 17px;')},[_c('label',{staticClass:"gh_text_field-label T14 ellipsis",class:_vm.typeSort,staticStyle:{"width":"75px","cursor":"pointer"},attrs:{"title":_vm.$t('date')},on:{"click":function($event){return _vm.doOrder('date')}}},[_vm._v(" "+_vm._s(_vm.$t('date'))+" ")])]),_vm._l((_vm.dataSanctionSection),function(item,index){return [[_c('div',{key:'actions_' + index,staticStyle:{"display":"flex","flex-direction":"row-reverse"}},[(_vm.getterSanctionSection !== undefined && _vm.getterSanctionSection.actions !== undefined && _vm.getterSanctionSection.actions.download_inspection !== undefined && _vm.getterSanctionSection.actions.download_inspection)?_c('GhAction',{attrs:{"dataction":{
                        id: 'download_inspection_' + item.id,
                        text: _vm.$t('download_inspection'),
                        icon: require('@/assets/gh_new_dessign/descargar.svg')
                    }},on:{"click":function($event){return _vm.pdf(item.id,'download')}}}):_vm._e(),(_vm.getterSanctionSection !== undefined && _vm.getterSanctionSection.actions !== undefined && _vm.getterSanctionSection.actions.see_inspection !== undefined && _vm.getterSanctionSection.actions.see_inspection)?_c('GhAction',{attrs:{"dataction":{
                        id: 'see_inspection_' + item.id,
                        text: _vm.$t('see_inspection'),
                        icon: require('@/assets/gh_new_dessign/view.svg')
                    }},on:{"click":function($event){return _vm.pdf(item.id,'see')}}}):_vm._e(),(_vm.getterSanctionSection !== undefined && _vm.getterSanctionSection.actions !== undefined && _vm.getterSanctionSection.actions.see_historic !== undefined && _vm.getterSanctionSection.actions.see_historic)?_c('GhAction',{attrs:{"dataction":{
                        id: 'see_historic_' + item.id,
                        text: _vm.$t('see_historic'),
                        icon: require('@/assets/gh_new_dessign/doc.svg')
                    }},on:{"click":function($event){return _vm.historic(item.id)}}}):_vm._e()],1)],_c('div',{key:index,style:((index % 2 ? 'background-color: #F3DAB5;' : '') + (_vm.getterSanctionSection.actions.download_inspection || _vm.getterSanctionSection.actions.see_inspection || _vm.getterSanctionSection.actions.see_historic ? 'margin-top: -20px;' : ''))},[_c('div',{class:item.warning ? 'warning_exist': ''},[_c('GhDropDownField',{key:_vm.refreshDrop,ref:"refDropDownField",refInFor:true,attrs:{"dataBlock":{
                        id: 'drop_sanction_' + index + '_' + item.id,
                        style: 'padding-top: 2px; padding-bottom: 2px;padding-left: 5px; ',
                        title: item.date + ' ' + item.name,
                        subTitle: item.from !== undefined && item.from !== '' ? _vm.$t('from') + ' ' + item.from : '',
                        subStyle: item.from !== undefined ? 'margin-left: 20px; max-width: 200px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;' : '',
                        extraSubTitle: item.extra_text !== undefined ? item.extra_text : '',
                        extraSubStyle: item.from !== undefined ? 'margin-left: 20px; max-width: 200px;white-space: nowrap; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;' : '',
                        isDrop: _vm.drop['key' + index]
                    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticStyle:{"padding-right":"18px","padding-left":"13px","padding-bottom":"10px"}},[_c(_vm.TableIncidence,{tag:"component",attrs:{"prop":item}})],1)]},proxy:true}],null,true)})],1)])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
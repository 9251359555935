import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";

Vue.use(vuex);

export default{
    actions: {
        async getDetailSections(state, item){
            return await axios.get(
                API_URL + "sanction/" + item.id + "/detail"
            ).then(
                response => {
                    if(response.status === 200){
                        return response.data.data;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
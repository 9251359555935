import result_search from "@/view/search/result/result.vue";

const routes = [
    {
        path: '/search_results',
        name: 'results',
        component: result_search,
        props: {showMenu: true}
    },
]
export default routes;
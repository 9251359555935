<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'decline_popup',
            title: $t('decline'),
            type: 'info',
            style: 'width: 1024px; min-width: 1024px'
        }"
        :bodyConfiguration="{id: 'decline_popup_body', class: '', style: 'overflow-y: auto;max-height: 800px;padding-left: 20px;', text: $t('preparing_reject_incidence') + ':'}"
        :footerConfiguration="{id: 'decline_popup_footer', class: '', style: ''}"
    >
        <template v-slot:body>
            <form id="form_decline_sanction">
                <div class="gh-content-global">
                    <div class="gh-row-content" v-if="getterSanction.show.incidence_detail">
                        <label class="gh_text-readonly_label T15" style="min-width: 150px; max-width: 150px; text-align: right;">{{$t('incidence_detail') + ':'}}</label>
                        <label class="gh_text-readonly_label T15_b" style="margin-left: 10px;">{{getterSanction.incidence_detail}}</label>
                    </div>
                    <div class="gh-row-content">
                        <GhTextarea
                            :datalabel="{
                                text: $t('motive') + '*',
                                styles: 'width: 150px; min-width: 150px !important; text-align: right; margin-right: 12px;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                id: 'decline_motive',
                                name: 'decline[motive]',
                                value: '',
                                required: true
                            }"
                        />
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'decline_accept_btn_popup',
                    text: $t('accept'),
                    class: 'T19 container-md_button_content',
                    style: 'display: flex;'
                }"
                @click="setDecline"
            />
            <GhButton
                :datainput="{
                    id: 'decline_close_btn_popup',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: 'display: flex;'
                }"
                @click="Global.closePopUp('decline_popup')"
            />
        </template>
    </GhPopUp>
</template>

<script >
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import {mapGetters} from "vuex";

    export default {
        name: 'popup_decline',
        props: ['props'],
        components:{
            GhTextarea,
            GhPopUp,
            GhButton
        },
        computed: {
            ...mapGetters(['getterSanction'])
        },
        methods:{
            async setDecline(){
                if(this.Global.checkRequired('form_decline_sanction') === 'OK') {
                    const formData = new FormData(document.getElementById('form_decline_sanction'));
                    const formData_2 = new FormData(document.getElementById('sanction_form'));

                    await this.$store.dispatch('setDecline', {
                        formData: [...formData,...formData_2],
                        id: this.props.id,
                        id_company: this.props.id_company
                    }, {root: true});
                 }
            },
        }
    }
</script>
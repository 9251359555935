<template>
    <div style="max-width: 100%; margin-right: auto; margin-left: auto;" :key="'PAGE' + refreshPage">
        <template v-if="$route.matched[0] !== undefined && $route.matched[0].props.default.showMenu">
            <GhMenu ref="refMenu">
                <template v-slot:cabezera>
                    <div class="logo_cabecera_square" style="margin-left: 8px;" >
                        <img style=" max-width: 91px;" :src="require('./assets/login/repsol.png')" alt=""/>
                    </div>
                </template>
                <template v-slot:header-platform_info>
                    <GhUserIcon
                        :generalConfiguration="{
                            class: 'flex-row-textt',
                            icon: require('./assets/gh_new_dessign/user.svg'),
                            title: '',
                            style: 'top: 53px; right: 20px; position:fixed;',
                            id: 'Icon_User'
                        }"
                        :bodyConfiguration="{
                            class: 'flex-row-text',
                            title: '',
                            style: '',
                            id: 'Icon_User_Body'
                        }"
                    />
                    <GhHelpIcon :dataBlock="{icon: require('./assets/gh_new_dessign/help.svg')}"></GhHelpIcon>
                    <GhLanguage
                        :dataBlock="{
                            title: $t('select_language'),
                            id:'select_lang',
                            icon: require('./assets/gh_new_dessign/arrowdown.svg')
                        }"
                        :language="languages"
                        :selected="selectedLanguage"
                    ></GhLanguage>
                    <div class="header-phone T4">
                        {{ '935 306 460' }}
                    </div>
                    <div class="header-email T4">
                        {{ 'sanciones@global-holdings.eu' }}
                    </div>
                </template>
            </GhMenu>
            <div style="margin-top: 93px;"></div>
            <GhLocation
                :key="refreshLocation"
                :dataLocation="{
                    showButton: false,
                    secondWindow: false,
                    showLoader: 'OK',
                    text: $route.matched[0].props.default.otherTitle !== undefined ? $t($route.matched[0].props.default.otherTitle) : $t(Tabs.TabActive)
                }"
                :dataButton="{id: '', text: 'Boton', style: ''}"
            />
            <div id="content_page" :class="this.$route.path === '/' ? '' : 'content_page'">
                <router-view/>

                <GhWarningTimeOut
                    :datablock="{
                        time: 1,
                    }"
                />

                <component :is="popups.popup_401"/>
            </div>
        </template>
        <template v-else>
            <router-view/>
        </template>

        <GhDeleteConfirm
            :visible_fields="Global.state.state_confirm.item_confirm"
            :id_popup="'delete_confirm'"
            :content_warning="Global.state.state_confirm.subheader_confirm"
            v-on:function="setDelete"
        />

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'warning_confirm',
                title: $t('required_fields'),
                type: 'warning',
                style: 'width: 35%; min-width: 430px'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'padding:2px;',
                text: $t('must_be_follow_fields_complete') + ':'
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <div class="gh_delete_confirm-content" style="overflow: auto;max-height: 800px; width: 100%; margin-right: 40px; padding-bottom: 15px;">
                    <div class="T15_b" v-for="(item, index) in globalState.alert_required.requireds" :key="index" style="padding-left: 20px;">
                        {{item}}
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'button',
                        text: $t('close'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="Global.closePopUp('warning_confirm');"
                />
            </template>

        </GhPopUp>

        <div v-if="Global.state.window_open" class="modal-backdrop"></div>
    </div>
</template>

<script>
    import GhMenu from 'fe-gh-menu-lib';
    import GhUserIcon from 'fe-gh-user-icon-lib'
    import GhHelpIcon from 'fe-gh-help-lib';
    import GhLanguage from 'fe-gh-language-lib';
    import GhLocation from 'fe-gh-location-lib';
    import GhDeleteConfirm from 'fe-gh-delete-confirm-lib';
    import GhPopUp from "fe-gh-popup-lib";
    import GhWarningTimeOut from "fe-gh-warning-timeout-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    import popup_401 from "@/view/shared/popup_401.vue";

    export default {
        components: {
            GhMenu,
            GhLocation,
            GhUserIcon,
            GhHelpIcon,
            GhLanguage,
            GhPopUp,
            GhButton,
            GhWarningTimeOut,
            GhDeleteConfirm,
        },
        data(){
            return{
                refreshPage: 0,
                languages: [
                    {id: 'es', extra_value_lang: 'Español'},
                    {id: 'en', extra_value_lang: 'English'}
                ],
                popups: {
                    popup_401: popup_401
                },
                refreshLocation: 0,
                globalState: this.Global.state,
                selectedLanguage: localStorage.getItem('Language-selected-select_lang') ? JSON.parse(localStorage.getItem('Language-selected-select_lang')) : {id:'es',extra_value_lang: 'Español'},
            }
        },
        watch: {
            async '$i18n.locale'(){
                this.Global.changeLanguage();
                this.resizeMenu();
            },
            'Tabs.TabActive'(){
                this.refreshLocation++
            },
            'refreshPage'(){
                let that = this;
                setTimeout(function(){
                    that.resizeMenu();
                },200);
            }
        },
        async beforeMount(){
            this.checkPermissions();
            this.refreshPage++;
        },
        mounted() {
            this.$i18n.locale = this.selectedLanguage.id;
            this.updateBackColor();
            this.checkPermissions();
            this.refreshLocation++
            this.refreshPage++;
        },
        beforeUpdate() {
            this.updateBackColor();
            this.checkPermissions();
            this.resizeMenu();
        },
        computed: {
            ...mapState(['Login', 'Tabs']),
        },
        methods:{
            checkPermissions(){
                if(this.$route.matched !== undefined && this.$route.matched[0] !== undefined && this.$route.matched[0].props.default.showMenu) {
                    this.$router.options.GhTabs = this.Login.tabs;
                }
            },
            resizeMenu(){
                var that = this;
                setTimeout(function (){
                    if(that.$refs.refMenu !== undefined) {
                        var tabs = that.$refs.refMenu.$children[3].$el.children[0].children;
                        for (var i = 0; i < tabs.length; i++) {
                            if (i !== 0) {
                                const NumLetters = that.splitResizeTab(tabs[i].lastChild.textContent).split('\n').reduce(function (a, b) {
                                    return a.length > b.length ? a : b;
                                }).length;
                                const CalculateWidth = 8.67 * NumLetters;
                                that.$refs.refMenu.$children[3].$el.children[0].children[i].lastChild.style.width = CalculateWidth + 'px';
                                if (tabs[i].classList[3] === 'tab-active') {
                                    let maximoaumento = document.getElementById(tabs[i].id).getBoundingClientRect().width;
                                    let destino = document.getElementById(tabs[i].id).getBoundingClientRect().x;
                                    document.getElementById('nav-actived').getElementsByClassName('nav-active-center')[0].animate([{width: maximoaumento - 10 + 'px'},], {
                                        duration: 0,
                                        fill: 'forwards'
                                    });
                                    document.getElementById('nav-actived').animate([{left: destino + 'px'},], {
                                        duration: 0,
                                        fill: 'forwards'
                                    });
                                }
                            }
                        }
                    }
                },150);
            },
            splitResizeTab(value){
                var stringnosplited = value.split(' ');
                var stringsplited = '';
                var stringsplitedaux = '';
                stringnosplited.forEach((string,i) => {
                    if(i === 0){
                        stringsplited = string;
                        stringsplitedaux = string;
                    }else{
                        stringsplitedaux += string;
                        if(stringsplitedaux.length < 12) {
                            stringsplited += ' ' + string;
                        }else{
                            stringsplited+= '\n' + string;
                        }
                    }
                })
                return stringsplited;
            },
            updateBackColor(){
                var r = document.querySelector(':root');
                var color = '#FFFFFF';

                if(this.$route.matched[0].props.default.showMenu || this.$route.matched[0].props.default.background){
                    color = '#DDDDDD';
                }
                r.style.setProperty('--body_color',color);
            },
            setDelete(){
                this.$store.dispatch(this.Global.state.state_confirm.where_confirm,{
                    id: this.Global.state.state_confirm.id_delete_confirm,
                    type: this.Global.state.state_confirm.type_confirm
                },{root:true});
                this.Global.closePopUp('delete_confirm');
            }
        },
    }
</script>

<style>
    * {
        padding: 0;
        margin: 0;
    }

    :root{
      --body_color: #FFFFFF;
    }

    @import './assets/css/styles.css';
    @import '~fe-gh-menu-lib/dist/fe-gh-menu-lib.css';
    @import '~fe-gh-data-selector-lib/dist/fe-gh-data-selector-lib.css';
    @import '~fe-gh-tabs-lib/dist/fe-gh-tabs-lib.css';
    @import '~fe-gh-location-lib/dist/fe-gh-location-lib.css';
    @import '~fe-gh-user-icon-lib/dist/fe-gh-user-icon-lib.css';
    @import "~fe-gh-popup-lib/dist/fe-gh-popup-lib.css";
    @import '~fe-gh-button-lib/dist/fe-gh-button-lib.css';
    @import "~fe-gh-inputtextfield-lib/dist/fe-gh-inputtextfield-lib.css";
    @import "~fe-gh-help-lib/dist/fe-gh-help-lib.css";
    @import "~fe-gh-language-lib/dist/fe-gh-language-lib.css";
    @import "~fe-gh-checks-lib/dist/fe-gh-checks-lib.css";
    @import "~fe-gh-table-common-lib/dist/fe-gh-table-common-lib.css";
    @import "~fe-gh-no-dropselector-lib/dist/fe-gh-no-dropselector-lib.css";
    @import "~fe-gh-dropselector-lib/dist/fe-gh-dropselector-lib.css";
    @import "~fe-gh-input-calendar-lib/dist/fe-gh-input-calendar-lib.css";

    @import "./assets/css/root.css";

    .tab-inactive:hover > div{
        color: var(--pallette-one) !important;
    }

    .T10, .T10_b, .T14, .T15_action {
        color: var(--pallette-five) !important;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }

    body{
        background-color: var( --body_color);
    }

    .content_page{
        padding-left: 12px;
        padding-right: 12px;
    }
</style>

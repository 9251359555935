<template>
    <div class="float_window">
        <GhLocation
            style="margin-top: -5px;"
            :dataLocation="{
                showButton: false,
                secondWindow: true,
                showLoader: 'OK',
                text: $t('criticality'),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
        />

        <div class="content_page" v-if="haveData">
            <GhNoDropSelector
                :datablock="{
                    id: 'criticality',
                    title: $t('criticality')
                }"
            >
                <template v-slot:content>
                    <form id="form_criticality" v-if="haveData">
                        <div class="gh-content-global" style="margin-left: 6px;">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'criticality_level',
                                            class: 'T15_b',
                                            subtitle: $t('select_criticality_level') + ':',
                                            text: $t('criticality_level') + '*',
                                            label: $t('criticality_level'),
                                            type: 'radio',
                                            columns: 3,
                                            required: true,
                                            style: '',
                                            popupStyle: 'min-width: 900px;width: 55%;'
                                        }"
                                        :datainput="{
                                            id: 'value_criticality_level',
                                            name: 'criticality[level]'
                                        }"
                                        :feed="getFeeds.criticity_level"
                                        :selected_input="getterConfigurationCriticalitiesData.level !== undefined ? getterConfigurationCriticalitiesData.level : []"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 300px; min-width: 300px; text-align: right;">{{$t('criticality_level') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(getterConfigurationCriticalitiesData.level).join(', ')}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('criticality_detail'),
                                            styles: 'width: 298px; min-width: 298px !important; text-align: right; margin-right: 12px;',
                                            class: ''
                                        }"
                                        :datainput="{
                                            id: 'criticality_detail_criticality',
                                            name: 'criticality[detail_criticality]',
                                            value: getterConfigurationCriticalitiesData.detail_criticality !== undefined ? getterConfigurationCriticalitiesData.detail_criticality : ''
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 300px; min-width: 300px; text-align: right;">{{$t('criticality_detail') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterConfigurationCriticalitiesData.detail_criticality !== undefined ? getterConfigurationCriticalitiesData.detail_criticality : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('assent_detail'),
                                            styles: 'width: 298px; min-width: 298px !important; text-align: right; margin-right: 12px;',
                                            class: ''
                                        }"
                                        :datainput="{
                                            id: 'criticality_detail_sanction',
                                            name: 'criticality[detail_sanction]',
                                            value: getterConfigurationCriticalitiesData.detail_sanction !== undefined ? getterConfigurationCriticalitiesData.detail_sanction : ''
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 300px; min-width: 300px; text-align: right;">{{$t('assent_detail') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterConfigurationCriticalitiesData.detail_sanction !== undefined ? getterConfigurationCriticalitiesData.detail_sanction : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('economic_sanction_of'),
                                            style: 'width: 300px; text-align: right;',
                                            class: 'T15'
                                        }"
                                        :datainput="{
                                            required: false,
                                            id: 'criticality_pay',
                                            name: 'criticality[pay]',
                                            style: 'width: 80px',
                                            value: getterConfigurationCriticalitiesData.pay !== undefined ? getterConfigurationCriticalitiesData.pay : '',
                                            type: 'text'
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 300px; min-width: 300px; text-align: right;">{{$t('economic_sanction_of')}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterConfigurationCriticalitiesData.pay !== undefined ? getterConfigurationCriticalitiesData.pay : ''}}</label>
                                </template>
                                <label class="gh_text_field-label T15" style="margin-left: 10px; margin-right: 10px">{{'€'}}</label>
                                <GhChecks
                                    :datalabel="{
                                        text: $t('na'),
                                        style: 'width: 130px',
                                        id: 'label_criticality_na_pay'
                                    }"
                                    :datainput="{
                                        required: false,
                                        selected: getterConfigurationCriticalitiesData.na !== undefined && getterConfigurationCriticalitiesData.na === 1,
                                        disabled: $root._route.params.view,
                                        type: 'checkbox',
                                        id: 'value_criticality_na_pay',
                                        value: 1,
                                        name: 'criticality[na]'
                                    }"
                                />
                            </div>
                            <div class="gh-row-content">
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 300px; min-width: 300px; text-align: right;">{{$t('implies_a_warning')}}</label>
                                <GhChecks
                                    :datalabel="{
                                        text: '',
                                        style: 'width: 130px',
                                        id: 'label_criticality_implies_a_warning'
                                    }"
                                    :datainput="{
                                        required: false,
                                        selected: getterConfigurationCriticalitiesData.warning !== undefined && getterConfigurationCriticalitiesData.warning === 1,
                                        disabled: $root._route.params.view,
                                        type: 'checkbox',
                                        id: 'value_criticality_implies_a_warning',
                                        value: 1,
                                        name: 'criticality[warning]'
                                    }"
                                />
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('implies_red_card_to_the_worker_of'),
                                            style: 'width: 300px; text-align: right;',
                                            class: 'T15'
                                        }"
                                        :datainput="{
                                            required: false,
                                            id: 'criticality_red_card_worker_from',
                                            name: 'criticality[red_card][worker][from]',
                                            style: 'width: 80px',
                                            value: getterConfigurationCriticalitiesData.red_card !== undefined && getterConfigurationCriticalitiesData.red_card.worker !== undefined && getterConfigurationCriticalitiesData.red_card.worker.from !== undefined ? getterConfigurationCriticalitiesData.red_card.worker.from : '',
                                            type: 'int'
                                        }"
                                    />
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('a'),
                                            style: 'width: 18px; text-align: right;',
                                            class: 'T15'
                                        }"
                                        :datainput="{
                                            required: false,
                                            id: 'criticality_red_card_worker_to',
                                            name: 'criticality[red_card][worker][to]',
                                            style: 'width: 80px',
                                            value: getterConfigurationCriticalitiesData.red_card !== undefined && getterConfigurationCriticalitiesData.red_card.worker !== undefined && getterConfigurationCriticalitiesData.red_card.worker.to !== undefined ? getterConfigurationCriticalitiesData.red_card.worker.to : '',
                                            type: 'int'
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 300px; min-width: 300px; text-align: right;">{{$t('implies_red_card_to_the_worker_of')}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterConfigurationCriticalitiesData.red_card !== undefined && getterConfigurationCriticalitiesData.red_card.worker !== undefined && getterConfigurationCriticalitiesData.red_card.worker.from !== undefined ? getterConfigurationCriticalitiesData.red_card.worker.from : ''}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$t('a')}}</label>
                                    <label class="gh_text_field-label T15_b">{{getterConfigurationCriticalitiesData.red_card !== undefined && getterConfigurationCriticalitiesData.red_card.worker !== undefined && getterConfigurationCriticalitiesData.red_card.worker.to !== undefined ? getterConfigurationCriticalitiesData.red_card.worker.to : ''}}</label>
                                </template>
                                <label class="gh_text_field-label T15" style="margin-left: 10px; margin-right: 10px">{{$t('days').toLowerCase()}}</label>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('implies_red_card_to_the_company_of'),
                                            style: 'width: 300px; text-align: right;',
                                            class: 'T15'
                                        }"
                                        :datainput="{
                                            required: false,
                                            id: 'criticality_red_card_company_from',
                                            name: 'criticality[red_card][company][from]',
                                            style: 'width: 80px',
                                            value: getterConfigurationCriticalitiesData.red_card !== undefined && getterConfigurationCriticalitiesData.red_card.company !== undefined && getterConfigurationCriticalitiesData.red_card.company.from !== undefined ? getterConfigurationCriticalitiesData.red_card.company.from : '',
                                            type: 'int'
                                        }"
                                    />
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('a'),
                                            style: 'width: 18px; text-align: right;',
                                            class: 'T15'
                                        }"
                                        :datainput="{
                                            required: false,
                                            id: 'criticality_red_card_company_to',
                                            name: 'criticality[red_card][company][to]',
                                            style: 'width: 80px',
                                            value: getterConfigurationCriticalitiesData.red_card !== undefined && getterConfigurationCriticalitiesData.red_card.company !== undefined && getterConfigurationCriticalitiesData.red_card.company.to !== undefined ? getterConfigurationCriticalitiesData.red_card.company.to : '',
                                            type: 'int'
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 300px; min-width: 300px; text-align: right;">{{$t('this_sanction_implies_a_red_card')}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterConfigurationCriticalitiesData.red_card !== undefined && getterConfigurationCriticalitiesData.red_card.company !== undefined && getterConfigurationCriticalitiesData.red_card.company.from !== undefined ? getterConfigurationCriticalitiesData.red_card.company.from : ''}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$t('a')}}</label>
                                    <label class="gh_text_field-label T15_b">{{getterConfigurationCriticalitiesData.red_card !== undefined && getterConfigurationCriticalitiesData.red_card.company !== undefined && getterConfigurationCriticalitiesData.red_card.company.to !== undefined ? getterConfigurationCriticalitiesData.red_card.company.to : ''}}</label>
                                </template>
                                <label class="gh_text_field-label T15" style="margin-left: 10px; margin-right: 10px">{{$t('days').toLowerCase()}}</label>
                            </div>
                        </div>
                    </form>
                    <template v-else-if="!haveData">
                        <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                    </template>
                </template>
            </GhNoDropSelector>
            <div class="centered_footer" style="margin-top: 8px;">
                <GhButton v-if="!$root._route.params.view && haveData"
                    :datainput="{
                        id: 'btn_save',
                        text: $t('save')
                    }"
                    @click="setCriticality()"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_return',
                        text: $t('close')
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters, mapState} from "vuex";
    import GhLocation from "fe-gh-location-lib";

    export default {
        name: 'view_criticality',
        components: {
            GhLocation,
            GhNoDropSelector,
            GhDataSelector,
            GhTextarea,
            GhInputTextField,
            GhChecks,
            GhButton
        },
        computed: {
            ...mapState(['getFeeds']),
            ...mapGetters(['getterConfigurationCriticalitiesData']),
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getFeedCriticalityLevel', '', {root: true});
            await this.$store.dispatch('getConfigurationCriticalitiesData', {
                id: this.$root._route.params.id
            },{root:true});

            this.haveData = true;
        },
        methods: {
            setCriticality(){
                if(this.Global.checkRequired('form_criticality') === 'OK'){
                    const formData = new FormData(document.getElementById('form_criticality'));
                    this.$store.dispatch('setCriticalityData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>

<style>
    #criticality_level_selector_label{
        width: 298px;
        text-align: end;
    }
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticStyle:{"padding-bottom":"12px"}},_vm._l((_vm.data),function(label,labelIndex){return _c('div',{key:labelIndex},[(_vm.QualifiedInspections.sections[label['name']].have_perms)?_c('GhNoDropSelector',{key:'qualified_' + label.id + (label.name === 'qualified_inspections' ? '_' + _vm.getterRefreshValidateAll : ''),attrs:{"datablock":{
                id: 'qualified_inspections_' + label.id,
                title: _vm.$t(label['name']),
                label: _vm.QualifiedInspections.sections[label['name']].count + ' ' + _vm.$t(label['second_text']),
            }},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.QualifiedInspections.sections[label['name']].actions !== undefined && _vm.QualifiedInspections.sections[label['name']].actions.excel)?[_c('GhAction',{attrs:{"dataction":{
                            id: 'qualified_sanctions_export_' + label.id,
                            text: _vm.$t('export_all'),
                            icon: require('../../assets/gh_new_dessign/descargar.svg'),
                        }},on:{"click":function($event){return _vm.setExportExcel(label['name']);}}})]:_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c(label.component,{tag:"component",attrs:{"drop":_vm.drop}})]},proxy:true}],null,true)}):_vm._e()],1)}),0):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
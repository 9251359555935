<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhNoDropSelector v-if="Shutdowns.sections[label['name']].have_perm"
                :datablock="{
                    id: 'shutdowns_' + label.id,
                    title: $t(label['name']),
                    label: Shutdowns.sections[label['name']].count + ' ' + $t(label['second_text'])
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhNoDropSelector>
        </div>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";

    import {mapGetters, mapState} from "vuex";

    import shutdowns from "@/view/shutdowns/shutdowns/shutdowns.vue";

    export default {
        name: 'tab_shutdowns',
        components: {
            GhNoDropSelector
        },
        computed: {
            ...mapState(['Shutdowns']),
            ...mapGetters(['getterShutdownsShutdownsCompanyIncidences'])
        },
        watch: {
            'getterShutdownsShutdownsCompanyIncidences'(){
                document.querySelectorAll('[id^="drop_company_"]').forEach((element) => {
                    let childrens = element.children[0].children[0].children;

                    for (var i = 0; i < childrens.length; i++){
                        if(childrens[i]._prevClass !== 'link T14'){
                            element.children[0].children[0].children[i].setAttribute('title', childrens[i].innerHTML);
                        }
                    }
                });
            }
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'shutdowns', component: shutdowns, second_text: 'results'},
                ],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getShutdownsSections', '', {root: true});

            this.haveData = true;
        }
    }
</script>

<style>
    .definition-field_header-text{
        margin-right: 10px;
    }

    [id^="drop_company_"] > .definition-field-content{
        max-width: 56%;
    }

    [id^="drop_company_"] > .definition-field-content > .definition-field-header{
        max-width: 100%;
    }

    [id^="drop_company_"] > .definition-field-content > .definition-field-header > .link{
        min-width: 25px;
    }

    [id^="drop_company_"] > .definition-field-content > .definition-field-header > .link{
        min-width: 25px;
    }

    [id^="drop_company_"] > .definition-field-content > .definition-field-header > .definition-field_header-text{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
</style>
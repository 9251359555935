<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhNoDropSelector v-if="ListGroups.sections[label['name']].have_perm"
                :datablock="{
                    id: 'configuration_permissions_' + label.id,
                    title: $t(label['name']),
                    label: ListGroups.sections[label['name']].count + ' ' + $t(label['second_text']),
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhNoDropSelector>
        </div>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';

    import {mapState} from "vuex";

    import groups from './groups/groups.vue';

    export default {
        name: "list_config",
        components: {
            GhNoDropSelector,
        },
        computed:{
            ...mapState(['ListGroups']),
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'groups', component: groups, second_text: 'results'},
                ],
                haveData: false
            }
        },
        async beforeMount() {
            await this.$store.dispatch('getCountGroups','',{root:true});

            this.haveData = true;
        }
    }
</script>
<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_add_form',
            title: $t('add_form'),
            type: 'add',
            style: 'width: 600px; min-width:600px',
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px;'
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <form id="form_form">
                <div class="gh-content-global">
                    <div class="gh-row-content">
                        <label class="T21 gh_text-readonly_label">{{$t('enter_a_form_name')}}</label>
                    </div>
                    <div class="gh-row-content">
                        <GhInputTextField
                            :key="refreshInput"
                            :datalabel="{
                                text: '',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                label_required: $t('form'),
                                required: true,
                                id: 'form_name',
                                name: 'form[name]',
                                style: 'width: 100%',
                                value: '',
                                type: 'text'
                            }"
                        />
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'btn_accept_add_form',
                    text: $t('accept')
                }"
                @click="setForm()"
            />
            <GhButton
                :datainput="{
                    id: 'btn_close_add_form',
                    text: $t('close')
                }"
                @click="Global.closePopUp('popup_add_form')"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";

    export default {
        name: 'add_form',
        components: {
            GhInputTextField,
            GhPopUp,
            GhButton
        },
        data(){
            return {
                refreshInput: 0
            }
        },
        methods: {
            setForm(){
                if(this.Global.checkRequired('form_form') === 'OK'){
                    const formData = new FormData(document.getElementById('form_form'));
                    this.$store.dispatch('setFormData', {
                        formData: [...formData]
                    }, {root: true});

                    this.refreshInput++;
                }
            }
        }
    }
</script>
<template>
    <div class="gh-row-content" style="margin-left: 35px">
        <div>
            <GhInputTextField
                :datalabel="{
                    text: $t('name'),
                    id: 'label_group_user_name',
                    class: '',
                    style:'',
                }"
                :datainput="{
                    id: 'input_group_user_name',
                    name: 'user[name]',
                    type: 'text',
                    style: 'width: 150px'
                }"
            />
            <GhInputTextField
                style="margin-left: 50px;"
                :datalabel="{
                    text: $t('surname'),
                    id: 'label_group_user_last_name',
                    class: '',
                    style:'',
                }"
                :datainput="{
                    class: '',
                    id: 'input_group_user_last_name',
                    name: 'user[last_name]',
                    type: 'text',
                    style: 'width: 300px'
                }"
            />
        </div>
    </div>
</template>

<script>
import GhInputTextField from "fe-gh-inputtextfield-lib";
export default {
    name: "view_assignment",
    components:{
        GhInputTextField,
    }
}
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhLocation',{staticStyle:{"margin-top":"-5px"},attrs:{"dataLocation":{
            showButton: false,
            secondWindow: true,
            showLoader: 'OK',
            text: _vm.$t('procedure'),
            img: require('@/assets/gh_new_dessign/floating.svg')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')}}}),(_vm.haveData)?_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'procedure',
                title: _vm.$t('procedure')
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_procedure"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"margin-left":"6px"}},[(!_vm.$root._route.params.view && _vm.$root._route.params.id !== undefined)?[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.$t('text_warning_config_procedures')))])])]:_vm._e(),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('code') + '*',
                                        style: 'width: 160px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'procedure_code',
                                        name: 'procedure[code]',
                                        style: 'width: 100px',
                                        value: _vm.getterConfigurationProceduresData.code !== undefined ? _vm.getterConfigurationProceduresData.code : '',
                                        type: 'text'
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterConfigurationProceduresData.code !== undefined ? _vm.getterConfigurationProceduresData.code : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('name_procedure') + '*',
                                        style: 'width: 160px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'procedure_name',
                                        name: 'procedure[name]',
                                        style: 'width: 300px',
                                        value: _vm.getterConfigurationProceduresData.name !== undefined ? _vm.getterConfigurationProceduresData.name : '',
                                        type: 'text'
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name_procedure') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterConfigurationProceduresData.name !== undefined ? _vm.getterConfigurationProceduresData.name : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('if') + '*',
                                        style: 'width: 160px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'procedure_if',
                                        name: 'procedure[if]',
                                        style: 'width: 50px',
                                        value: _vm.getterConfigurationProceduresData.if !== undefined ? _vm.getterConfigurationProceduresData.if : '',
                                        type: 'int',
                                        limitNumMin: 1,
                                        limitNumMax: 10
                                    }}}),_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'procedure_incident',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_incidents') + ':',
                                        text: _vm.$t('incidents') + '*',
                                        label: _vm.$t('incidents'),
                                        type: 'radio',
                                        columns: 3,
                                        required: true,
                                        style: 'margin-left: 10px;',
                                        popupStyle: 'min-width: 900px;width: 55%;'
                                    },"datainput":{
                                        id: 'value_procedure_incident',
                                        name: 'procedure[incident]'
                                    },"feed":_vm.getFeeds.level_criticity,"selected_input":_vm.getterConfigurationProceduresData.incident !== undefined ? _vm.getterConfigurationProceduresData.incident : []}}),_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'procedure_ambit',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_ambit') + ':',
                                        text: _vm.$t('ambit'),
                                        label: _vm.$t('ambit'),
                                        type: 'checkbox',
                                        columns: 3,
                                        required: false,
                                        style: 'margin-left: 10px;',
                                        popupStyle: 'min-width: 900px;width: 55%;'
                                    },"datainput":{
                                        id: 'value_procedure_ambit',
                                        name: 'procedure[ambit][]'
                                    },"feed":_vm.getFeeds.ambits,"selected_input":_vm.getterConfigurationProceduresData.ambit !== undefined ? _vm.getterConfigurationProceduresData.ambit : []}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('in_period') + '*',
                                        style: 'margin-left: 10px; white-space: nowrap;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'procedure_in_period',
                                        name: 'procedure[in_period]',
                                        style: 'width: 50px',
                                        value: _vm.getterConfigurationProceduresData.in_period !== undefined ? _vm.getterConfigurationProceduresData.in_period : '',
                                        type: 'int',
                                        limitNumMin: 1,
                                        limitNumMax: 24
                                    }}}),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('months')))])]:[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","margin-left":"160px"}},[_vm._v(" "+_vm._s(_vm.$t('if') + ' ' + (_vm.getterConfigurationProceduresData.if !== undefined ? _vm.getterConfigurationProceduresData.if : '') + ' ' + _vm.$t('incidents').toLowerCase() + ' ' + Object.values(_vm.getterConfigurationProceduresData.incident).join(', ') + ' ' + _vm.$t('ambit').toLowerCase() + ' ' + Object.values(_vm.getterConfigurationProceduresData.ambit).join(', ') + ' ' + _vm.$t('in_period').toLowerCase() + ' ' + (_vm.getterConfigurationProceduresData.in_period !== undefined ? _vm.getterConfigurationProceduresData.in_period : '') + ' ' + _vm.$t('months').toLowerCase())+" ")])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'procedure_action',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_actions') + ':',
                                        text: _vm.$t('actions') + '*',
                                        label: _vm.$t('actions'),
                                        type: 'checkbox',
                                        columns: 3,
                                        required: true,
                                        style: '',
                                        popupStyle: 'min-width: 900px;width: 55%;'
                                    },"datainput":{
                                        id: 'value_procedure_actions',
                                        name: 'procedure[actions][]'
                                    },"feed":_vm.getFeeds.actions,"selected_input":_vm.getterConfigurationProceduresData.actions !== undefined ? _vm.getterConfigurationProceduresData.actions : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('actions') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(Object.values(_vm.getterConfigurationProceduresData.actions).join(', ')))])]],2)],2)]):(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})]:_vm._e()]},proxy:true}],null,false,3135668550)}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view && _vm.haveData)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_save',
                    text: _vm.$t('save')
                }},on:{"click":function($event){return _vm.setProcedure()}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_return',
                    text: _vm.$t('close')
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
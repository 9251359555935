import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        list: [],
        refreshConfigurationActions: false,
        data: [],
        refreshConfigurationActionsData: false,
    },
    mutations: {
        loadConfigurationActions(state, payload) {
            state.refreshConfigurationActions = !state.refreshConfigurationActions;
            state.list = payload;
        },
        loadConfigurationActionsData(state, payload) {
            state.refreshConfigurationActionsData = !state.refreshConfigurationActionsData;
            state.data = payload;
        },
    },
    getters: {
        getterConfigurationActionsList(state){
            const dummy = state.refreshConfigurationActions; // eslint-disable-line no-unused-vars
            return state.list;
        },
        getterConfigurationActionsData(state){
            const dummy = state.refreshConfigurationActionsData; // eslint-disable-line no-unused-vars
            return state.data;
        },
    },
    actions: {
        async getConfigurationActions(state){
            await axios.get(
                API_URL + "actions"
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadConfigurationActions', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getConfigurationActionsData(state, item){
            if(item.id === undefined){
                state.commit("loadConfigurationActionsData", []);
            }else {
                await axios.get(
                    API_URL + "actions/" + item.id
                ).then(
                    response => {
                        if (response.status === 200) {
                            state.commit('loadConfigurationActionsData', response.data.data);
                        }
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async setActionData(state, item){
            let request = item.id ? API_URL + "actions/" + item.id : API_URL + "actions";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        window.close();
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeActions(state, item){
            let request = API_URL + "actions/" + item.id;
            let formData = new FormData();

            await axios({
                method: "delete",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('getConfigurationSections');
                        await state.dispatch('getConfigurationActions');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticStyle:{"padding-right":"18px","padding-left":"13px","padding-bottom":"10px"}},[_c('GhTableCommon',{attrs:{"extratable":{
            id: 'table_shutdown_' + _vm.prop.id
        },"header":_vm.header,"data":_vm.getterShutdownsShutdownsCompanyIncidences[_vm.prop.id] !== undefined && _vm.getterShutdownsShutdownsCompanyIncidences[_vm.prop.id].list.length === 0 ? [] : _vm.getterShutdownsShutdownsCompanyIncidences[_vm.prop.id].list},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('label',{staticClass:"T15_b",style:('color:' + itemProp['style_criticality'] + ';')},[_vm._v(_vm._s(itemProp[labelProp]))])]}},{key:"actions",fn:function({itemProp}){return [(_vm.getterShutdownsShutdownsCompanyIncidences[_vm.prop.id].actions.view)?[_c('GhAction',{attrs:{"dataction":{
                        id: 'view_sanction_' + itemProp['id'],
                        text: _vm.$t('view'),
                        item_prop: itemProp,
                        icon: require('@/assets/gh_new_dessign/doc.svg')
                    }},on:{"click":function($event){return _vm.setView(itemProp, 'view')}}})]:_vm._e()]}}],null,false,3656529421)})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'list_persons_group',title: $t('group')}">
            <template v-slot:content>
                <form id="form_group" :key="haveData">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style:'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'group_name',
                                        name: 'group[name]',
                                        style: 'width: 25%',
                                        value: name,
                                        type: 'text'
                                    }"
                                    ref="refName"
                                    @focusout="persitance('refName')"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{''}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description'),
                                        styles: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;'
                                    }"
                                    :datainput="{
                                        id: 'group_description',
                                        name: 'group[description]',
                                        value: description
                                    }"
                                    ref="refDescription"
                                    @focusout="persitance('refDescription')"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{''}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('permissions') + '*'}}</label>
                        </div>
                        <div style="margin-top: -29px;">
                            <div v-for="(item,index) in getFeeds.feed_perms" :key="index" :class="index % 2 === 0 ? 'striped_white' : 'striped'" class="gh-row-content" style="padding: 5px; padding-top: 3px; margin-left: 160px;">
                                <div>
                                    <div style="display: flex; flex-wrap: wrap;">
                                        <div v-for="(itemChildren, indexChildren) in item" :key="itemChildren.id + indexChildren" :title="itemChildren.description" style="display: flex; min-width: 300px; box-sizing: border-box;">
                                            <GhChecks
                                                :datalabel="{
                                                    text: '',
                                                    style: '',
                                                    class: '',
                                                    id: ''
                                                }"
                                                :datainput="{
                                                    disabled: $root._route.params.view,
                                                    required: true,
                                                    selected: ListGroups.group_data === undefined || ListGroups.group_data.permissions === undefined || ListGroups.group_data.permissions.length === 0 ? false : Object.keys(ListGroups.group_data.permissions).includes(itemChildren.id),
                                                    type: 'checkbox',
                                                    id: 'group_permissions_' + itemChildren.id,
                                                    value: itemChildren.id,
                                                    label_required: $t('permissions') + '*',
                                                    name: 'group[permissions][]'
                                                }"
                                            />
                                            <label class="gh_text_field-label T15" style="margin-left: 10px;">{{itemChildren.name}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="gh-row-content" style="margin-top: 10px;">
                            <div :key="refreshUsers">
                                <GhDropDownField
                                    :dataBlock="{
                                        id: 'drop_group_users',
                                        style:'width: 100%; margin-right: 0px;',
                                        title: $t('users'),
                                        subTitle: dataUser.length + ' ' + $t('results'),
                                        isDrop: true
                                    }"
                                >
                                    <template v-slot:content>
                                        <GhTableCommon v-if="haveData"
                                            :extratable="{
                                                id: 'conf_supp_assignment_users'
                                            }"
                                            :header="header"
                                            :data="dataUser"
                                        >
                                            <template v-slot:footer v-if="!$root._route.params.view">
                                                <div class="centered">
                                                    <GhButton
                                                        :datainput="{
                                                            id: 'btn_add_assignment_users',
                                                            text: $t('add_users')
                                                        }"
                                                        @click="searchUsers(dataUser)"
                                                    />
                                                </div>
                                            </template>
                                        </GhTableCommon>
                                    </template>
                                </GhDropDownField>
                            </div>
                        </div>
                        <template v-for="(user, keyUser) in dataUser">
                            <input type="hidden" name="group[users][]" :value="user.id" :key="keyUser">
                        </template>
                    </div>
                </form>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save')
                }"
                type="submit"
                @click="submitGroup"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back')
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhDropDownField from "fe-gh-drop-down-field-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from "fe-gh-button-lib";
    import {API_URL} from "@/common/config";

    import {mapState, mapGetters} from "vuex";

    export default {
        name: "view_group",
        components: {
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhChecks,
            GhDropDownField,
            GhTableCommon,
            GhButton
        },
        computed: {
            ...mapState(['getFeeds', 'ListGroups']),
            ...mapGetters(['getterGroupUsers'])
        },
        data(){
            return {
                haveData: false,
                name: '',
                description: '',
                dataUser: [],
                header: [
                    {text: 'name', field: 'first_name', sorting: true,typeSort: 'string', style:'min-width: 150px; max-width: 150px; width: 50%;'},
                    {text: 'surname', field: 'last_name', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 50%;'},
                ],
                headerSearch: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'name', field: 'first_name', sorting: true,typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 50%;'},
                    {text: 'surname', field: 'last_name', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 50%;'},
                ],
                refreshUsers: 0,
                timer: []
            }
        },
        async beforeMount(){
            await this.$store.dispatch('cleanTemporalGroupUsers', '', {root: true});
            await this.$store.dispatch('getFeedPerms','',{root:true});
            await this.$store.dispatch('getGroupData', {id: this.$root._route.params.id},{root:true});

            if(this.$root._route.params.id !== undefined){
                this.dataUser = this.ListGroups.group_data.users !== undefined && this.ListGroups.group_data.users.length !== 0 ? this.ListGroups.group_data.users : [];
            }

            this.name = this.ListGroups.group_data.name !== undefined ? this.ListGroups.group_data.name : '';
            this.description = this.ListGroups.group_data.description !== undefined ? this.ListGroups.group_data.description : '';
            this.haveData = true;
        },
        watch: {
            'getterGroupUsers'(data){
                this.dataUser = data;
                this.refreshUsers++;
            }
        },
        methods: {
            persitance(ref){
                switch(ref){
                    case 'refName':
                        this.name = this.$refs[ref].datainput.value;
                        break;
                    case 'refDescription':
                        this.description = this.$refs[ref].datainput.value;
                        break;
                }

                this.refreshUsers++;
            },
            searchUsers(data){
                var then=this;
                var params = {
                    idblock: 'users',
                    actionsSearch: '',
                    actionsSelected: '',
                    actionsNoDropSelected: '',
                    actionsNoDropSearch: '',
                    dataDisabled: [],
                    allmarkoption: false,
                    supportPermision: false,
                    textuserselected: this.$t('selected_users'),
                    img: require('@/assets/gh_new_dessign/floating.svg'),
                    textNofilter: this.$t('no_filter_has_been_applied'),
                    textNoResult: this.$t('no_results_found'),
                    nameSlot: 'user-search',
                    nameStore: 'ListResults',
                    ResultSearch: 'searchDataUsers',
                    dataSelected: data,
                    actionresult: 'loadSearchGroupUsers',
                    textlocation: this.$t('add_users'),
                    headersearch: JSON.stringify(this.headerSearch),
                    headerselected: JSON.stringify(this.headerSearch),
                    formId: 'user',
                    actionsearch: 'getSearchGroupUsers',
                    checkfilterselected: 1,
                    hiddencheck: false,
                    type: 'checkbox',
                    loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif'),
                    dataUsers: this.dataUser
                }

                localStorage.setItem('Params_' + 'users', JSON.stringify(params));
                localStorage.setItem('API_URL', API_URL);
                this.Global.windowOpen(this.Global.openSecondWindow('GhContentWindowSearchView',{ idblock: params.idblock }),'users_target', true);
                window.UpdatedSelect = function (e) {
                    then.$store.dispatch('setTemporalGroupUsers', {data: e}, {root: true});
                    localStorage.removeItem('Params_users');
                    localStorage.removeItem('API_URL');
                }

                window.reloadwindow = function () {}
                window.closewindow = function () {
                    then.Global.state.window_open = false;
                }
                window.events = function () {}
            },
            async submitGroup() {
                if(this.Global.checkRequired('form_group') === 'OK') {
                    const formData = new FormData(document.getElementById('form_group'));
                    this.$store.dispatch('setGroupData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            },
        }
    }
</script>

<style>
    #drop_group_users > div > div > .definition-field_header-text {
        width: 150px;
        text-align: right;
    }
</style>
<template>
    <div style="padding-bottom: 12px;">
        <GhNoDropSelector
            :datablock="{
                id: 'filters_block',
                title: $t('seeker'),
                second_title: ''
            }"
        >
            <template v-slot:content>
                <component v-bind:is="searchgeneral.filters" />
            </template>
        </GhNoDropSelector>
        <GhNoDropSelector
            :datablock="{
                id: 'results_block',
                title: (resultsSearch != '' ? resultsSearch+' ' : '') + $t('results'),
                second_title:''
            }"
        >
            <template v-slot:content>
                <component v-bind:is="searchgeneral.result" />
            </template>
        </GhNoDropSelector>
    </div>
</template>

<script>
import GhNoDropSelector from "fe-gh-no-dropselector-lib";
import view_search from "@/view/search/view/search";
import result_search from "@/view/search/result/result";

export default {
    name: "list_search",
    components: {
        GhNoDropSelector,
    },
    data(){
        return{
            searchgeneral: {filters: view_search,result:result_search},
            resultsSearch: '',
        }
    },
    beforeMount() {
        this.$store.dispatch('cleanGlobalSpinner', '', {root: true});
    },
    beforeDestroy() {
        this.$store.dispatch('destroyedResultData','',{root:true});
    }
}
</script>
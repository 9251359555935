import QualifiedInspections from "@/view/qualified_inspections/qualified_inspections.vue";

import ValidateAll from './window/route';

const route = [
    {
        path: '/qualified_inspections',
        name: 'qualified_inspections',
        props: {showMenu: true},
        component: QualifiedInspections
    }
];

const routes = [
    ...route,
    ...ValidateAll
]

export default routes;
import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        list: [],
        refreshConfigurationCriticalities: false,
        data: [],
        refreshConfigurationCriticalitiesData: false,
    },
    mutations: {
        loadConfigurationCriticalities(state, payload) {
            state.refreshConfigurationCriticalities = !state.refreshConfigurationCriticalities;
            state.list = payload;
        },
        loadConfigurationCriticalitiesData(state, payload) {
            state.refreshConfigurationCriticalitiesData = !state.refreshConfigurationCriticalitiesData;
            state.data = payload;
        },
    },
    getters: {
        getterConfigurationCriticalitiesList(state){
            const dummy = state.refreshConfigurationCriticalities; // eslint-disable-line no-unused-vars
            return state.list;
        },
        getterConfigurationCriticalitiesData(state){
            const dummy = state.refreshConfigurationCriticalitiesData; // eslint-disable-line no-unused-vars
            return state.data;
        },
    },
    actions: {
        async getConfigurationCriticalities(state){
            await axios.get(
                API_URL + "criticalities"
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadConfigurationCriticalities', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getConfigurationCriticalitiesData(state, item){
            if(item.id === undefined){
                state.commit("loadConfigurationCriticalitiesData", []);
            }else {
                await axios.get(
                    API_URL + "criticalities/" + item.id
                ).then(
                    response => {
                        if (response.status === 200) {
                            state.commit('loadConfigurationCriticalitiesData', response.data.data);
                        }
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async setCriticalityData(state, item){
            let request = item.id ? API_URL + "criticalities/" + item.id : API_URL + "criticalities";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        window.close();
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeCriticalities(state, item){
            let request = API_URL + "criticalities/" + item.id;
            let formData = new FormData();

            await axios({
                method: "delete",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('getConfigurationSections');
                        await state.dispatch('getConfigurationCriticalities');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"10px"}},[_c('GhTableCommon',{attrs:{"extratable":{
          id: 'groups',
        },"header":_vm.header,"data":_vm.ListGroups.records_data !== undefined && _vm.ListGroups.records_data.list !== undefined && _vm.ListGroups.records_data.list.length === 0 ? [] : _vm.ListGroups.records_data.list},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(_vm.ListGroups.records_data !== undefined && _vm.ListGroups.records_data.actions !== undefined && _vm.ListGroups.records_data.actions.modify)?[_c('router-link',{attrs:{"to":{name: 'edit_group', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                            id: 'edit_group_' + itemProp['id'],
                            text: _vm.$t('modify'),
                            key_show_condition: 'state_id',
                            value_show_condition: 1,
                            icon: require('../../../assets/gh_new_dessign/edit.svg'),
                            item_prop: itemProp
                        }}})],1)]:_vm._e(),(_vm.ListGroups.records_data !== undefined && _vm.ListGroups.records_data.actions !== undefined && _vm.ListGroups.records_data.actions.delete)?[_c('GhAction',{attrs:{"dataction":{
                        id: 'delete_group',
                        text: _vm.$t('delete'),
                        icon: require('../../../assets/gh_new_dessign/trash.svg'),
                        item_prop: itemProp
                    }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeGroup', itemProp.id,{
                        'name': itemProp.name,
                        'description': itemProp.description,
                        'users': itemProp.users,
                    }, _vm.$t('delete_group'), _vm.$t('preparing_delete_group'), 'delete')}}})]:_vm._e()]}},{key:"footer",fn:function(){return [(_vm.ListGroups.records_data !== undefined && _vm.ListGroups.records_data.actions !== undefined && _vm.ListGroups.records_data.actions.add)?[_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'new_group'}}},[_c('GhButton',{attrs:{"datainput":{
                                id: 'btn_add_group',
                                text: _vm.$t('add')
                            }}})],1)],1)]:_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
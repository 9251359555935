import Shutdowns from "@/view/shutdowns/shutdowns.vue";

const route = [
    {
        path: '/shutdowns',
        name: 'shutdowns',
        props: {showMenu: true},
        component: Shutdowns
    }
];

const routes = [
    ...route,
]

export default routes;
<template>
    <div class="float_window">
        <GhLocation
            style="margin-top: -5px;"
            :dataLocation="{
                showButton: false,
                secondWindow: true,
                showLoader: 'OK',
                text: $t('validate'),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new', {
                id: 1,
                add: true,
                floatWindow: true
            }),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'validate',
                    title: $t('validate') + ' ' + (getterDatalValidateInspection !== undefined && getterDatalValidateInspection.company !== undefined && getterDatalValidateInspection.company.inspection !== undefined ? getterDatalValidateInspection.company.inspection : '')
                }"
            >
                <template v-slot:content>
                    <div v-if="haveData">
                        <form id="validate_all">
                            <div class="gh-content-global">
                                <div class="gh-row-content">
                                    <div class="T15">
                                        {{$t('label_sanction_validate_all_1')}}
                                    </div>
                                    <div class="T15_b">
                                        {{(getterDatalValidateInspection !== undefined && getterDatalValidateInspection.company !== undefined && getterDatalValidateInspection.company.name !== undefined ? getterDatalValidateInspection.company.name : '') + ','}}
                                    </div>
                                    <div class="T15">
                                        {{$t('label_sanction_validate_all_2')}}
                                    </div>
                                </div>
                                <div class="gh-row-content" style="display: grid!important;">
                                    <template v-for="(doc,docIndex) in getterDatalValidateInspection.documents">
                                        <a class="T15_action table-cell_link F5 C1" @click="downloadDoc(doc.file_path)" style="margin-bottom: 5px;" :key="'document_' + docIndex">{{doc.file_name}}</a>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text-readonly_label T15" style="margin-right: 10px;margin-top: 2px;">{{$t('question_documents_resolve_sanction')}}</label>
                                    <GhChecks
                                        :datalabel="{
                                            text: '',
                                            style: 'width: 100px',
                                            id: 'label_validate_yes'
                                        }"
                                        :datainput="{
                                            required: true,
                                            selected: false,
                                            label_required: $t('question_documents_resolve_sanction'),
                                            type: 'radio',
                                            id: 'validate_yes',
                                            value: 1,
                                            name: 'validate[resolve]'
                                        }"
                                        @click="showComment = false"
                                    />
                                    <label class="gh_text-readonly_label T15" style="margin-right: 50px;margin-top: 2px;margin-left: 5px;">{{$t('yes')}}</label>
                                    <GhChecks
                                        :datalabel="{
                                            text: '',
                                            style: 'width: 100px',
                                            id: 'label_validate_no'
                                        }"
                                        :datainput="{
                                            required: true,
                                            selected: false,
                                            label_required: $t('question_documents_resolve_sanction'),
                                            type: 'radio',
                                            id: 'validate_no',
                                            value: 0,
                                            name: 'validate[resolve]'
                                        }"
                                        @click="showComment = true"
                                    />
                                    <label class="gh_text-readonly_label T15" style="margin-right: 50px;margin-top: 2px;margin-left: 5px;">{{$t('no')}}</label>
                                </div>
                                <div class="gh-row-content" v-if="showComment">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('comment') + '*',
                                            class: 'T15_b',
                                            styles: 'margin-right: 10px;',
                                        }"
                                        :datainput="{
                                            id: 'validate_comment',
                                            name: 'validate[comment]',
                                            required: true,
                                            value: '',
                                        }"
                                    />
                                </div>
                            </div>
                        </form>

                        <GhTableCommon
                            :extratable="{
                                id: 'table_validate_all'
                            }"
                            :header="header"
                            :data="getterQualifiedInspectionsCompaniesIncidences[$root._route.params.id] === undefined || getterQualifiedInspectionsCompaniesIncidences[$root._route.params.id].list.length === 0 ? [] : getterQualifiedInspectionsCompaniesIncidences[$root._route.params.id].list"
                        >
                            <template v-slot:field_redirect="{itemProp, labelProp}">
                                <label class="T15_b" :style="'color:' + itemProp['style_criticality'] + ';'">{{itemProp[labelProp]}}</label>
                            </template>
                        </GhTableCommon>
                    </div>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 15px;">
                <GhButton
                    :datainput="{
                        id: 'button_accept',
                        text: $t('accept'),
                        class: 'T19 container-md_button_content',
                        style: 'display: flex;'
                    }"
                    @click="submit"
                />
                <GhButton
                    :datainput="{
                        id: 'button_cancel',
                        text: $t('cancel'),
                        class: 'T19 container-md_button_content',
                        style: 'display: flex;'
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import {mapGetters} from "vuex";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhButton from "fe-gh-button-lib";
    import GhTextarea from "fe-gh-textarea-lib";

    export default {
        name: 'validate_all',
        components: {
            GhTextarea,
            GhButton,
            GhChecks,
            GhTableCommon,
            GhNoDropSelector,
            GhLocation
        },
        computed: {
            ...mapGetters(['getterQualifiedInspectionsCompaniesIncidences','getterDatalValidateInspection'])
        },
        data(){
            return{
                haveData: false,
                showComment: false,
                header: [
                    {text: 'incidence_relative_to', field: 'incidence', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 70%;'},
                    {text: 'paralyzation', field: 'paralize', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 10%;'},
                    {text: 'criticity', field: 'criticity', urlRedirect: true, sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'state', field: 'state', sorting: true, typeSort: 'string', style: 'min-width: 160px; max-width: 160px; width: 20%;'},
                ],
            }
        },
        beforeMount(){
            this.$store.dispatch('getDatalValidateInspection', {id: this.$root._route.params.id}, {root: true})
            this.haveData = true;
        },
        methods:{
            downloadDoc(url){
                this.$store.dispatch('easyDownload', {url: url}, {root: true})
            },
            async submit(){
                if(this.Global.checkRequired('validate_all') === 'OK') {
                    const formData = new FormData(document.getElementById('validate_all'));

                    await this.$store.dispatch('setValidateInspection', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});

                    window.opener.UpdatedSelect({
                        element: true
                    });
                }
            }
        }
    }
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_add_form',
        title: _vm.$t('add_form'),
        type: 'add',
        style: 'width: 600px; min-width:600px',
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px;'
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{attrs:{"id":"form_form"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"T21 gh_text-readonly_label"},[_vm._v(_vm._s(_vm.$t('enter_a_form_name')))])]),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{key:_vm.refreshInput,attrs:{"datalabel":{
                            text: '',
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            label_required: _vm.$t('form'),
                            required: true,
                            id: 'form_name',
                            name: 'form[name]',
                            style: 'width: 100%',
                            value: '',
                            type: 'text'
                        }}})],1)])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'btn_accept_add_form',
                text: _vm.$t('accept')
            }},on:{"click":function($event){return _vm.setForm()}}}),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_close_add_form',
                text: _vm.$t('close')
            }},on:{"click":function($event){return _vm.Global.closePopUp('popup_add_form')}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhLocation',{staticStyle:{"margin-top":"-5px"},attrs:{"dataLocation":{
            showButton: false,
            secondWindow: true,
            showLoader: 'OK',
            text: _vm.$t('validate'),
            img: require('@/assets/gh_new_dessign/floating.svg')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('incidence_new', {
            id: 1,
            add: true,
            floatWindow: true
        }),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'validate',
                title: _vm.$t('validate') + ' ' + (_vm.getterDatalValidateInspection !== undefined && _vm.getterDatalValidateInspection.company !== undefined && _vm.getterDatalValidateInspection.company.inspection !== undefined ? _vm.getterDatalValidateInspection.company.inspection : '')
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('div',[_c('form',{attrs:{"id":"validate_all"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('div',{staticClass:"T15"},[_vm._v(" "+_vm._s(_vm.$t('label_sanction_validate_all_1'))+" ")]),_c('div',{staticClass:"T15_b"},[_vm._v(" "+_vm._s((_vm.getterDatalValidateInspection !== undefined && _vm.getterDatalValidateInspection.company !== undefined && _vm.getterDatalValidateInspection.company.name !== undefined ? _vm.getterDatalValidateInspection.company.name : '') + ',')+" ")]),_c('div',{staticClass:"T15"},[_vm._v(" "+_vm._s(_vm.$t('label_sanction_validate_all_2'))+" ")])]),_c('div',{staticClass:"gh-row-content",staticStyle:{"display":"grid!important"}},[_vm._l((_vm.getterDatalValidateInspection.documents),function(doc,docIndex){return [_c('a',{key:'document_' + docIndex,staticClass:"T15_action table-cell_link F5 C1",staticStyle:{"margin-bottom":"5px"},on:{"click":function($event){return _vm.downloadDoc(doc.file_path)}}},[_vm._v(_vm._s(doc.file_name))])]})],2),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text-readonly_label T15",staticStyle:{"margin-right":"10px","margin-top":"2px"}},[_vm._v(_vm._s(_vm.$t('question_documents_resolve_sanction')))]),_c('GhChecks',{attrs:{"datalabel":{
                                        text: '',
                                        style: 'width: 100px',
                                        id: 'label_validate_yes'
                                    },"datainput":{
                                        required: true,
                                        selected: false,
                                        label_required: _vm.$t('question_documents_resolve_sanction'),
                                        type: 'radio',
                                        id: 'validate_yes',
                                        value: 1,
                                        name: 'validate[resolve]'
                                    }},on:{"click":function($event){_vm.showComment = false}}}),_c('label',{staticClass:"gh_text-readonly_label T15",staticStyle:{"margin-right":"50px","margin-top":"2px","margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('yes')))]),_c('GhChecks',{attrs:{"datalabel":{
                                        text: '',
                                        style: 'width: 100px',
                                        id: 'label_validate_no'
                                    },"datainput":{
                                        required: true,
                                        selected: false,
                                        label_required: _vm.$t('question_documents_resolve_sanction'),
                                        type: 'radio',
                                        id: 'validate_no',
                                        value: 0,
                                        name: 'validate[resolve]'
                                    }},on:{"click":function($event){_vm.showComment = true}}}),_c('label',{staticClass:"gh_text-readonly_label T15",staticStyle:{"margin-right":"50px","margin-top":"2px","margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('no')))])],1),(_vm.showComment)?_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{attrs:{"datalabel":{
                                        text: _vm.$t('comment') + '*',
                                        class: 'T15_b',
                                        styles: 'margin-right: 10px;',
                                    },"datainput":{
                                        id: 'validate_comment',
                                        name: 'validate[comment]',
                                        required: true,
                                        value: '',
                                    }}})],1):_vm._e()])]),_c('GhTableCommon',{attrs:{"extratable":{
                            id: 'table_validate_all'
                        },"header":_vm.header,"data":_vm.getterQualifiedInspectionsCompaniesIncidences[_vm.$root._route.params.id] === undefined || _vm.getterQualifiedInspectionsCompaniesIncidences[_vm.$root._route.params.id].list.length === 0 ? [] : _vm.getterQualifiedInspectionsCompaniesIncidences[_vm.$root._route.params.id].list},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('label',{staticClass:"T15_b",style:('color:' + itemProp['style_criticality'] + ';')},[_vm._v(_vm._s(itemProp[labelProp]))])]}}],null,false,1211466744)})],1):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"15px"}},[_c('GhButton',{attrs:{"datainput":{
                    id: 'button_accept',
                    text: _vm.$t('accept'),
                    class: 'T19 container-md_button_content',
                    style: 'display: flex;'
                }},on:{"click":_vm.submit}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'button_cancel',
                    text: _vm.$t('cancel'),
                    class: 'T19 container-md_button_content',
                    style: 'display: flex;'
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
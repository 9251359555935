<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="Configuration.sections[label['name']].have_perm"
                :datablock="{
                    id: 'configuration_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(label['second_text']),
                    num_results: label['second_text'] === '' ? '' : Configuration.sections[label['name']].count,
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhDropSelector from 'fe-gh-dropselector-lib';

    import {mapGetters, mapState} from "vuex";

    import forms from "@/view/configuration/forms/forms.vue";
    import certificates from "@/view/configuration/criticalities/criticalities.vue";
    import actions from "@/view/configuration/actions/actions.vue";
    import procedures from "@/view/configuration/procedures/procedures.vue";

    export default {
        name: 'sections_configuration',
        components: {
            GhDropSelector
        },
        computed: {
            ...mapGetters(['getterConfigurationForms']),
            ...mapState(['Configuration', 'ConfigurationForms'])
        },
        data(){
            return{
                data: [
                    {id: 1, name: 'forms', component: forms, second_text: ''},
                    {id: 2, name: 'criticalities', component: certificates, second_text: 'results'},
                    {id: 3, name: 'actions', component: actions, second_text: 'results'},
                    {id: 4, name: 'procedures', component: procedures, second_text: 'results'}
                ],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getConfigurationSections', '', {root: true});
            this.haveData = true;
        }
    }
</script>

import SanctionedCompanies from "@/view/sanctioned_companies/sanctioned_companies.vue";

const route = [
    {
        path: '/sanctioned-companies',
        name: 'sanctioned_companies',
        props: {showMenu: true},
        component: SanctionedCompanies
    }
];

const routes = [
    ...route,
]

export default routes;
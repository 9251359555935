<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhNoDropSelector v-if="SanctionedCompanies.sections[label['name']].have_perm"
                :datablock="{
                    id: 'shutdowns_' + label.id,
                    title: $t(label['name']),
                    label: SanctionedCompanies.sections[label['name']].count + ' ' + $t(label['second_text'])
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhNoDropSelector>
        </div>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";

    import {mapState} from "vuex";

    import sanctioned_companies from "@/view/sanctioned_companies/sanctioned_companies/sanctioned_companies.vue";

    export default {
        name: 'sanctioned_companies',
        components: {
            GhNoDropSelector
        },
        computed: {
            ...mapState(['SanctionedCompanies'])
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'sanctioned_companies', component: sanctioned_companies, second_text: 'results'},
                ],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getSanctionedCompaniesSections', '', {root: true});

            this.haveData = true;
        }
    }
</script>
<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'historic_popup',
            title: $t('historic'),
            type: 'info',
            style: 'width: 1024px; min-width: 1024px'
        }"
        :bodyConfiguration="{id: 'historic_popup_body', class: '', style: 'overflow-y: auto;max-height: 800px;padding-left: 20px;', text: ''}"
        :footerConfiguration="{id: 'historic_popup_footer', class: '', style: ''}"
    >
        <template v-slot:body>
            <div class="gh-content-global">
                <template v-if="SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.company !== undefined">
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15">
                            {{$t('label_historic_1_1')}}
                            <span class="T15_b">
                                {{(SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.company !== undefined && SharedPopupHistoric.data.company.name !== undefined ? SharedPopupHistoric.data.company.name : '')}}
                            </span>
                            {{', ' + $t('label_historic_1_2')}}
                            <span class="T15_b">
                                {{(SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.company !== undefined && SharedPopupHistoric.data.company.inspections !== undefined ? SharedPopupHistoric.data.company.inspections : '000') + ' ' + $t('inspections').toLowerCase()}}
                            </span>
                            {{$t('with').toLowerCase()}}
                            <span class="T15_b">
                                {{(SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.company !== undefined && SharedPopupHistoric.data.company.incidences !== undefined ? SharedPopupHistoric.data.company.incidences : '000') + ' ' + $t('incidences').toLowerCase()}}
                            </span>
                            {{$t('label_historic_2')}}
                            <span class="T15_b">
                                {{(SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.company !== undefined && SharedPopupHistoric.data.company.mild !== undefined && SharedPopupHistoric.data.company.mild.value !== undefined ? SharedPopupHistoric.data.company.mild.value : '000') + ' (' + (SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.company !== undefined && SharedPopupHistoric.data.company.mild !== undefined && SharedPopupHistoric.data.company.mild.percentage !== undefined ? SharedPopupHistoric.data.company.mild.percentage : '000%') + ') ' + $t('mild_plural').toLowerCase() + ','}}
                            </span>
                            <span class="T15_b">
                                {{(SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.company !== undefined && SharedPopupHistoric.data.company.serious !== undefined && SharedPopupHistoric.data.company.serious.value !== undefined ? SharedPopupHistoric.data.company.serious.value : '000') + ' (' + (SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.company !== undefined && SharedPopupHistoric.data.company.serious !== undefined && SharedPopupHistoric.data.company.serious.percentage !== undefined ? SharedPopupHistoric.data.company.serious.percentage : '000%') + ') ' + $t('serious_plural').toLowerCase()}}
                            </span>
                            {{$t('and')}}
                            <span class="T15_b">
                                {{(SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.company !== undefined && SharedPopupHistoric.data.company.very_serious !== undefined && SharedPopupHistoric.data.company.very_serious.value !== undefined ? SharedPopupHistoric.data.company.very_serious.value : '000') + ' (' + (SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.company !== undefined && SharedPopupHistoric.data.company.very_serious !== undefined && SharedPopupHistoric.data.company.very_serious.percentage !== undefined ? SharedPopupHistoric.data.company.very_serious.percentage : '000%') + ') ' + $t('very_serious_plural').toLowerCase() + '.'}}
                            </span>
                        </label>
                    </div>
                </template>
                <template v-if="SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.outsourced !== undefined">
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15">
                            {{$t('label_historic_outsourced_1_1')}}
                            <span class="T15_b">
                                {{(SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.outsourced !== undefined && SharedPopupHistoric.data.outsourced.name !== undefined ? SharedPopupHistoric.data.outsourced.name : '')}}
                            </span>
                            {{$t('label_historic_1_2')}}
                            <span class="T15_b">
                                {{(SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.outsourced !== undefined && SharedPopupHistoric.data.outsourced.inspections !== undefined ? SharedPopupHistoric.data.outsourced.inspections : '000') + ' ' + $t('inspections').toLowerCase()}}
                            </span>
                            {{$t('with').toLowerCase()}}
                            <span class="T15_b">
                                {{(SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.outsourced !== undefined && SharedPopupHistoric.data.outsourced.incidences !== undefined ? SharedPopupHistoric.data.outsourced.incidences : '000') + ' ' + $t('incidences').toLowerCase()}}
                            </span>
                            {{$t('label_historic_2')}}
                            <span class="T15_b">
                                {{(SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.outsourced !== undefined && SharedPopupHistoric.data.outsourced.mild !== undefined && SharedPopupHistoric.data.outsourced.mild.value !== undefined ? SharedPopupHistoric.data.outsourced.mild.value : '000') + ' (' + (SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.outsourced !== undefined && SharedPopupHistoric.data.outsourced.mild !== undefined && SharedPopupHistoric.data.outsourced.mild.percentage !== undefined ? SharedPopupHistoric.data.outsourced.mild.percentage : '000%') + ') ' + $t('mild_plural').toLowerCase() + ','}}
                            </span>
                            <span class="T15_b">
                                {{(SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.outsourced !== undefined && SharedPopupHistoric.data.outsourced.serious !== undefined && SharedPopupHistoric.data.outsourced.serious.value !== undefined ? SharedPopupHistoric.data.outsourced.serious.value : '000') + ' (' + (SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.outsourced !== undefined && SharedPopupHistoric.data.outsourced.serious !== undefined && SharedPopupHistoric.data.outsourced.serious.percentage !== undefined ? SharedPopupHistoric.data.outsourced.serious.percentage : '000%') + ') ' + $t('serious_plural').toLowerCase()}}
                            </span>
                            {{$t('and')}}
                            <span class="T15_b">
                                {{(SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.outsourced !== undefined && SharedPopupHistoric.data.outsourced.very_serious !== undefined && SharedPopupHistoric.data.outsourced.very_serious.value !== undefined ? SharedPopupHistoric.data.outsourced.very_serious.value : '000') + ' (' + (SharedPopupHistoric.data !== undefined && SharedPopupHistoric.data.outsourced !== undefined && SharedPopupHistoric.data.outsourced.very_serious !== undefined && SharedPopupHistoric.data.outsourced.very_serious.percentage !== undefined ? SharedPopupHistoric.data.outsourced.very_serious.percentage : '000%') + ') ' + $t('very_serious_plural').toLowerCase() + '.'}}
                            </span>
                        </label>
                    </div>
                </template>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'historic_btn_popup',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: 'display: flex;'
                }"
                @click="Global.closePopUp('historic_popup')"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";
    export default {
        name: 'popup_historic',
        components:{
            GhPopUp,
            GhButton
        },
        computed:{
            ...mapState(['SharedPopupHistoric'])
        }
    }
</script>
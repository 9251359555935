<template>
    <div v-if="haveData" style="padding-right: 18px; padding-left: 13px; padding-bottom: 10px;">
        <GhTableCommon
            :extratable="{
                id: 'table_shutdown_' + prop.id
            }"
            :header="header"
            :data="getterShutdownsShutdownsCompanyIncidences[prop.id] !== undefined && getterShutdownsShutdownsCompanyIncidences[prop.id].list.length === 0 ? [] : getterShutdownsShutdownsCompanyIncidences[prop.id].list"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <label class="T15_b" :style="'color:' + itemProp['style_criticality'] + ';'">{{itemProp[labelProp]}}</label>
            </template>
            <template v-slot:actions="{itemProp}">
                <template v-if="getterShutdownsShutdownsCompanyIncidences[prop.id].actions.view">
                    <GhAction
                        :dataction="{
                            id: 'view_sanction_' + itemProp['id'],
                            text: $t('view'),
                            item_prop: itemProp,
                            icon: require('@/assets/gh_new_dessign/doc.svg')
                        }"
                        @click="setView(itemProp, 'view')"
                    />
                </template>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from 'fe-gh-table-common-lib';

    import {mapGetters} from "vuex";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'sections_sanction',
        props: ['prop'],
        components:{
            GhAction,
            GhTableCommon
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'incidence_relative_to', field: 'incidence', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 70%;'},
                    {text: 'paralyzation', field: 'paralize', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'criticity', field: 'criticity', urlRedirect: true, sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    // {text: 'ambit', field: 'ambit', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'state', field: 'state', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: '', field: 'actions', sorting: false, style: 'min-width: 50px;'},
                ],
            }
        },
        computed: {
            ...mapGetters(['getterShutdownsShutdownsCompanyIncidences'])
        },
        watch: {
            async '$parent.open'(data){
                if(data === true) {
                    await this.$store.dispatch('getShutdownsShutdownsCompanyIncidences', {
                        id: this.prop.id
                    }, {root: true});

                    if(this.getterShutdownsShutdownsCompanyIncidences[this.prop.id] === undefined || this.getterShutdownsShutdownsCompanyIncidences[this.prop.id].actions === undefined || this.getterShutdownsShutdownsCompanyIncidences[this.prop.id].actions.view === undefined || !this.getterShutdownsShutdownsCompanyIncidences[this.prop.id].actions.view){
                        delete this.header[5];

                        this.header = this.header.filter(function (item) {
                            return item !== undefined;
                        });
                    }

                    this.haveData = true;
                }else{
                    this.haveData = false;
                }
            }
        },
        methods: {
            async setView(item, type){
                this.Global.windowOpen(this.Global.openSecondWindow('sanction_action',{
                    type: type,
                    origin: 'shutdowns',
                    id_company: this.prop.id,
                    id: item.id
                }),'sanction');
            },
            async checkChildWindow() {
                // if (this.Global.state.window.closed) {
                //     this.Global.state.window_open = false;
                //     clearInterval(this.timer);
                // }
            }
        }
    }
</script>

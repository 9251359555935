var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'decline_popup',
        title: _vm.$t('decline'),
        type: 'info',
        style: 'width: 1024px; min-width: 1024px'
    },"bodyConfiguration":{id: 'decline_popup_body', class: '', style: 'overflow-y: auto;max-height: 800px;padding-left: 20px;', text: _vm.$t('preparing_reject_incidence') + ':'},"footerConfiguration":{id: 'decline_popup_footer', class: '', style: ''}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{attrs:{"id":"form_decline_sanction"}},[_c('div',{staticClass:"gh-content-global"},[(_vm.getterSanction.show.incidence_detail)?_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text-readonly_label T15",staticStyle:{"min-width":"150px","max-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('incidence_detail') + ':'))]),_c('label',{staticClass:"gh_text-readonly_label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.getterSanction.incidence_detail))])]):_vm._e(),_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{attrs:{"datalabel":{
                            text: _vm.$t('motive') + '*',
                            styles: 'width: 150px; min-width: 150px !important; text-align: right; margin-right: 12px;',
                            class: 'T15_b'
                        },"datainput":{
                            id: 'decline_motive',
                            name: 'decline[motive]',
                            value: '',
                            required: true
                        }}})],1)])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'decline_accept_btn_popup',
                text: _vm.$t('accept'),
                class: 'T19 container-md_button_content',
                style: 'display: flex;'
            }},on:{"click":_vm.setDecline}}),_c('GhButton',{attrs:{"datainput":{
                id: 'decline_close_btn_popup',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: 'display: flex;'
            }},on:{"click":function($event){return _vm.Global.closePopUp('decline_popup')}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
import Historic from "@/view/historic/historic.vue";

const route = [
    {
        path: '/historic',
        name: 'historic',
        props: {showMenu: true},
        component: Historic
    }
];

const routes = [
    ...route,
]

export default routes;
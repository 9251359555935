var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticStyle:{"margin-top":"10px"}},[_c('GhTableCommon',{attrs:{"extratable":{
            id: 'sanctioned_companies'
        },"header":_vm.header,"data":_vm.getterSanctionedCompaniesList.list},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(_vm.getterSanctionedCompaniesList.actions.bill)?[_c('GhAction',{attrs:{"dataction":{
                        id: 'detail_qualified_sanctions',
                        text: _vm.$t('bill'),
                        icon: require('../../../assets/gh_new_dessign/doc.svg')
                    }},on:{"click":function($event){return _vm.getBill(itemProp.id)}}})]:_vm._e(),(_vm.getterSanctionedCompaniesList.actions.payed)?[_c('GhAction',{attrs:{"dataction":{
                        id: 'download_qualified_sanctions',
                        text: _vm.$t('payed'),
                        icon: require('../../../assets/gh_new_dessign/tick.svg'),
                    }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','paySanctionedCompanies', itemProp.id,{
                        'date': itemProp.date,
                        'inspected_company': itemProp.inspected_company,
                        'main_contractor': itemProp.main_contractor,
                        'inspector': itemProp.inspector,
                        'inspector_company': itemProp.inspector_company,
                        'inspector2': itemProp.inspector2,
                        'ee_ss': itemProp.ee_ss
                    }, _vm.$t('sanction_payed'), _vm.$t('preparing_sanction_payed') + ':', 'delete')}}})]:_vm._e()]}}],null,false,2634479469)}),_c(_vm.components.bill,{tag:"component",attrs:{"dataBill":_vm.dataBill}})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
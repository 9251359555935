import Sanction from "@/view/shared/detail_sanctions.vue";

const routes = [
    {
        path: '/sanction/:id/detail',
        name: 'view_sanction',
        component: Sanction,
        props: {id: null, showMenu: false, background:true, otherTitle: 'sanction'}
    }
];

export default routes;
<template>
    <div v-if="haveData">
        <form id="form_search" :key="refresh">
            <div class="gh-content-global">
                <div class="gh-row-content">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('name'),
                            style: 'margin-left: 50px;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: false,
                            id: 'language_name',
                            name: 'language[name]',
                            style: 'width: 40%',
                            value: '',
                            type: 'text'
                        }"
                    />
                </div>
                <div class="gh-row-content">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('id'),
                            style: 'margin-left: 50px;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            id: 'language_id',
                            name: 'language[id]',
                            style: 'width: 40%',
                            value: '',
                            type: 'text'
                        }"
                    />
                </div>
                <div class="gh-row-content">
                    <GhDataSelector ref="GhDataSelector_1"
                                    :datablock="{
                            id: 'languages_search',
                            class: 'T15_b',
                            subtitle: $t('select_language_for_search') + ':',
                            text: $t('search_language_s'),
                            label: $t('search_language_s'),
                            type: 'checkbox',
                            columns: 2,
                            required: false,
                            style: 'margin-left: 50px;',
                            popupStyle: 'min-width: 30%;max-width: 30%;'
                        }"
                                    :datainput="{
                            id: 'value_languages_search',
                            name: 'language[search][]'
                        }"
                                    :feed="getFeeds.feed_languages"
                    />
                </div>
                <div class="gh-row-content">
                    <GhDataSelector ref="GhDataSelector_2"
                                    :datablock="{
                            id: 'languages_show',
                            class: 'T15_b',
                            subtitle: $t('select_language_display') + ':',
                            text: $t('language_s_display'),
                            label: $t('language_s_display'),
                            type: 'checkbox',
                            columns: 2,
                            style: 'margin-left: 50px;',
                            popupStyle: 'min-width: 30%;max-width: 30%;'
                        }"
                                    :datainput="{
                            id: 'value_languages_show',
                            name: 'language[show][]'
                        }"
                                    :feed="getFeeds.feed_languages"
                    />
                </div>
                <div class="gh-row-content">
                    <GhDataSelector
                        :datablock="{
                            id: 'language_systems_search',
                            class: 'T15_b',
                            subtitle: $t('select_application') + ':',
                            text: $t('applications'),
                            label: $t('applications'),
                            type: 'checkbox',
                            columns: 2,
                            style: 'margin-left: 50px;',
                            popupStyle: 'min-width: 35%;max-width: 35%;'
                        }"
                        :datainput="{
                            id: 'value_language_systems_search',
                            name: 'language[systems][]'
                        }"
                        :feed="getFeeds.feed_systems"
                        :selected_input="[]"
                    />
                </div>
                <div class="gh-row-content" style="padding-top: 15px; padding-bottom: 20px;">
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_and',
                            value: 'AND',
                            type: 'radio',
                            name: 'language[logic]',
                            style: 'margin-left:50px;',
                            selected: true
                        }"
                        v-bind:datalabel="{
                            style: 'width: calc((100% - 183px) - (((100% - 280px) * 8) / 15));',
                            class: 'T15 input_label',
                            id: 'label_and',
                            text: $t('all_search_criteria_entered'),
                        }"
                        @click="selectRadio('GhRadioAND')"
                        ref="GhRadioAND"
                    />
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_or',
                            value: 'OR',
                            type: 'radio',
                            name: 'language[logic]',
                        }"
                        v-bind:datalabel="{
                            style: 'margin-left: 4px;',
                            class: 'T15 input_label',
                            id: 'label_or',
                            text: $t('any_search_criteria_entered')
                        }"
                        ref="GhRadioOR"
                        @click="selectRadio('GhRadioOR')"
                    />
                </div>
            </div>
            <div class="centered" style="margin-bottom: 10px;">
                <GhButton
                    :datainput="{id: 'button_search',text: $t('search'),class: 'T19 container-md_button_content',style: 'display: flex;'}"
                    @click="search();"
                />
                <GhButton
                    :datainput="{id: 'button_clean',text: $t('clean'),class: 'T19 container-md_button_content',style: 'display: flex;'}"
                    @click="clean();"
                />
            </div>
        </form>
    </div>
</template>

<script>
import GhInputTextField from "fe-gh-inputtextfield-lib";
import GhDataSelector from "fe-gh-data-selector-lib";
import GhChecks from "fe-gh-checks-lib";
import GhButton from "fe-gh-button-lib";
import {mapState} from "vuex";

export default {
    name: "view_search",
    components: {
        GhInputTextField,
        GhDataSelector,
        GhChecks,
        GhButton,
    },
    computed: {
        ...mapState(['getFeeds']),
    },
    data(){
        return{
            haveData: false,
            refresh: 0,
        }
    },
    async beforeMount() {
        await this.$store.dispatch('getFeedLanguages','',{root:true});
        await this.$store.dispatch('getFeedSystems','',{root:true});
        this.haveData = true;
    },
    methods:{
        selectRadio(value){
            if (this.$refs[value].$el.children[0].children[1].checked != true){
                this.$refs[value].checkUncheck();
            }
        },
        search(){
            if(this.Global.checkRequired('form_search') == 'OK') {
                const formData = new FormData(document.getElementById('form_search'));
                this.$store.dispatch('searchData', {formData: [...formData]}, {root: true});
            }
        },
        clean(){
            this.$store.dispatch('destroyedResultData','',{root:true});
            document.getElementById('form_search').reset();
            this.refresh++;
        },
    },
}
</script>
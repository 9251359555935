import Procedure from "@/view/configuration/procedures/view/procedure.vue";

const routes = [
    {
        path: '/configuration/procedures',
        name: 'new_configuration_procedures',
        component: Procedure,
        props: {showMenu: false, background: true, otherTitle: 'procedures'},
        children: [
            {
                path: '/configuration/procedures/:id',
                name: 'modify_configuration_procedures',
                component: Procedure,
                props: {id: null, showMenu: false, background: true, otherTitle: 'procedures'},
            },
            {
                path: '/configuration/procedures/:id/:view',
                name: 'view_configuration_procedures',
                component: Procedure,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'procedures'},
            },
        ]
    }
];

export default routes;
import ViewSearch from '@/view/search/view/search.vue';
import ViewSearchUser from '@/view/search/view/user.vue';
import ViewSearchCompany from '@/view/search/view/company.vue';
import ViewSearchInstalation from '@/view/search/view/instalation.vue';

const routes = [
    {
        path: '/search_filters',
        name: 'filters',
        component: ViewSearch,
        props: {showMenu: true}
    },
    {
        path: '/user-search',
        name: 'user-search',
        component: ViewSearchUser,
        props: {id: null,showMenu: true, otherTitle: 'user_search'}
    },
    {
        path: '/company-search',
        name: 'company-search',
        component: ViewSearchCompany,
        props: {id: null,showMenu: true, otherTitle: 'company_search'}
    },
    {
        path: '/instalation-search',
        name: 'instalation-search',
        component: ViewSearchInstalation,
        props: {id: null,showMenu: true, otherTitle: 'company_search'}
    },
]
export default routes;
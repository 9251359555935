<template>
    <div class="float_window">
        <form id="form_search" :key="refresh">
            <div class="section_line" style="margin-top: 8px;">
                <div class="T15_b" style="padding-left:13px;">{{$t('contractor')}}</div>
            </div>
            <div class="gh-content-global">
                <div class="gh-row-content">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('company_cif'),
                            id: 'label_company_cif',
                            class: 'T15_b',
                            style: 'width: 150px; text-align:right;',
                        }"
                        :datainput="{
                            id: 'value_company_cif',
                            name: 'search[company_cif]',
                            type: 'text',
                            style: 'min-width: 100px; width: 196px'
                        }"
                    />
                    <GhInputTextField
                        :datalabel="{
                            text: $t('company_name'),
                            id: 'label_company_name',
                            class: 'T15_b',
                            style: 'width: 150px; text-align:right; margin-left: 25px;',
                        }"
                        :datainput="{
                            id: 'value_company_name',
                            name: 'search[company_name]',
                            type: 'text',
                            style: 'min-width: 100px; width: 196px'
                        }"
                    />
                </div>
                <div class="gh-row-content">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('worker_cif'),
                            id: 'label_worker_cif',
                            class: 'T15_b',
                            style: 'width: 150px; text-align:right;',
                        }"
                        :datainput="{
                            id: 'value_worker_cif',
                            name: 'search[worker_cif]',
                            type: 'text',
                            style: 'min-width: 100px; width: 196px'
                        }"
                    />
                    <GhInputTextField
                        :datalabel="{
                            text: $t('worker_name'),
                            id: 'label_worker_name',
                            class: 'T15_b',
                            style: 'width: 150px; text-align:right; margin-left: 25px;',
                        }"
                        :datainput="{
                            id: 'value_worker_name',
                            name: 'search[worker_name]',
                            type: 'text',
                            style: 'min-width: 100px; width: 196px'
                        }"
                    />
                </div>
            </div>
            <div class="section_line" style="margin-top: -7px;">
                <div class="T15_b" style="padding-left:13px;">{{$t('instalation')}}</div>
            </div>
            <div class="gh-content-global">
                <div class="gh-row-content">
                    <GhSimpleSearch
                        :datalabel="{
                            text: $t('code'),
                            style: 'width: 150px; text-align:right;',
                            class: 'T15_b',
                            id: 'label_search_instalation_code'
                        }"
                        :datainput="{
                            required: false,
                            class: '',
                            id: 'value_search_instalation_code',
                            nameId: 'search[instalation_code]',
                            style: 'min-width: 100px; width: 200px',
                            value: '',
                            placeholder: '',
                            href: 'getInstalationCodeSearch'
                        }"
                        ref="RefInstalationCode"
                        @focusout="focusoutSearchInput('RefInstalationCode', 'value_search_instalation_code')"
                    />
                    <GhSimpleSearch
                        :datalabel="{
                            text: $t('name'),
                            style: 'width: 150px; text-align:right; margin-left: 25px;',
                            class: 'T15_b',
                            id: 'label_search_instalation_name'
                        }"
                        :datainput="{
                            required: false,
                            class: '',
                            id: 'value_search_instalation_name',
                            nameId: 'search[instalation_name]',
                            style: 'min-width: 100px; width: 200px',
                            value: '',
                            placeholder: '',
                            href: 'getInstalationNameSearch'
                        }"
                        ref="RefInstalationName"
                        @focusout="focusoutSearchInput('RefInstalationName', 'value_search_instalation_name')"
                    />
                </div>
                <div class="gh-row-content">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('province'),
                            id: 'label_search_province',
                            class: 'T15_b',
                            style: 'width: 150px; text-align:right;',
                        }"
                        :datainput="{
                            id: 'value_search_province',
                            name: 'search[province]',
                            type: 'text',
                            style: 'min-width: 100px; width: 196px'
                        }"
                    />
                    <GhInputTextField
                        :datalabel="{
                            text: $t('delegation'),
                            id: 'label_search_delegation',
                            class: 'T15_b',
                            style: 'min-width: 150px; text-align:right; margin-left: 25px;',
                        }"
                        :datainput="{
                            id: 'value_search_delegation',
                            name: 'search[delegation]',
                            type: 'text',
                            style: 'min-width: 100px; width: 196px'
                        }"
                    />
                </div>
                <div class="gh-row-content">
                    <GhSimpleSearch
                        :datalabel="{
                            text: $t('inspector'),
                            style: 'width: 150px; text-align:right;',
                            class: 'T15_b',
                            id: 'label_search_inspector'
                        }"
                        :datainput="{
                            required: false,
                            class: '',
                            id: 'value_search_inspector',
                            nameId: 'search[inspector]',
                            style: 'min-width: 100px; width: 200px',
                            value: '',
                            placeholder: '',
                            href: 'getInspectorSearch'
                        }"
                        ref="RefInspector"
                        @focusout="focusoutSearchInput('RefInspector', 'value_search_inspector')"
                    />
                </div>
                <div class="gh-row-content">
                    <div>
                        <GhSimpleSearch
                            :datalabel="{
                                text: $t('gsma'),
                                style: 'width: 150px; text-align:right;',
                                class: 'T15_b',
                                id: 'label_search_gsma'
                            }"
                            :datainput="{
                                required: false,
                                class: '',
                                id: 'value_search_gsma',
                                nameId: 'search[gsma]',
                                style: 'min-width: 100px; width: 200px',
                                value: '',
                                placeholder: '',
                                href: 'getGSMASearch'
                            }"
                            ref="RefGSMA"
                            @focusout="focusoutSearchInput('RefGSMA', 'value_search_gsma')"
                        />
                        <GhSimpleSearch
                            :datalabel="{
                                text: $t('gat'),
                                style: 'width: 150px; text-align:right; margin-left: 25px;',
                                class: 'T15_b',
                                id: 'label_search_gat'
                            }"
                            :datainput="{
                                required: false,
                                class: '',
                                id: 'value_search_gat',
                                nameId: 'search[gat]',
                                style: 'min-width: 100px; width: 200px',
                                value: '',
                                placeholder: '',
                                href: 'getGATSearch'
                            }"
                            ref="RefGAT"
                            @focusout="focusoutSearchInput('RefGAT', 'value_search_gat')"
                        />
                    </div>
                </div>
            </div>
            <div class="section_line" style="margin-top: -7px; ">
                <div class="T15_b" style="padding-left:13px;">{{$t('infraction_type')}}</div>
            </div>
            <div class="gh-content-global">
                <div class="gh-row-content" style="margin-left: 160px;">
                    <div v-for="(item, key) in getFeeds.level_criticity" :key="'INFRACTION' + key">
                        <GhChecks
                            v-bind:datainput="{
                                id: 'radio_and',
                                value: key,
                                type: 'radio',
                                name: 'search[infraction_type]',
                                selected: false
                            }"
                            v-bind:datalabel="{
                                style: 'margin-right: 25px;',
                                class: 'T15 input_label',
                                id: 'label_and',
                                text: item
                            }"
                        />
                    </div>
                </div>
            </div>
            <div class="section_line" style="margin-top: -7px; ">
                <div class="T15_b" style="padding-left:13px;">{{$t('inspection')}}</div>
            </div>
            <div class="gh-content-global">
                <div class="gh-row-content">
                    <GhCalendar
                        :datalabel="{
                            id: 'label_search_date_from',
                            text: $t('from'),
                            style: ';text-align: right;'
                        }"
                        :datainput="{
                            id: 'input_search_date_from',
                            name: 'search[date_from]',
                            value: ''
                        }"
                    />
                    <GhCalendar
                        :datalabel="{
                            id: 'label_search_date_until',
                            text: $t('until'),
                            style: 'width:150px; text-align: right; margin-left: 95px;'
                        }"
                        :datainput="{
                            id: 'input_search_end_date',
                            name: 'search[date_until]',
                            value: ''
                        }"
                    />
                </div>
                <div class="gh-row-content">
                    <div>
                        <GhDataSelector
                            id="historic_type_inspection"
                            :datablock="{
                                id: 'value_search_type_inspection',
                                class: 'T15_b',
                                subtitle: $t('select_type_inspection') + ':',
                                text: $t('type_inspection'),
                                label: $t('type_inspection'),
                                type: 'checkbox',
                                columns: 2,
                                style: '',
                                popupStyle: 'min-width: 35%;max-width: 35%;',
                                inputAll: true
                            }"
                            :datainput="{
                                id: 'value_label_search_type_inspection',
                                name: 'search[type_inspection][]'
                            }"
                            :feed="getFeeds.feed_type_inspection"
                            :selected_input="[]"
                        />
                        <GhDataSelector
                            id="historic_type_work"
                            :datablock="{
                                id: 'value_search_type_work',
                                class: 'T15_b',
                                subtitle: $t('select_type_work') + ':',
                                text: $t('type_work'),
                                label: $t('type_work'),
                                type: 'checkbox',
                                columns: 2,
                                style: 'margin-left: 12px;',
                                popupStyle: 'min-width: 35%;max-width: 35%;',
                                inputAll: true
                            }"
                            :datainput="{
                                id: 'value_label_search_type_work',
                                name: 'search[type_work][]'
                            }"
                            :feed="getFeeds.feed_type_work"
                            :selected_input="[]"
                        />
                    </div>
                </div>
                <div class="gh-row-content">
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_and',
                            value: 'and',
                            type: 'radio',
                            name: 'search[criterio]',
                            selected: true
                        }"
                        v-bind:datalabel="{
                            style: '',
                            class: 'T15 input_label',
                            id: 'label_and',
                            text: $t('all_search_criteria_entered')
                        }"
                    />
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_or',
                            value: 'or',
                            type: 'radio',
                            style: 'margin-left:35px;',
                            name: 'search[criterio]',
                        }"
                        v-bind:datalabel="{
                            style: 'margin-left: 4px;',
                            class: 'T15 input_label',
                            id: 'label_or',
                            text: $t('any_search_criteria_entered')
                        }"
                    />
                </div>
            </div>
        </form>
        <div class="centered" style="padding-bottom: 10px;">
            <GhButton :datainput="{id: 'btn_save', class: 'T19 container-md_button_content', text: $t('search')}" @click="search()"/>
            <GhButton :datainput="{id: 'btn_return',class: 'T19 container-md_button_content', text: $t('clean')}" @click="clean()"/>
        </div>
    </div>
</template>

<script>
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhSimpleSearch from "fe-gh-simple-search-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhButton from "fe-gh-button-lib";
    import GhChecks from "fe-gh-checks-lib";

    import {mapState} from "vuex";

    export default {
        name: 'view_search_historic',
        components:{
            GhCalendar,
            GhSimpleSearch,
            GhInputTextField,
            GhDataSelector,
            GhButton,
            GhChecks
        },
        computed:{
            ...mapState(['getFeeds'])
        },
        data(){
            return{
                refresh: 0,
                selected: []
            }
        },
        methods:{
            focusoutSearchInput(ref, id){
                let that = this;
                setTimeout(function(){
                    if(that.$refs[ref].$el.children[1].children[1].value === ''){
                        that.$refs[ref].$el.children[1].children[0].value = '';
                        document.getElementById(id + '_value_hidden').value = '';
                    }else if(that.$refs[ref].dataSearch.value === ''){
                        that.$refs[ref].$el.children[1].children[0].value = '';
                        that.$refs[ref].dataSearch.valueId = '';
                        document.getElementById(id + '_value_hidden').value = '';
                    }else{
                        if(that.selected[ref] !== undefined && that.selected[ref].valueId === that.$refs[ref].dataSearch.valueId && that.selected[ref].value !== that.$refs[ref].dataSearch.value){
                            that.$refs[ref].$el.children[1].children[0].value = that.selected[ref].value;
                            that.$refs[ref].dataSearch.valueId = that.selected[ref].valueId;
                            that.$refs[ref].dataSearch.value = that.selected[ref].value;
                            document.getElementById(id + '_value_hidden').value = that.selected[ref].valueId;
                        }else {
                            that.selected[ref] = {
                                value: that.$refs[ref].dataSearch.value,
                                valueId: that.$refs[ref].dataSearch.valueId,
                            }
                        }
                    }
                },100);
            },
            clean(){
                this.$store.dispatch('destroyedResultDataHistoric','',{root:true});
                document.getElementById("form_search").reset();
                this.refresh++;
            },
            async search(){
                const formData = new FormData(document.getElementById('form_search'));
                await this.$store.dispatch('searchDataHistoric',{formData: [...formData]},{root:true});
            }
        }
    }
</script>

<style>
    #label_search_date_from, #value_search_type_inspection_selector_label, #value_search_type_work_selector_label {
        width: 150px;
        text-align: right;
    }

    #historic_type_inspection, #historic_type_work {
        min-width: 372px !important;
    }

    #value_search_type_inspection_data_select_SUMMARY {
        max-width: 150px !important;
        width: 150px !important;
    }

    #historic_type_inspection > div {
        margin-bottom: unset !important;
    }

    #historic_type_work > div {
        margin-bottom: unset !important;
    }

    #value_search_instalation_name_hidden, #value_search_instalation_code_hidden, #value_search_gsma_hidden , #value_search_gat_hidden {
        position: absolute;
    }
</style>
<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_bill',
            title: $t('bill'),
            type: 'warning',
            style: 'width: 700px; min-width: 700px'
        }"
        :bodyConfiguration="{id: 'popup_bill_body', class: '', style: 'overflow-y: auto;max-height: 800px;padding-left: 20px;', text: $t('select_type_bill')}"
        :footerConfiguration="{id: 'popup_bill_footer', class: '', style: ''}"
    >
        <template v-slot:body>
            <form id="form_bill" :key="refreshContent">
                <div class="gh-row-content" style="margin-top: 20px;" :key="refreshInput">
                    <label class="gh_text_field-label T15" style="margin-left: 20px; margin-right: 10px;">{{$t('manual')}}</label>
                    <GhInputTextField
                        :datalabel="{
                            text: '',
                            style: '',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: true,
                            id: 'sanction_new_cuantity',
                            name: 'sanction[new_cuantity]',
                            style: 'min-width: 50px; width: 50px;',
                            value: '',
                            type: 'int',
                            label_required: $t('type_bill') + '*'
                        }"
                        @keyup="refreshCheck++"
                        ref="RefInput"
                    />
                    <label class="gh_text_field-label T15" style="margin-left: 10px;">{{$t('days')}}</label>
                </div>
                <div class="grid-container-2" style="margin-left: 10px; margin-right: 50px;">
                    <div class="grid-item" v-for="(item, index) in getFeeds.bill_type" :key="index" style="display: flex;">
                        <GhChecks
                            :key="index + refreshCheck"
                            :datalabel="{
                                text: '',
                                style: '',
                                class: '',
                                id: ''
                            }"
                            :datainput="{
                                disabled: false,
                                required: true,
                                selected: false,
                                type: 'radio',
                                id: 'worker_' + index,
                                value: index,
                                name: 'bill[automatic]',
                                label_required: $t('type_bill') + '*'
                            }"
                            @click="refreshInput++"
                            ref="RefChecks"
                            style="margin-left: 10px;padding-top: 5px;padding-bottom: 5px;"
                        />
                        <label class="gh_text_field-label T15" style="margin-left: 10px; margin-top: 7px;">{{item}}</label>
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'bill_accept_popup',
                    text: $t('accept'),
                    class: 'T19 container-md_button_content',
                    style: 'display: flex;'
                }"
                @click="setBill()"
            />
            <GhButton
                :datainput="{
                    id: 'bill_close_popup',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: 'display: flex;'
                }"
                @click="Global.closePopUp('popup_bill'); refreshContent++"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";

    import {mapState} from "vuex";
    import GhChecks from "fe-gh-checks-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'popup_bill',
        props: ['dataBill'],
        components: {
            GhButton,
            GhInputTextField,
            GhChecks,
            GhPopUp
        },
        computed: {
            ...mapState(['getFeeds'])
        },
        data(){
            return {
                refreshContent: 0,
                refreshInput: 0,
                refreshCheck: 0
            }
        },
        methods: {
            setBill(){
                let haveData = false;
                this.$refs.RefChecks.forEach((i) => {
                    if(i.selected){
                        haveData = true;
                    }
                });

                if(this.$refs.RefInput.datainput.value !== ''){
                    haveData = true;
                }

                if(!haveData){
                    this.Global.state.alert_required.requireds = {
                        0: this.$t('type_bill') + '*'
                    };
                    this.Global.openPopUp('warning_confirm');
                }else{
                    const formData = new FormData(document.getElementById('form_bill'));
                    this.$store.dispatch('setBill', {
                        formData: [...formData],
                        id: this.dataBill.id
                    }, {root: true});

                    this.Global.closePopUp('popup_bill');
                    this.refreshContent++
                }
            }
        }
    }
</script>
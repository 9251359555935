import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import Global from "../../Global";
Vue.use(vuex);

export default{
    state: {
        records_results: '',
        title_results: '',
        headers_results: '',
        popup_results: '',
        searchDataUsers: [],
        dataUsers: [],
        refreshUsers: false,
        languageDetails: [],
        refreshDetails: false,
        lastSearch: [],
        refreshSpinner: false,
        spinner: false
    },
    mutations: {
        loadResults(state, payload) {
            state.records_results = payload;
        },
        loadTitleResult(state, payload) {
            state.title_results = payload;
        },
        loadHeaderResult(state, payload) {
            state.headers_results = payload;
        },
        loadPopupResult(state, payload) {
            state.popup_results = payload;
        },
        loadSearchGroupUsers(state,payload){
            state.searchDataUsers = payload;
        },
        loadTemporalGroupUsers(state, payload){
            state.refreshUsers = !state.refreshUsers;
            state.dataUsers = payload;
        },
        loadLanguageDetails(state, payload){
            state.refreshDetails = !state.refreshDetails;
            state.languageDetails = payload;
        },
        loadLastSearch(state, payload){
            state.lastSearch = payload;
        },
        loadSpinnerSearch(state, payload){
            state.refreshSpinner = !state.refreshSpinner;
            state.spinner = payload;
        }
    },
    getters:{
        getterGroupUsers(state){
            const dummy = state.refreshUsers; // eslint-disable-line no-unused-vars
            return state.dataUsers;
        },
        getterLanguageDetails(state){
            const dummy = state.refreshDetails; // eslint-disable-line no-unused-vars
            return state.languageDetails;
        },
        getterGlobalSpinner(state){
            const dummy = state.refreshSpinner; // eslint-disable-line no-unused-vars
            return state.spinner;
        }
    },
    actions: {
        cleanGlobalSpinner(state){
            state.commit('loadSpinnerSearch', false);
        },
        destroyedResultData(state){
            state.commit("loadResults", '');
            state.commit('loadTitleResult', 'no_results_found');
        },
        async searchData(state,items){
            let requestLogin = API_URL +'traduction?';

            state.commit('loadLastSearch', items.formData);

            items.formData.forEach((item) => {
                requestLogin += item[0]+'='+item[1]+'&';
            });
            requestLogin = requestLogin.slice(0, -1);
            state.commit('loadSpinnerSearch', true);
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadResults", response.data.data);
                        state.commit("loadTitleResult", response.data.data.length);
                        state.commit('loadSpinnerSearch', false);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchGroupUsers(state, item){
            var url = localStorage.getItem('API_URL');
            let requestSearchCompanies = url + "users";
            let payload = {};

            item.formData.forEach((item) => {
                payload[item[0]] = item[1] ;
            });

            if(JSON.parse(localStorage.getItem('Params_users')).dataUsers.length !== 0){
                JSON.parse(localStorage.getItem('Params_users')).dataUsers.forEach((i, k) => {
                    payload['user[checked][' + k + ']'] = i.id;
                });
            }

            await axios({
                method: "get",
                url: requestSearchCompanies,
                params: payload,
            }).then(
                response => {
                    state.commit("loadSearchGroupUsers", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected) == true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        },
        setTemporalGroupUsers(state, item){
            state.commit('loadTemporalGroupUsers', item.data);
        },
        cleanTemporalGroupUsers(state){
            state.commit('loadTemporalGroupUsers', []);
        },
        async getLanguageDetails(state, item){
            let request = API_URL + 'traduction/details';

            var payload = {
                'keyword': item.keyword_id,
                'system': item.system_id
            }

            await axios({
                method: "get",
                url: request,
                params: payload,
            }).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadLanguageDetails', response.data.data);
                    }
                }
            )
        },
        async setLanguageDetails(state, item){
            let request = API_URL + "traduction/modify/" + item.keyword_id;
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });


            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('searchData', {formData: state.state.lastSearch});
                        Global.closePopUp('row_key');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeLanguageDetails(state, item){
            let requestLogin = API_URL + "traduction/delete/relations";
            let formData = new FormData();

            formData.append('keyword', item.id.keyword);
            formData.append('system', item.id.system);

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('searchData', {formData: state.state.lastSearch});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
import Criticality from "@/view/configuration/criticalities/view/criticality.vue";

const routes = [
    {
        path: '/configuration/criticalities',
        name: 'new_configuration_criticalities',
        component: Criticality,
        props: {showMenu: false, background: true, otherTitle: 'criticality'},
        children: [
            {
                path: '/configuration/criticalities/:id',
                name: 'modify_configuration_criticalities',
                component: Criticality,
                props: {id: null, showMenu: false, background: true, otherTitle: 'criticality'},
            },
            {
                path: '/configuration/criticalities/:id/:view',
                name: 'view_configuration_criticalities',
                component: Criticality,
                props: {id: null, view: true, showMenu: false, background: true, otherTitle: 'criticality'},
            },
        ]
    }
];

export default routes;
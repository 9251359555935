<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{
                id: 'table_qualified_inspections_' + prop.id
            }"
            :header="header"
            :data="getterQualifiedInspectionsCompaniesIncidences[prop.id] === undefined || getterQualifiedInspectionsCompaniesIncidences[prop.id].list.length === 0 ? [] : getterQualifiedInspectionsCompaniesIncidences[prop.id].list"
            :ref="'refIncidencesQualified' + prop.id"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <label class="T15_b" :style="'color:' + itemProp['style_criticality'] + ';'">{{itemProp[labelProp]}}</label>
            </template>
            <template v-slot:actions="{itemProp}">
                <template v-if="getterQualifiedInspectionsCompaniesIncidences[prop.id].actions.view">
                    <GhAction
                        :dataction="{
                            id: 'view_sanction_' + itemProp['id'],
                            text: $t('view'),
                            item_prop: itemProp,
                            icon: require('@/assets/gh_new_dessign/doc.svg')
                        }"
                        @click="setView(itemProp, 'view')"
                    />
                </template>
                <template v-if="(itemProp.resolve || itemProp.validate) && (getterQualifiedInspectionsCompaniesIncidences[prop.id].actions.validate || getterQualifiedInspectionsCompaniesIncidences[prop.id].actions.resolve)">
                    <GhAction
                        :dataction="{
                            id: 'action_qualified_inspections_' + itemProp['id'],
                            text: $t(itemProp.resolve ? 'resolve' : 'validate'),
                            item_prop: itemProp,
                            icon: require('@/assets/gh_new_dessign/tick.svg')
                        }"
                        @click="setAction(itemProp, itemProp.resolve ? 'resolve' : 'validate')"
                    />
                </template>
            </template>
        </GhTableCommon>
    </div>
    <div v-else class="centered" style="margin-top: 10px; margin-bottom: 10px;">
        <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
    </div>
</template>

<script>
    import GhTableCommon from 'fe-gh-table-common-lib';
    import GhAction from 'fe-gh-action-lib';

    import {mapGetters} from "vuex";

    export default {
        name: 'sections_sanction',
        props: ['prop'],
        components:{
            GhTableCommon,
            GhAction
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'incidence_relative_to', field: 'incidence', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 70%;'},
                    {text: 'paralyzation', field: 'paralize', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'criticity', field: 'criticity', urlRedirect: true, sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    // {text: 'ambit', field: 'ambit', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'state', field: 'state', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: '', field: 'actions', sorting: false, style: 'min-width: 150px;'},
                ],
                dispatcher: false,
                timer: []
            }
        },
        computed: {
            ...mapGetters(['getterQualifiedInspectionsCompaniesIncidences'])
        },
        watch: {
            async '$parent.open'(data){
                if(data === true) {
                    await this.$store.dispatch('getQualifiedInspectionsCompaniesIncidences', {
                        id: this.prop.id
                    }, {root: true});

                    if(this.getterQualifiedInspectionsCompaniesIncidences[this.prop.id] === undefined || this.getterQualifiedInspectionsCompaniesIncidences[this.prop.id].actions === undefined || this.getterQualifiedInspectionsCompaniesIncidences[this.prop.id].actions.resolve === undefined || !this.getterQualifiedInspectionsCompaniesIncidences[this.prop.id].actions.resolve || this.getterQualifiedInspectionsCompaniesIncidences[this.prop.id].actions.validate === undefined || !this.getterQualifiedInspectionsCompaniesIncidences[this.prop.id].actions.validate){
                        delete this.header[5];

                        this.header = this.header.filter(function (item) {
                            return item !== undefined;
                        });
                    }

                    this.haveData = true;
                }else{
                    this.haveData = false;
                }
            }
        },
        mounted(){
            let that = this;
            setTimeout(function(){
                document.querySelectorAll('[id^="list_header_table_qualified_inspections_' + that.prop.id + '"]').forEach((element) => {
                    element.addEventListener('click', that.clickHeader);
                });

                if(that.$parent.$parent.order['key' + that.prop.id] !== undefined && that.$parent.$parent.order['key' + that.prop.id].type === 'sorting-desc'){
                    that.$refs['refIncidencesQualified' + that.prop.id].ghSort('string', that.$parent.$parent.order['key' + that.prop.id].ho, that.$parent.$parent.order['key' + that.prop.id].cellIndex);
                }

                if(that.$parent.$parent.order['key' + that.prop.id] !== undefined && that.$parent.$parent.order['key' + that.prop.id].type === 'sorting-asc'){
                    that.$refs['refIncidencesQualified' + that.prop.id].ghSort('string', that.$parent.$parent.order['key' + that.prop.id].ho, that.$parent.$parent.order['key' + that.prop.id].cellIndex);
                    that.$refs['refIncidencesQualified' + that.prop.id].ghSort('string', that.$parent.$parent.order['key' + that.prop.id].ho, that.$parent.$parent.order['key' + that.prop.id].cellIndex);
                }
            },100);
        },
        methods:{
            async setView(item, type){
                this.Global.windowOpen(this.Global.openSecondWindow('sanction_action',{
                    type: type,
                    origin: 'qualified',
                    id_company: this.prop.id,
                    id: item.id
                }),'sanction');

                this.dispatcher = false;
            },
            async setAction(item, type){
                this.Global.windowOpen(this.Global.openSecondWindow('sanction_action',{
                    type: type,
                    id_company: this.prop.id,
                    id: item.id,
                    origin: type
                }),'sanction', false);

                this.dispatcher = true;
                this.timer = setInterval(await this.checkChildWindow, 500);
            },
            async checkChildWindow() {
                if (this.Global.state.window.closed) {
                    if(this.dispatcher) {
                        await this.$store.dispatch('getQualifiedInspectionsSections', '', {root: true});
                        await this.$store.dispatch('getQualifiedInspectionsCompanies', '', {root: true});

                        this.$parent.$parent.refreshDrop++;
                    }

                    this.Global.state.window_open = false;

                    clearInterval(this.timer);
                }
            },
            clickHeader(event){
                if(this.$parent.$parent.order['key' + this.prop.id] === undefined){
                    this.$parent.$parent.order['key' + this.prop.id] = [];
                    this.$parent.$parent.order['key' + this.prop.id].ho = '';
                    this.$parent.$parent.order['key' + this.prop.id].type = '';
                    this.$parent.$parent.order['key' + this.prop.id].cellIndex = '';
                }

                this.$parent.$parent.order['key' + this.prop.id].ho = event.target.id.split(this.prop.id + '_')[1];
                this.$parent.$parent.order['key' + this.prop.id].cellIndex = event.target.cellIndex;

                event.target.classList.forEach((i) => {
                    if(i === 'sorting-desc' || i === 'sorting-asc'){
                        this.$parent.$parent.order['key' + this.prop.id].type = i;
                    }
                });
            }
        }
    }
</script>

<template>
    <div v-if="haveData" style="margin-top: 10px;">
        <GhTableCommon
            :extratable="{
                id: 'sanctioned_companies'
            }"
            :header="header"
            :data="getterSanctionedCompaniesList.list"
        >
            <template v-slot:actions="{itemProp}">
                <template v-if="getterSanctionedCompaniesList.actions.bill">
                    <GhAction
                        :dataction="{
                            id: 'detail_qualified_sanctions',
                            text: $t('bill'),
                            icon: require('../../../assets/gh_new_dessign/doc.svg')
                        }"
                        @click="getBill(itemProp.id)"
                    />
                </template>
                <template v-if="getterSanctionedCompaniesList.actions.payed">
                    <GhAction
                        :dataction="{
                            id: 'download_qualified_sanctions',
                            text: $t('payed'),
                            icon: require('../../../assets/gh_new_dessign/tick.svg'),
                        }"
                        @click="Global.deleteConfirm('delete_confirm','paySanctionedCompanies', itemProp.id,{
                            'date': itemProp.date,
                            'inspected_company': itemProp.inspected_company,
                            'main_contractor': itemProp.main_contractor,
                            'inspector': itemProp.inspector,
                            'inspector_company': itemProp.inspector_company,
                            'inspector2': itemProp.inspector2,
                            'ee_ss': itemProp.ee_ss
                        }, $t('sanction_payed'), $t('preparing_sanction_payed') + ':', 'delete')"
                    />
                </template>
            </template>
        </GhTableCommon>

        <component :is="components.bill" :dataBill="dataBill"/>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapGetters} from "vuex";

    import popup_bill from "@/view/sanctioned_companies/sanctioned_companies/popups/bill.vue";

    export default {
        name: 'list_sanctioned_companies',
        components: {
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapGetters(['getterSanctionedCompaniesList'])
        },
        data(){
            return {
                header: [
                    {text: 'date', field: 'date', sorting: true, typeSort: 'date', style: 'min-width: 90px; max-width: 90px; width: 90px;'},
                    {text: 'inspected_company', field: 'inspected_company', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 20%;'},
                    {text: 'main_contractor', field: 'main_contractor', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 20%;'},
                    {text: 'inspector', field: 'inspector', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 80px; width: 20%;'},
                    {text: 'inspector_company', field: 'inspector_company', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 20%;'},
                    {text: 'inspector2', field: 'inspector2', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 20%;'},
                    {text: 'ee_ss', field: 'ee_ss', sorting: true, typeSort: 'int', style: 'min-width: 50px; max-width: 50px; width: 100px;'},
                    {text: 'L', field: 'mild', sorting: true, typeSort: 'int', style: 'min-width: 30px; max-width: 30px; width: 50px;'},
                    {text: 'G', field: 'serious', sorting: true, typeSort: 'int', style: 'min-width: 30px; max-width: 30px; width: 50px;'},
                    {text: 'MG', field: 'very_serious', sorting: true, typeSort: 'int', style: 'min-width: 30px; max-width: 30px; width: 50px;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                components: {
                    bill: popup_bill
                },
                dataBill: [],
                haveData: false
            }
        },
        watch: {
            async '$parent.open'(data){
                if(data === true) {
                    await this.$store.dispatch('getFeedBillType', '', {root: true});
                    await this.$store.dispatch('getSanctionedCompaniesList', '', {root: true});

                    if(!this.getterSanctionedCompaniesList.actions.bill && !this.getterSanctionedCompaniesList.actions.payed){
                        delete this.header[10];

                        this.header = this.header.filter(function (item) {
                            return item !== undefined;
                        });
                    }

                    this.haveData = true;
                }else{
                    this.haveData = false;
                }
            }
        },
        methods: {
            async getBill(id){
                this.dataBill = {
                    'id': id
                };

                this.Global.openPopUp('popup_bill');
            }
        }
    }
</script>
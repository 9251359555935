import Configuration from "@/view/configuration/configuration.vue";

import Criticality from "./criticalities/route";
import Actions from "./actions/route";
import Procedures from "./procedures/route";

const route = [
    {
        path: '/configuration',
        name: 'configuration',
        props: {showMenu: true},
        component: Configuration
    }
];

const routes = [
    ...route,
    ...Criticality,
    ...Actions,
    ...Procedures
]

export default routes;
<template>
    <div v-if="Historic.loader">
        <div class="centered" style="margin-top: 10px; margin-bottom: 10px;">
            <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
    <div v-else>
        <div :style=" this.$store.state.Historic.searchData && this.$store.state.Historic.searchData != [] ? 'margin-top: 12px;' : ''">
            <GhTableCommon
                v-if="this.$store.state.Historic.searchData && this.$store.state.Historic.searchData != []"
                :extratable="{
                    id:'result_search_data'
                }"
                :data="this.$store.state.Historic.searchData && this.$store.state.Historic.searchData != [] && this.$store.state.Historic.searchData.length !== undefined ? this.$store.state.Historic.searchData : []"
                :header="header">
                <template v-slot:field_redirect="{itemProp, labelProp}">
                    <template v-if="labelProp === 'infractions'">
                        <div class="ellipsis" :title="(itemProp[labelProp].mild !== '' && itemProp[labelProp].mild !== 0 ? itemProp[labelProp].mild + ' ' + $t('mild_plural') + '; ' : '') + (itemProp[labelProp].serious !== '' && itemProp[labelProp].serious !== 0 ? itemProp[labelProp].serious + ' ' + $t('serious_plural') + '; ' : '') + (itemProp[labelProp].very_serious !== '' && itemProp[labelProp].very_serious !== 0 ? itemProp[labelProp].very_serious + ' ' + $t('very_serious_plural') + '; ' : '')">
                            <template v-if="itemProp[labelProp].mild !== '' && itemProp[labelProp].mild !== 0">
                                <span class="T15_b" style="color: green">{{itemProp[labelProp].mild + ' ' + $t('mild_plural') + '; '}}</span>
                            </template>
                            <template v-if="itemProp[labelProp].serious !== '' && itemProp[labelProp].serious !== 0">
                                <span class="T15_b" style="color: orange">{{itemProp[labelProp].serious + ' ' + $t('serious_plural') + '; '}}</span>
                            </template>
                            <template v-if="itemProp[labelProp].very_serious !== '' && itemProp[labelProp].very_serious !== 0">
                                <span class="T15_b" style="color: red">{{itemProp[labelProp].very_serious + ' ' + $t('very_serious_plural') + '; '}}</span>
                            </template>
                        </div>
                    </template>
                    <template v-if="labelProp === 'inspections_action' || labelProp === 'infractions_action'">
                        <GhAction
                            :dataction="{
                                id: 'download_' + labelProp + '_' + itemProp['id'],
                                text: $t('download'),
                                icon: require('@/assets/gh_new_dessign/descargar.svg')
                            }"
                            @click="downloadSanction(itemProp, labelProp === 'inspections_action' ? 'inspections' : 'infractions')"
                        />
                    </template>
                </template>
            </GhTableCommon>
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";

    export default {
        name: "result_historic",
        components:{
            GhTableCommon,
            GhAction
        },
        computed:{
            ...mapState(['Historic'])
        },
        data(){
            return{
                header: [
                    {text: 'num_inspection', field: 'num_inspection', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15.5%;'},
                    {text: 'instalation', field: 'instalation', sorting: true, typeSort: 'string', style: 'min-width: 120px; max-width: 120px; width: 15.5%;'},
                    {text: 'inspector', field: 'inspector', sorting: true, typeSort: 'string', style: 'min-width: 120px; max-width: 120px; width: 15.5%;'},
                    {text: 'company_inspected', field: 'company_inspected', sorting: true, typeSort: 'string', style: 'min-width: 120px; max-width: 120px; width: 15.5%;'},
                    {text: 'main_company', field: 'main_company', sorting: true, typeSort: 'string', style: 'min-width: 120px; max-width: 120px; width: 15.5%;'},
                    {text: 'list_of_infractions', field: 'infractions', sorting: false, urlRedirect: true, style: 'min-width: 120px; max-width: 120px; width: 22.5%;'},
                    {text: 'inspections', field: 'inspections_action', sorting: false, urlRedirect: true, style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'infractions', field: 'infractions_action', sorting: false, urlRedirect: true, style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                ],
            }
        },
        methods:{
            async downloadSanction(item, type){
                await this.$store.dispatch('downloadSanction',{
                    id: item.id,
                    type: type
                },{root:true});
            }
        }
    }
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'associated_popup',
        title: _vm.$t('exempt_sanctioning_regime'),
        type: 'add',
        style: 'width: 35%; min-width: 430px'
    },"bodyConfiguration":{id: 'associated_popup_body', class: '', style: 'overflow-y: auto;max-height: 800px;padding-left: 20px;', text: ''},"footerConfiguration":{id: 'associated_popup_footer', class: '', style: ''}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{key:_vm.refreshContent,staticStyle:{"margin-top":"15px","margin-left":"20px","margin-right":"20px"}},[_c('label',{staticClass:"T15 gh_text-readonly_label"},[_vm._v(_vm._s(_vm.$t('company') + ': '))]),_c('label',{staticClass:"T15_b gh_text-readonly_label"},[_vm._v(_vm._s(_vm.companies))])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'accept_associated_popup',
                text: _vm.$t('accept'),
                class: 'T19 container-md_button_content',
                style: 'display: flex;'
            }},on:{"click":function($event){return _vm.acceptAssociated()}}}),_c('GhButton',{attrs:{"datainput":{
                id: 'close_associated_popup',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: 'display: flex;'
            }},on:{"click":function($event){return _vm.Global.closePopUp('associated_popup')}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
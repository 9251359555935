import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        sections: [],
        refreshSections: false,
    },
    mutations: {
        loadQualifiedInspectionsSections(state, payload) {
            state.refreshSections = !state.refreshSections;
            state.sections = payload;
        }
    },
    getters: {
        getterQualifiedInspectionsSections(state){
            const dummy = state.refreshSections; // eslint-disable-line no-unused-vars
            return state.sections;
        }
    },
    actions: {
        async getQualifiedInspectionsSections(state){
            await axios.get(
                API_URL + "app-modules/qualified-inspections"
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadQualifiedInspectionsSections', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setExportExcelQualifiedInspections(state, item){
            let request = API_URL + "qualified-inspections/" + item.type + "/excel";

            await axios({
                method: "get",
                url: request,
                headers: { "Content-Type" : "multipart/form-data"},
                responseType: 'blob',
            }).then(
                response => {
                    const url = window.URL.createObjectURL(new Blob([response.data],{
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', response.headers['content-disposition']);
                    document.body.appendChild(link);
                    link.click();
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
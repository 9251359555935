var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticStyle:{"padding-bottom":"12px"}},[_c('GhNoDropSelector',{attrs:{"datablock":{ id: 'historic', title: _vm.$t('historic'), second_title: ''}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.SearchHistoric,{key:'search_' + _vm.refreshView,tag:"component"})]},proxy:true}],null,false,2200115320)}),_c('GhNoDropSelector',{attrs:{"datablock":{
        id: 'result',
        title: typeof _vm.Historic.resultTitle == 'number' ? _vm.Historic.resultTitle + ' ' + _vm.$t('results') : _vm.$t((_vm.Historic.resultTitle !== undefined ? _vm.Historic.resultTitle : 'no_results_found')),
        second_title:''
    }},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(typeof _vm.Historic.resultTitle == 'number')?[_c('GhAction',{attrs:{"dataction":{
                        id: 'export_excel_result',
                        text: _vm.$t('export_excel'),
                        icon: require('@/assets/gh_new_dessign/descargar.svg')
                    }},on:{"click":_vm.exportExcel}})]:_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c(_vm.ResultHistoric,{tag:"component"})]},proxy:true}],null,false,2517988169)})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticStyle:{"margin-top":"10px"}},[_c('GhTableCommon',{attrs:{"extratable":{
            id: 'not_associated'
        },"header":_vm.header,"data":_vm.getterNotAssociatedList.list},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(_vm.getterNotAssociatedList.actions.associate)?[_c('GhAction',{attrs:{"dataction":{
                        id: 'associate_not_associated',
                        text: _vm.$t('associate'),
                        icon: require('../../../assets/gh_new_dessign/tick.svg')
                    }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','setAssociated', itemProp.id,{'name': itemProp.name, 'cif': itemProp.cif, 'from': itemProp.from}, _vm.$t('associate_company'), _vm.$t('the_company') + ' ' + itemProp.name + ' ' + _vm.$t('will_be_associated_with_the_sanctioning_regime'), 'delete')}}})]:_vm._e()]}},{key:"footer",fn:function(){return [(_vm.getterNotAssociatedList.actions.add)?_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                        id: 'add_not_associated',
                        text: _vm.$t('add')
                    }},on:{"click":function($event){return _vm.addAssociate()}}})],1):_vm._e()]},proxy:true}],null,false,3963155410)}),_c(_vm.components.associate,{tag:"component",attrs:{"dataPopup":_vm.dataPopup}})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('div',{staticClass:"gh-content-global",staticStyle:{"padding-left":"15px","padding-top":"8px"}},[_vm._l((_vm.getterConfigurationForms.list),function(item,key){return _c('div',{key:'FORM' + key,staticClass:"gh-row-content",staticStyle:{"padding-bottom":"2px","padding-top":"2px","padding-left":"5px","padding-right":"5px"},style:(key % 2 ? '' : 'background-color: #F3DAB5;')},[_c('div',[_c('div',{staticStyle:{"width":"20%"}},[_c('label',{staticClass:"T15_b gh_text-readonly_label"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{staticStyle:{"width":"40%"}},[_c('GhEasyUpload',{attrs:{"extrablock":{
                            id: 'attach_' + item.id,
                            class: '',
                            style: 'flex-direction: row !important;',
                            name_hidden: 'form[attach][' + item.id + ']',
                            id_hidden: 'attach',
                            icon_attach: require('../../../assets/gh_new_dessign/attach.svg')
                        },"datalabel":{
                            id: 'label_attach_' + item.id,
                            style: ''
                        },"datainput":{
                            text: _vm.$t('attach') + ' ' + item.name,
                            show_image: false,
                            delete: false,
                            href: 'uploadForm',
                            file_name: '',
                            file_path: '',
                            file_download: '',
                            required: false
                        }},on:{"click":function($event){return _vm.setUpload(item.id)}}})],1),_c('div',{staticStyle:{"width":"40%"}},[_c('GhAction',{attrs:{"dataction":{
                            id: 'download_' + item.id,
                            text: _vm.$t('download') + ' ' + item.name,
                            icon: require('../../../assets/gh_new_dessign/descargar.svg'),
                            href: item.download.file_download
                        }}})],1)])])}),(_vm.getterConfigurationForms.actions !== undefined && _vm.getterConfigurationForms.actions.add)?_c('div',{staticClass:"centered",staticStyle:{"margin-top":"12px"}},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_new_configuration_procedures',
                    text: _vm.$t('add')
                }},on:{"click":function($event){return _vm.Global.openPopUp('popup_add_form')}}})],1):_vm._e()],2),_c(_vm.component.message_upload,{tag:"component"}),_c(_vm.component.new_form,{tag:"component"})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }